import React, { useState } from 'react';
import { Accordion, Card, ListGroup, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import Image from '../../../../../../../core-components/Image';
import { SOCIAL_MEDIA } from '../../../../../../../utils/commonConstant';
import { maskPriceForAdmins } from '../../../../../frontend-common/CreditBuyPackagePage/UtilComponents/UtilitiesFunction';
import styles from './SocialMedia.module.scss';

function SocialMedia({ values, onValueChange, heading, price, accessLevel, paymentModelType }) {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [selectedPlatforms, setSelectedPlatforms] = useState(values?.config || []);

  const handlePlatformChange = (event) => {
    const platform = event.target.value;
    let updatedPlatforms = [...selectedPlatforms];

    if (event.target.checked) {
      if (!updatedPlatforms.includes(platform)) {
        updatedPlatforms.push(platform);
      }
    } else {
      updatedPlatforms = updatedPlatforms.filter((item) => item !== platform);
    }

    setSelectedPlatforms(updatedPlatforms);

    onValueChange('socialMedia', {
      count: updatedPlatforms.length,
      config: updatedPlatforms
    });
  };

  const toggleAccordion = (eventKey = 0) => {
    setActiveAccordion(activeAccordion === eventKey ? null : eventKey);
  };

  return (
    <div className={styles.addonsContainer}>
      <Accordion>
        <Card className={styles.addonsContainerCard}>
          <Accordion.Toggle
            as={Card.Header}
            eventKey='0'
            className={styles.addonsContainerHeader}
            onClick={() => toggleAccordion(0)}
            data-testid='accordion-toggle'
          >
            <div>
              <span className={styles.iconContainer}>
                <FontAwesomeIcon icon={faPlusCircle} color='#389E0D' className={styles.plusIcon} />
                <Image
                  name='socialMedia.svg'
                  svg='socialMedia.svg'
                  className={styles.SocialMediaIcon}
                />
              </span>
            </div>
            <div className={styles.headingRightContainer}>
              <span>{heading}</span>
              <div className={styles.headingRight}>
                <span className={styles.startFrom}>
                  <span className={styles.rupee}>&#8377;</span>
                  {maskPriceForAdmins(price, accessLevel, paymentModelType)}
                </span>
                {activeAccordion === 0 ? (
                  <Image name={'UpAngle.svg'} svg={'UpAngle.svg'} />
                ) : (
                  <Image name={'DownAngle.svg'} svg={'DownAngle.svg'} />
                )}
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='0'>
            <Card.Body className={styles.cardBody}>
              <ListGroup className={styles.listGroup}>
                {Object.keys(SOCIAL_MEDIA).map((platform, index) => {
                  return (
                    <ListGroup.Item className={styles.listGroupItem} key={index}>
                      <Form.Group controlId={`${platform}`} className={styles.formGroup}>
                        <Form.Check
                          name={`${platform}`}
                          id={`${platform}`}
                          type='checkbox'
                          value={platform}
                          checked={selectedPlatforms.includes(platform)}
                          onChange={handlePlatformChange}
                          label={<span className={styles.label}>{SOCIAL_MEDIA[platform]}</span>}
                        />
                      </Form.Group>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

export default SocialMedia;
