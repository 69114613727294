import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { isEmpty, toLower } from 'lodash';
import { Field, FieldArray } from 'formik';
import Modal from 'react-bootstrap/Modal';
import { isMobileOnly } from 'react-device-detect';
import CountryCodeModal from '../../../CountryCodeModal';
import ValidatedFormInputField from '../../../../../../../core-components/ValidatedFormInputField';
import CustomValidatedSelect from '../../../../../../../core-components/CustomValidatedSelect';
import Image from '../../../../../../../core-components/Image';
import { getTrimmedValue } from '../../../../../../../utils/utilities';
import countryOptions from '../../../../../../../utils/countryCodesEn.json';
import countryNames from '../../../../../../../utils/countryNamesEn.json';
import { capitalizeName, handleApostrophe } from '../../../../../../CompanyAdmin/frontend-common/utils/Utilities';
import styles from './HrDetailsStep.module.scss';
import { allowOnlyNumbersRegex } from '../../../../../../../utils/ValidationSchema';

class HrDetailsStep extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    subModalShow: false,
    modalTitle: null,
    modalLabel: 'India(+91)',
    modalSubTitle: null,
    defaultValue: null,
    mobHrCountryCodeLabel: '(' + this.props.values?.hr_info?.country_code + ')',
    country_code: null,
    mobMgCountryCodeLabel: '(' + this.props.values?.manager_info?.country_code + ')',
    alt_country_code: null
  };

  modalToggle = (title, titleLabel, defaultCountryCode) => {
    this.setState({
      subModalShow: true,
      modalTitle: title,
      modalSubTitle: titleLabel,
      defaultValue: defaultCountryCode
    });
  };

  modalHide = () => {
    this.setState({
      subModalShow: false
    });
  };

  setCountryCodeValue = ({ title, subTitle }, country_code) => {
    this.setState({
      [title]: country_code.value,
      [subTitle]: '(' + country_code.value + ')',
      modalLabel: country_code.label + '(' + country_code.value + ')'
    });
    this.props.setFieldValue(title, country_code.value);
  };

  getTitle = () => {
    const restOfTitle = this.props?.values?.companyName
      ? `${this.props.values.companyName} details`
      : ' Employment';
    return `${!this.props.isEdit ? 'Add' : 'Edit'} ${restOfTitle}`;
  };

  render() {
    const { handleSubmit, isSubmitting, values, errors, setFieldValue } = this.props;
    const setValue = (fieldname, value, withSpace) => {
      const trimmedVal = getTrimmedValue(value, withSpace);
      setFieldValue(fieldname, trimmedVal);
      return trimmedVal;
    };
    const isEmailWarning = (email) => {
      if (!email || email === '') return null;
      let warningMsg = '';
      if (
        this.props?.cached_data?.cache_data?.contact_details?.email !== '' &&
        this.props?.cached_data?.cache_data?.contact_details?.email?.toLowerCase() ===
          email?.toLowerCase()
      ) {
        warningMsg = `Provided email is same as the employee's email`;
      } else if (
        email !== '' &&
        values.hr_info?.email !== '' &&
        values.hr_info?.email?.toLowerCase() === email?.toLowerCase()
      ) {
        warningMsg = `Provided email is same as the HR's email`;
      }
      return warningMsg !== '' ? <div className={styles.subLabel}>{warningMsg}</div> : null;
    };
    const isPhoneWarning = (phone) => {
      let warningMsg = '';
      if (
        this.props?.cached_data?.cache_data?.contact_details?.phone_number !== '' &&
        `${this.props?.cached_data?.cache_data?.contact_details?.country_code}${this.props?.cached_data?.cache_data?.contact_details?.phone_number}` ===
          phone
      ) {
        warningMsg = `Provided Phone number is same as the employee's Phone number`;
      } else if (
        values.hr_info?.phone !== '' &&
        `${values.hr_info?.country_code}${values.hr_info?.phone}` === phone
      ) {
        warningMsg = `Provided Phone number is same as the HR's Phone number`;
      }
      return warningMsg !== '' ? <div className={styles.subLabel}>{warningMsg}</div> : null;
    };
    const isValidForm = isEmpty(errors);
    return (
      <>
        <Modal
          size='lg'
          show={this.props.show}
          onHide={this.props.onHide}
          aria-labelledby='contained-modal-title-vcenter'
          backdrop='static'
          backdropClassName={'customBGVModalBackdrop'}
          dialogClassName='bgvModalDialog'
          className='bgvModal'
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              <div className={styles.employmentModalTitle}>{this.getTitle()}</div>
              <div className={styles.employmentModalSubTitle}>{'Step 3 of 3'}</div>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit} className={styles.hrDetailsStepForm}>
            <Modal.Body className={styles.modalBody}>
              <div className={styles.employmentModalStepTitle}>
                {'How can we reach out to your company?'}
              </div>
              <div className={styles.employmentModalTypeTitle}>{'HR information'}</div>
              <Row>
                <Col sm={12} xs={12} md={12} lg={12}>
                  <Form.Label>
                    Full Name <span>*</span>
                  </Form.Label>
                  <Field
                    type='text'
                    name='hr_info.full_name'
                    component={ValidatedFormInputField}
                    onBlur={(e) => {
                      this.props.handleBlur(e);
                      setValue(
                        'hr_info.full_name',
                        capitalizeName(values?.hr_info.full_name),
                        true
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} xs={12} md={10} lg={10}>
                  <Form.Label>Email Address</Form.Label>
                  <Field
                    type='text'
                    name='hr_info.email'
                    component={ValidatedFormInputField}
                    onBlur={(e) => {
                      this.props.handleBlur(e);
                      setValue('hr_info.email', toLower(values?.hr_info.email), false);
                    }}
                  />
                  {(!errors.hr_info || (errors.hr_info && !errors.hr_info.email)) &&
                  (!values.hr_info.email || values.hr_info.email === '') ? (
                    <div className={styles.subLabel}>
                      {'Please provide their Email address as this will ensure quick verification.'}
                    </div>
                  ) : null}
                </Col>
              </Row>
              <Form.Label>Phone Number</Form.Label>
              <Form.Row>
                {!isMobileOnly ? (
                  <Field
                    type='text'
                    name='hr_info.country_code'
                    defaultValue={values.hr_info.country_code}
                    options={countryNames}
                    className={'countryCodeDropDown ' + styles.countryCodeDropDown}
                    filterLabel={countryOptions}
                    component={CustomValidatedSelect}
                  />
                ) : (
                  <Form.Group
                    as={Col}
                    className={styles.mobCountryCodeDropDown}
                    controlId='formGridPhoneNumber'
                  >
                    <div
                      className={styles.mobileCountryCodes}
                      onClick={() =>
                        this.modalToggle(
                          'hr_info.country_code',
                          'mobHrCountryCodeLabel',
                          values.hr_info.country_code
                        )
                      }
                    >
                      {this.state.mobHrCountryCodeLabel}
                      <Image className={styles.countryCodeArrow} name='dropdownArrow.svg' />
                    </div>
                  </Form.Group>
                )}

                <Form.Group as={Col} controlId='formGridPhoneNumber'>
                  <Field
                    type='text'
                    name={`hr_info.phone`}
                    component={ValidatedFormInputField}
                    onBlur={(e) => {
                      this.props.handleBlur(e);
                      setValue('hr_info.phone', values?.hr_info.phone, false);
                    }}
                    regex={allowOnlyNumbersRegex}
                    isHyphenNotRequired
                  />
                </Form.Group>
              </Form.Row>
              {(!errors.hr_info || (errors.hr_info && !errors.hr_info.phone)) &&
              values.hr_info.phone === '' ? (
                <div className={styles.phoneSubLabel}>
                  {'Please provide their Phone number as this will ensure quick verification.'}
                </div>
              ) : null}

              {!values.refCount ? (
                <>
                  <div className={styles.employmentModalTypeTitle}>{'Manager information'}</div>
                  <Row>
                    <Col sm={12} xs={12} md={12} lg={12}>
                      <Form.Label>
                        Full Name <span>*</span>
                      </Form.Label>
                      <Field
                        type='text'
                        name='manager_info.full_name'
                        component={ValidatedFormInputField}
                        onBlur={(e) => {
                          this.props.handleBlur(e);
                          setValue(
                            'manager_info.full_name',
                            capitalizeName(values?.manager_info.full_name),
                            true
                          );
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} xs={12} md={10} lg={10}>
                      <Form.Label>Email Address</Form.Label>
                      <Field
                        type='text'
                        name='manager_info.email'
                        component={ValidatedFormInputField}
                        onBlur={(e) => {
                          this.props.handleBlur(e);
                          setValue(
                            'manager_info.email',
                            toLower(values?.manager_info.email),
                            false
                          );
                        }}
                      />
                      {(!errors.manager_info ||
                        (errors.manager_info && !errors.manager_info.email)) &&
                      (!values.manager_info.email || values.manager_info.email === '') ? (
                        <div className={styles.subLabel}>
                          {
                            'Please provide their Email address as this will ensure quick verification.'
                          }
                        </div>
                      ) : null}
                      {isEmailWarning(values?.manager_info.email)}
                    </Col>
                  </Row>
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Row>
                    {!isMobileOnly ? (
                      <Field
                        type='text'
                        name='manager_info.country_code'
                        defaultValue={values.manager_info.country_code}
                        options={countryNames}
                        className={'countryCodeDropDown ' + styles.countryCodeDropDown}
                        filterLabel={countryOptions}
                        component={CustomValidatedSelect}
                      />
                    ) : (
                      <Form.Group
                        as={Col}
                        className={styles.mobCountryCodeDropDown}
                        controlId='formGridPhoneNumber'
                      >
                        <div
                          className={styles.mobileCountryCodes}
                          onClick={() =>
                            this.modalToggle(
                              'manager_info.country_code',
                              'mobMgCountryCodeLabel',
                              values.manager_info.country_code
                            )
                          }
                        >
                          {this.state.mobMgCountryCodeLabel}
                          <Image className={styles.countryCodeArrow} name='dropdownArrow.svg' />
                        </div>
                      </Form.Group>
                    )}

                    <Form.Group as={Col} controlId='formGridPhoneNumber'>
                      <Field
                        type='text'
                        name={`manager_info.phone`}
                        regex={allowOnlyNumbersRegex}
                        component={ValidatedFormInputField}
                        onBlur={(e) => {
                          this.props.handleBlur(e);
                          setValue('manager_info.phone', values?.manager_info.phone, false);
                        }}
                        isHyphenNotRequired
                      />
                    </Form.Group>
                  </Form.Row>
                  {(!errors.manager_info || (errors.manager_info && !errors.manager_info.phone)) &&
                  values.manager_info.phone === '' ? (
                    <div className={styles.phoneSubLabel}>
                      {'Please provide their Phone number as this will ensure quick verification.'}
                    </div>
                  ) : null}
                  {isPhoneWarning(
                    `${values.manager_info.country_code}${values?.manager_info.phone}`
                  )}
                </>
              ) : null}

              {/* References */}

              {values.refCount ? (
                <>
                  <div className={styles.refTittle}>{`Reference checks (${values.refCount})`}</div>
                  <FieldArray
                    name='references_additional'
                    render={(arrayHelpers) => (
                      <>
                        {values.references_additional && values.references_additional.length > 0
                          ? values.references_additional.map((value, index) => (
                              <React.Fragment key={`references_${index}`}>
                                <div className={styles.refLabel}>
                                  {index ? `Additional reference - ${index}` : `Manager`}
                                </div>
                                <Row>
                                  <Col sm={12} xs={12} md={12} lg={12}>
                                    <Form.Label>
                                      Full Name <span>*</span>
                                    </Form.Label>
                                    <Field
                                      type='text'
                                      name={`references_additional.${index}.full_name`}
                                      component={ValidatedFormInputField}
                                      onBlur={(e) => {
                                        this.props.handleBlur(e);
                                        setValue(
                                          `references_additional.${index}.full_name`,
                                          capitalizeName(e.target.value),
                                          true
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={12} xs={12} md={12} lg={12}>
                                    <Form.Label>
                                      Email Address<span>*</span>
                                    </Form.Label>
                                    <Field
                                      type='text'
                                      name={`references_additional.${index}.email`}
                                      component={ValidatedFormInputField}
                                      onBlur={(e) => {
                                        this.props.handleBlur(e);
                                        setValue(
                                          `references_additional.${index}.email`,
                                          toLower(e.target.value),
                                          false
                                        );
                                      }}
                                    />
                                    {!errors?.references_additional?.[index]?.email &&
                                      isEmailWarning(value.email)}
                                  </Col>
                                </Row>
                                <Form.Label>
                                  Phone Number<span>*</span>
                                </Form.Label>

                                <Form.Row>
                                  {!isMobileOnly ? (
                                    <Field
                                      type='text'
                                      name={`references_additional.${index}.country_code`}
                                      defaultValue={value.country_code}
                                      options={countryNames}
                                      className={
                                        'countryCodeDropDown ' + styles.countryCodeDropDown
                                      }
                                      filterLabel={countryOptions}
                                      component={CustomValidatedSelect}
                                    />
                                  ) : (
                                    <Form.Group
                                      as={Col}
                                      className={styles.mobCountryCodeDropDown}
                                      controlId='formGridPhoneNumber'
                                    >
                                      <div
                                        className={styles.mobileCountryCodes}
                                        onClick={() =>
                                          this.modalToggle(
                                            `references_additional.${index}.country_code`,
                                            'mobMgCountryCodeLabel',
                                            value.country_code
                                          )
                                        }
                                      >
                                        {this.state.mobMgCountryCodeLabel}
                                        <Image
                                          className={styles.countryCodeArrow}
                                          name='dropdownArrow.svg'
                                        />
                                      </div>
                                    </Form.Group>
                                  )}
                                  <Form.Group as={Col} controlId='formGridPhoneNumber'>
                                    <Field
                                      type='text'
                                      name={`references_additional.${index}.phone`}
                                      component={ValidatedFormInputField}
                                      regex={allowOnlyNumbersRegex}
                                      onBlur={(e) => {
                                        this.props.handleBlur(e);
                                        setValue(
                                          `references_additional.${index}.phone`,
                                          e.target.value,
                                          false
                                        );
                                      }}
                                    />
                                    {!errors?.references_additional?.[index]?.phone &&
                                      isPhoneWarning(`${value.country_code}${value.phone}`)}
                                  </Form.Group>
                                </Form.Row>
                                <Row>
                                  <Col sm={12} xs={12} md={12} lg={12}>
                                    <Form.Label>
                                      Designation <span>*</span>
                                    </Form.Label>
                                    <Field
                                      type='text'
                                      name={`references_additional.${index}.designation`}
                                      component={ValidatedFormInputField}
                                      onBlur={(e) => {
                                        this.props.handleBlur(e);
                                        setValue(
                                          `references_additional.${index}.designation`,
                                          handleApostrophe(e.target.value),
                                          true
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={12} xs={12} md={12} lg={12}>
                                    <Form.Label>LinkedIn URL</Form.Label>
                                    <Field
                                      type='text'
                                      isTouchedNotRequired={true}
                                      name={`references_additional.${index}.linkedin_url`}
                                      component={ValidatedFormInputField}
                                      onChange={(e) => {
                                        const val = getTrimmedValue(e.target.value, false);
                                        setFieldValue(
                                          `references_additional.${index}.linkedin_url`,
                                          val
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            ))
                          : null}
                      </>
                    )}
                  />
                </>
              ) : null}
              {/* End of References */}
            </Modal.Body>
            <Modal.Footer className={styles.employmentSubmitBtn}>
              <Button
                className={'bgvModalSecondaryBtn'}
                onClick={(e) => {
                  this.props.subPrevStep();
                }}
              >
                Go Back
              </Button>
              <Button
                type='submit'
                className={'bgvModalPrimaryBtn'}
                disabled={isSubmitting || !isValidForm}
              >
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {this.state.subModalShow ? (
          <CountryCodeModal
            subShow={this.state.subModalShow}
            SubOnHide={this.modalHide}
            title={this.state.modalTitle}
            subTitle={this.state.modalSubTitle}
            defaultValue={this.state.defaultValue}
            handleCodeChange={this.setCountryCodeValue}
            {...this.props}
          />
        ) : null}
      </>
    );
  }
}
export default HrDetailsStep;
