import { isEmpty, isInteger } from 'lodash';
import * as Yup from 'yup';
import { getTrimmedValue, isValidPhoneNumber } from './utilities';
import validator from 'validator';
import { PASSWORD_RULES } from './commonConstant';

Object.defineProperty(String.prototype, 'capitalize', {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});
const NULLABLE = Yup.string().nullable();
const REQUIRED = (message) => {
  if (!message) return Yup.string().required('This is a required field.');

  return Yup.string().required(`Please enter a ${message}`);
};
function hasRepeatedChars(password) {
  for (let i = 1; i < password.length; i++) {
    if (password[i] === password[i - 1]) {
      return true;
    }
  }
  return false;
}
function hasConsecutiveRepeatedChars(password) {
  for (let i = 1; i < password.length; i++) {
    if (password[i].toLowerCase() === password[i - 1].toLowerCase()) {
      return true;
    }
  }
  return false;
}
/**
 * * Regular expressions
 */
const INVALID_SPECIAL_CHARS_IN_PASSWORD = /["'\s]/;
const ALPHANUMERIC_STRICT = /^[a-zA-Z0-9]/;
const ALPHANUMERIC_STRING = /^(?!')[a-zA-Z0-9.&()' -_\s-]*(?<!')$/;
const ALPHANUMERIC_STRING_WITH_DIACRITICAL = /^[a-zA-Z0-9.&()-_\s\p{M}\p{L}]+$/u;
const ALPHABATIC_STRING =
  /(?!\.)(?!\-)(?!.*\.$)(?!.*\-$)(?!\s)(?!.*\.\.)(?!.*\-\-)(?!.*\-\.)(?!.*\.\-)(?!.*\-\s)^(([a-zA-Z]-?)*[a-zA-Z\.\s]){2,}$/;
const FOR_HR_DESIGNATION = /^[a-zA-Z0-9_\s-]+$/;
const FOR_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.[A-Za-z]{2,63})+(\.[A-Za-z]{2,63})?$/;
const FOR_UAN = /^1\d{11}$/;
const FOR_URL =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/;
const FOR_GST = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
const USER_CATEGORY_REGEX = /^[a-zA-Z0-9 \-_/().]*$/;
const EMPLOYEE_ID_REGEX = /^[a-zA-Z0-9 \-_/()]*$/;

/**
 * As per ADO-5705
 * *
 * * - Now we allow white-spaces and all special characters excluding
 * * single/double quotes and accented characters.
 */
const FOR_PASSWORD =
  /^(?!.*(\w)\1{1,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~^\-\]\/><:[=@$+|!%*?&#;,.{}_()\\])[A-Za-z\d@+-^&\/@$!%*?&#,:;=|`~.{}_()]{12,}$/;
/** Write Methods below only */

/**
 * Rules for LinkedIn URL validation:
 *
 * The following regular expression validates LinkedIn profile URLs based on the provided rules:
 *
 * - linkedin\.com/in : Match the literal "linkedin.com/in" substring.
 *
 * Valid LinkedIn profile URLs should contain "linkedin.com/in" anywhere in the URL.
 *
 * Invalid URLs would not include "linkedin.com/in" in the URL.
 */
export const LINKEDIN_URL_VALIDATION =
  /^(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/[a-zA-Z0-9-_.]+\/?$/;

export const FACEBOOK_URL_VALIDATION =
  /^(?:https?:\/\/)?(?:www\.)?facebook\.com\/[a-zA-Z0-9.]+\/?$/;

export const TWITTER_URL_VALIDATION =
  /^(?:https?:\/\/)?(?:www\.)?(?:twitter\.com|x\.com)\/[a-zA-Z0-9_]+\/?$/;

export const INSTAGRAM_URL_VALIDATION =
  /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/[a-zA-Z0-9._]+\/?$/;

function hasAccentedCharacters(str) {
  return /[^\u0000-\u007F]/.test(str);
}

function hasSpecialChar(str) {
  return /[`~!#$%^@&*()_\-\+\|{}\]\[;:\/\\\?.=>,<]/.test(str);
}

const STRING_CHECK = (message) => {
  return Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, true))
    .matches(ALPHABATIC_STRING, `Please enter a valid ${message}`);
};

const USER_CATEGORY_CHECK = (message) => {
  return Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, true))
    .matches(USER_CATEGORY_REGEX, `Please enter a valid ${message}`);
};

const EMPLOYEE_ID_CHECK = (message) => {
  return Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, true))
    .matches(EMPLOYEE_ID_REGEX, `Please enter a valid ${message}`);
};

/**
 * Removed auto-trim for password validations.
 */
const PASSWORD_CHECK = Yup.string()
  .matches(FOR_PASSWORD, 'Password does not match required criteria')
  .test({
    message: 'Password does not match required criteria',
    test: (value) => {
      return !new RegExp(/(.)\1+/i).test(value);
    }
  });
const FOR_TOKEN_NAME = /^[\sa-zA-Z0-9_-]*$/;
const STRICT_STRING_CHECK = (message) => {
  return Yup.string().matches(ALPHANUMERIC_STRING, `Please enter a valid ${message}`);
};
const CITY_NAME_CHECK = (message) => {
  return Yup.string().matches(
    ALPHANUMERIC_STRING_WITH_DIACRITICAL,
    `Please enter a valid ${message}`
  );
};
const EMAIL_CHECK = Yup.string().matches(FOR_EMAIL, 'Please enter a valid email address');

const UAN_CHECK = Yup.string()
  .transform((currentVal) => getTrimmedValue(currentVal, false))
  .matches(FOR_UAN, 'Please enter a valid UAN');

const LINKEDIN_CHECK = Yup.string()
  .transform((currentVal) => getTrimmedValue(currentVal, false))
  .matches(LINKEDIN_URL_VALIDATION, 'Please enter a valid LinkedIn profile URL');
const TWITTER_CHECK = Yup.string()
  .transform((currentVal) => getTrimmedValue(currentVal, false))
  .matches(TWITTER_URL_VALIDATION, 'Please enter a valid X (Twitter) profile URL');
const FACEBOOK_CHECK = Yup.string()
  .transform((currentVal) => getTrimmedValue(currentVal, false))
  .matches(FACEBOOK_URL_VALIDATION, 'Please enter a valid Facebook profile URL');
const INSTAGRAM_CHECK = Yup.string()
  .transform((currentVal) => getTrimmedValue(currentVal, false))
  .matches(INSTAGRAM_URL_VALIDATION, 'Please enter a valid Instagram profile URL');

const concatenateSchema = ({ schema, isNullable, isRequired, isRequiredMessage }) => {
  if (isRequired)
    return schema.concat(isRequiredMessage ? REQUIRED(isRequiredMessage) : REQUIRED());

  if (isNullable) return schema.concat(NULLABLE);

  return schema;
};

/**
 * * getNameValidationSchema() : To validate field without leading whitespace.
 * * getStrictNameValidationSchema() : To validate field without any special characters.
 * * getPasswordValidationchema(): to Validate Password,
 * * getEmailValidationSchema() : To validate email field.
 * * getPhoneValidationSchema() : To validate phone number fields.
 * * getUANValidationSchema() : To validate UAN number.
 * * getURLValidationSchema() : To validate URL fields.
 * * getGSTValidationSchema() : To validate GST fields.
 */

/**
 * @param {Custom message to show at the end of warning message} message
 * @param {Set to true if field is required} isRequired
 * @param {Set to true if field is nullable} isNullable
 */
export const getNameValidationSchema = ({ message, isRequired, isRequiredMessage, isNullable, minCharacter = null }) => {
  let schema = STRING_CHECK(message);

  if (minCharacter) {
    schema = schema.min(minCharacter, `Name must be at least ${minCharacter} characters long.`);
  }
  schema = concatenateSchema({
    schema: schema,
    isNullable: isNullable,
    isRequired: isRequired,
    isRequiredMessage: message
  });

  return schema;
};

export const getHrDesignationSchema = ({ message, isRequired, isRequiredMessage, isNullable }) => {
  let schema = Yup.string().matches(FOR_HR_DESIGNATION, `Please enter a valid ${message}`);

  schema = concatenateSchema({
    schema: schema,
    isNullable: isNullable,
    isRequired: isRequired,
    isRequiredMessage: message
  });

  return schema;
};

export const getNameValidationObject = ({ message, isRequired, isNullable }) => {
  return Yup.object()
    .transform((currentVal) => getTrimmedValue(currentVal, true))
    .shape({
      value: getNameValidationSchema({
        message: message,
        isRequired: isRequired,
        isNullable: isNullable
      })
    });
};

export const getStrictNameValidationSchema = ({ message, isRequired, isNullable }) => {
  let schema = STRICT_STRING_CHECK(message);

  schema = concatenateSchema({
    schema: schema,
    isNullable: isNullable,
    isRequired: isRequired,
    isRequiredMessage: message
  });

  return schema;
};

export const getCityNameValidation = ({ message, isRequired, isNullable }) => {
  let schema = CITY_NAME_CHECK(message);

  schema = concatenateSchema({
    schema: schema,
    isNullable: isNullable,
    isRequired: isRequired,
    isRequiredMessage: message
  });

  return schema;
};

export const getCustomNameValidationSchema = ({
  message,
  isRequired,
  isNullable,
  isRequiredMessage
}) => {
  let schema = Yup.string().matches(ALPHANUMERIC_STRING, message);

  if (isRequired) schema = schema.required(`Please enter a ${isRequiredMessage}`);

  return schema;
};

export const getEmailValidationSchema = ({
  isRequired,
  isNullable,
  msg = 'Please enter a valid email address.',
  isPlusSignAllowed = true
}) => {
  let schema = Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, false))
    .test({
      message: msg,
      test: (value) => {
        if (value) {
          const isEmailValid = validator.isEmail(value);
          const isFirstCharAlphaNumeric = value[0].match(ALPHANUMERIC_STRICT) !== null;
          const isLastCharOfUsernameAlphaNumeric = isEmailValid
            ? value[value.search(/@/) - 1].match(ALPHANUMERIC_STRICT) !== null
            : false;
          let doesNotContainPlusInUsername = true;
          if (!isPlusSignAllowed)
            doesNotContainPlusInUsername = /^[^+]*$/.test(value.split('@')[0]);
          if (isPlusSignAllowed)
            return (
              isEmailValid &&
              doesNotContainPlusInUsername &&
              isFirstCharAlphaNumeric &&
              isLastCharOfUsernameAlphaNumeric
            );
          return (
            isEmailValid &&
            doesNotContainPlusInUsername &&
            isFirstCharAlphaNumeric &&
            isLastCharOfUsernameAlphaNumeric
          );
        }
        return true;
      }
    });

  schema = concatenateSchema({
    schema: schema,
    isNullable: isNullable,
    isRequired: isRequired,
    isPlusSignAllowed: isPlusSignAllowed
  });

  return schema;
};

export const checkEmailValidation = (email) => {
  let isPlusSignAllowed = false;
  if (!email) {
    return false; // Return false for empty email
  }

  const isEmailValid = validator.isEmail(email);
  if (!isEmailValid) {
    return false; // Return false if email is not valid
  }

  const isFirstCharAlphaNumeric = email[0].match(ALPHANUMERIC_STRICT) !== null;

  const isLastCharOfUsernameAlphaNumeric = isEmailValid
    ? email[email.search(/@/) - 1].match(ALPHANUMERIC_STRICT) !== null
    : false;
  let doesNotContainPlusInUsername = true;
  if (!isPlusSignAllowed) doesNotContainPlusInUsername = /^[^+]*$/.test(email.split('@')[0]);
  return (
    doesNotContainPlusInUsername && isFirstCharAlphaNumeric && isLastCharOfUsernameAlphaNumeric
  );
};

export const getPasswordValidationchema = () => {
  let schema = PASSWORD_CHECK;

  schema = concatenateSchema({
    schema: schema,
    isNullable: false,
    isRequired: true
  });

  return schema;
};
// Export as a Yup object
export const getEmailValidationObject = ({ isRequired, isNullable }) => {
  return Yup.object().shape({
    value: getEmailValidationSchema({
      isRequired: isRequired,
      isNullable: isNullable
    })
  });
};

export const getPhoneValidationSchema = ({ fieldName, isNullable, isRequired }) => {
  return Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, false))
    .when('countryCode', (countryCode, schema) => {
      return schema.test({
        name: fieldName,
        message: 'Please enter a valid Phone number.',
        test: function (value) {
          if (value) {
            const fullNumber = countryCode ? `${countryCode}${value}` : value;
            return isValidPhoneNumber(fullNumber);
          }
          return true;
        }
      });
    })
    .concat(
      concatenateSchema({
        schema: Yup.string(),
        isNullable: isNullable,
        isRequired: isRequired
      })
    );
};

export const getUANValidationSchema = ({ isRequired, isNullable }) => {
  let schema = UAN_CHECK;

  schema = concatenateSchema({ schema: schema, isNullable: isNullable, isRequired: isRequired });

  return schema;
};

export const getURLValidationSchema = ({ isRequired, isNullable }) => {
  let schema = Yup.string().lowercase().matches(FOR_URL, {
    message: 'Enter valid website.',
    excludeEmptyString: false
  });

  schema = concatenateSchema({
    schema: schema,
    isNullable: isNullable,
    isRequired: isRequired,
    isRequiredMessage: 'Company website cannot be blank'
  });

  return schema;
};

export const getGSTValidationSchema = () => {
  return Yup.string().matches(FOR_GST, 'Please enter a valid GSTIN');
};

export const isValidTokenName = (value) => {
  if (value === '' || value?.length < 3) {
    return false;
  }
  return FOR_TOKEN_NAME.test(value);
};

export const isValidExpiryLimit = (value) => {
  if (!isEmpty(value)) {
    if (isInteger(Number(value))) {
      if (parseInt(value, 10) >= 600 && parseInt(value, 10) <= 86400) {
        return true;
      }
    }
  }

  return false;
};

/**
 * Used for set/reset/forgot password flow.
 * @param {Password} password
 * @returns Result object with boolean values for respective rules.
 */
export const checkPasswordRules = (password) => {
  let result = PASSWORD_RULES;

  result.IS_CHARACTER_RULE_SATISFIED = password.length >= 12;
  result.IS_AT_LEAST_ONE_UPPER_CASE_PRESENT = /[A-Z]/.test(password);
  result.IS_AT_LEAST_ONE_LOWER_CASE_PRESENT = /[a-z]/.test(password);
  result.IS_NUMBER_RULE_SATISFIED = /\d/.test(password);
  result.IS_SYMBOL_RULE_SATISFIED =
    hasSpecialChar(password) &&
    !hasAccentedCharacters(password) &&
    !INVALID_SPECIAL_CHARS_IN_PASSWORD.test(password);
  result.IS_NO_REPEATING_CHAR_PRESENT = !hasRepeatedChars(password);
  result.IS_NO_REPEATING_CHAR_PRESENT = !hasConsecutiveRepeatedChars(password);

  return result;
};

/**
 * Validation Schema for Linkedin URL fields.
 */

export const getLinkedinUrlValidation = ({ isRequired, isNullable }) => {
  let schema = LINKEDIN_CHECK;

  schema = concatenateSchema({ schema: schema, isNullable: isNullable, isRequired: isRequired });

  return schema;
};
export const getFacebookUrlValidation = ({ isRequired, isNullable }) => {
  let schema = FACEBOOK_CHECK;

  schema = concatenateSchema({ schema: schema, isNullable: isNullable, isRequired: isRequired });

  return schema;
};
export const getTwitterUrlValidation = ({ isRequired, isNullable }) => {
  let schema = TWITTER_CHECK;

  schema = concatenateSchema({ schema: schema, isNullable: isNullable, isRequired: isRequired });

  return schema;
};
export const getInstagramUrlValidation = ({ isRequired, isNullable }) => {
  let schema = INSTAGRAM_CHECK;

  schema = concatenateSchema({ schema: schema, isNullable: isNullable, isRequired: isRequired });

  return schema;
};

export const userCategoryValidationSchema = ({ message, isRequired, isNullable }) => {
  let schema = USER_CATEGORY_CHECK(message);
  schema = concatenateSchema({
    schema: schema,
    isNullable: isNullable,
    isRequired: isRequired,
    isRequiredMessage: message
  });
  return schema;
};

export const employeeIdValidationSchema = ({ message, isRequired, isNullable }) => {
  let schema = EMPLOYEE_ID_CHECK(message);
  schema = concatenateSchema({
    schema: schema,
    isNullable: isNullable,
    isRequired: isRequired,
    isRequiredMessage: message
  });
  return schema;
};

export const allowOnlyNumbersRegex = /[^\d]+/g;
