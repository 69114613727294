import React, { useState, useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import { Table, Card, OverlayTrigger, Form, Col, Tooltip } from 'react-bootstrap';

import CategoryModals from '../../CategoryModals';
import AdminModals from '../../Administrators/AdminModals';
import Button from '../../../../core-components/Button';
import Icon from '../../../../core-components/Icon';
import Image from '../../../../core-components/Image';
import Pagination from '../../../../core-components/Pagination';

import styles from './ManageCategories.module.scss';

const ManageCategories = (props) => {
  const pageSizes = [10, 25, 50, 100];

  const [pageSize, setPageSize] = useState(pageSizes[0]);
  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [isData, setIsData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalSubTitle, setModalSubTitle] = useState(null);
  const [modalShowStatus, setModalShowStatus] = useState(false);
  const [modalButton, setModalButton] = useState(null);
  const [id, setId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [info, setInfo] = useState('');

  const getCandidatesCount = (val) => {
    let res = 0;
    if (val) {
      val.forEach((element) => {
        if (element.companyCandidateMapping) {
          res++;
        }
      });
    }
    return res;
  };
  const modalToggle = (modalTitle, modalSubTitle, modalButton, index, row, info = '') => {
    setModalShow(true);
    setModalTitle(modalTitle);
    setModalSubTitle(modalSubTitle);
    setModalButton(modalButton);
    setId(index);
    setSelectedRow({ category: row });
    setInfo(info);
  };

  const modalHide = (value) => {
    if (value === 'reload category') {
      setModalShowStatus(true);
      setModalShow(false);
      setPageSize(pageSizes[0]);
      setPageNumber(0); //setting to default value
      fetchCategories(pageSizes[0], 0);
    }
    setModalShow(false);
  };

  const modalHideStatus = () => {
    setModalShowStatus(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let limit = pageSize;
    let offset = pageNumber;
    props?.invalidateCategories();
    fetchCategories(limit, offset);
    props?.getPaymentDetails();
  }, [pageSize, pageNumber]);

  const fetchCategories = (limit, offset) => {
    props?.getCategories(limit, offset);
  };

  useEffect(() => {
    setData(props?.categories?.userCategories);
    setCount(props?.categories?.count);
    setLoading(props?.categories?.loading);
    setIsData(props?.categories?.count !== 0);
  }, [props.categories]);

  const paginate = (limit, offset) => {
    setLoading(true);
    fetchCategories(limit, offset);
  };

  const pageSizeOptions = [];
  let index = 0;
  do {
    const value = pageSizes[index] > count ? count : pageSizes[index];
    if (!value) break;
    pageSizeOptions.push(
      <option key={index} value={value}>
        {value}
      </option>
    );
  } while (pageSizes[index++] < count);

  return (
    <div>
      <AdminModals
        show={modalShowStatus}
        onHide={modalHideStatus}
        content={
          modalTitle === 'Edit Category'
            ? 'Category has been updated successfully'
            : modalTitle === 'Delete Category'
            ? 'Category deleted successfully'
            : 'Category created successfully'
        }
      />
      <Card className={styles.manageCatCard}>
        <nav className={styles.manageCatCardHeader}>
          <div className={styles.headerComp}>Total {count} Categories</div>
          <Button
            className={`${styles.createButton} ${styles.accentColor}`}
            onClick={() => {
              modalToggle('Create Category', 'Name', 'Create');
            }}
          >
            {'+Add Category'}
          </Button>
        </nav>
        {modalShow && (
          <CategoryModals
            show={modalShow}
            onHide={modalHide}
            title={modalTitle}
            subTitle={modalSubTitle}
            button={modalButton}
            categoryId={id}
            category={selectedRow}
            info={info}
            placeholder={'Category Name'}
          />
        )}

        <Card className={styles.tableCard}>
          <Table className={styles.manageCategoryTable}>
            <thead>
              <tr>
                <th>#</th>
                <th>Categories</th>
                <th className={styles.alignRight}>Users</th>
                <th className={styles.alignRight}>Candidates</th>
                <th className={styles.alignCenter}>Actions</th>
              </tr>
            </thead>
            {isData ? (
              <tbody>
                {loading
                  ? Array.apply(null, { length: pageSize })
                      .map(Number.call, Number)
                      .map((loadData, i) => {
                        return (
                          <tr key={i}>
                            <td className={styles.mainField}>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                            <td className={styles.alignleft}>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                            <td className={styles.alignRight}>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                            <td className={styles.alignRight}>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                            <td className={styles.alignCenter}>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                          </tr>
                        );
                      })
                  : data &&
                    data.map((rowData, i) => {
                      return (
                        <tr key={i}>
                          <td>{pageNumber * pageSize + (i + 1)}</td>
                          <td className={styles.mainField}>{rowData.categoryName}</td>
                          <td className={styles.alignRight}>
                            {rowData?.userCategoryMappings?.length}
                          </td>
                          <td
                            className={styles.alignRight}
                            style={{ cursor: 'pointer', color: '#366cf3' }}
                            onClick={() =>
                              window.open(`/candidates?category=${rowData.categoryName}`, '_blank')
                            }
                          >
                            {getCandidatesCount(rowData?.candidateUserCategoryMappings)}
                          </td>
                          <td className={styles.alignRight}>
                            <div className={styles.icons}>
                              <OverlayTrigger
                                placement='bottom'
                                overlay={<Tooltip id={'EditCategory'}>{'Edit'}</Tooltip>}
                              >
                                <div
                                  className={styles.icon}
                                  onClick={() =>
                                    modalToggle(
                                      'Edit Category',
                                      'Name',
                                      'Save',
                                      rowData.id,
                                      rowData.categoryName
                                    )
                                  }
                                >
                                  <Icon
                                    icon={'EditCategory'}
                                    color={'#333333'}
                                    width={14}
                                    height={14}
                                  />
                                </div>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement='bottom'
                                overlay={
                                  <Tooltip id={'DeleteCategory'}>
                                    <p>{'Delete'}</p>
                                  </Tooltip>
                                }
                              >
                                <div
                                  className={styles.icon}
                                  onClick={() => {
                                    modalToggle(
                                      'Delete Category',
                                      'Are you sure you want to delete this category?',
                                      'Delete',
                                      rowData.id,
                                      rowData.categoryName,
                                      'Candidates under this category won’t be deleted.'
                                    );
                                  }}
                                >
                                  <Icon
                                    icon={'DeleteCategory'}
                                    color={'#333333'}
                                    width={12}
                                    height={14}
                                  />
                                </div>
                              </OverlayTrigger>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={5}>
                    <div className={styles.noDataWrapper}>
                      <Image name={'NoData.svg'} />
                      <div className={styles.noDataContent}>{'No categories found'}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
          {isData ? (
            <Form.Row className={styles.paginationWrapper}>
              <Col className={styles.showPagination}>
                Show
                <select
                  className={styles.selectPagination}
                  value={pageSize}
                  onChange={(event) => {
                    const newPageSize = parseInt(event.target.value);
                    setPageNumber(0);
                    setPageSize(newPageSize);
                    paginate(newPageSize, 0);
                  }}
                >
                  {pageSizeOptions}
                </select>
                items of {count}
              </Col>
              <Col>
                <Pagination
                  forcePage={pageNumber}
                  onPageChange={({ selected }) => {
                    setPageNumber(selected);
                    paginate(pageSize, selected);
                  }}
                  pageCount={Math.ceil(count / pageSize)}
                />
              </Col>
              <Col xs='3'></Col>
            </Form.Row>
          ) : (
            <div></div>
          )}
        </Card>
      </Card>
    </div>
  );
};

export default ManageCategories;
