import React, { useEffect, useState } from 'react';
import Loader from '../../../core-components/Loader';
import Cookies from 'js-cookie';
import styles from './ChargesApproval.module.scss';
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import Input from '../../../core-components/Input';
import { DropdownList } from 'react-widgets';
import Image from '../../../core-components/Image';
import {
  APPROVAL_STATUS,
  DELETE_REASON,
  EDU_CHARGE_TYPE,
  PAYMENT_MODEL_TYPE
} from '../../../utils/commonConstant';
import { COST_TYPE, checkNameMap } from '../../../utils/commonConstant';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import Pagination from '../../../core-components/Pagination';
import moment from 'moment/moment';
import RejectModal from './RejectModal/RejectModal';
import './daterangePicker.scss';
import { rejectADC, approveADC } from '../../../api/company';
import { toast } from 'react-toastify';
import {
  convertToIndianNumeration,
  getIDCheck,
  useDebounce,
  toCamelCase,
  getTrimmedValue,
  errToastMessage
} from '../../../utils/utilities';
import ApproveAllModal from './ApproveModal/ApproveModal';
import CreditLimitMoal from './CreditLimitModal/CreditLimitModal';
import Icon from '../../../core-components/Icon';
import receipt from '../../../images/receipt.svg';
import { Link } from 'react-router-dom';
import { DayPickerRangeController, isInclusivelyBeforeDay } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import AppConstants from '../../../core-components/AppConstants';
import InsuffFundsModal from './InsufficientFundsModal/InsuffFundsModal';
import AdcInfoModal from './AdcInfoModal/AdcInfoModal';
import { capitalizeName, getDocPipingUrlCA } from '../frontend-common/utils/Utilities';

export default function CharsApproval(props) {
  const {
    approvalList,
    getPaymentDetails,
    getAdcSettingsConfig,
    adcSettings = {},
    history,
    userID
  } = props;
  const { adcNotification: adcNotificationConfig, loading: isLoading } = adcSettings;

  const [focusedInput, setFocusedInput] = useState('startDate');
  const [openDateSelector, setOpenDateSelector] = useState(false);
  const [filteredApprovalList, setFilteredApprovalList] = useState({ rows: [] });

  const paymentModelType =
    props?.companyPaymentMode?.companyPaymentType === 'PREPAID' ? 'PREPAID' : 'POSTPAID';
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(approvalList?.count);
  const [pageSize, setPageSize] = useState(10);
  const [dateRangeShow, setDateRangeShow] = useState({ startDate: null, endDate: null });
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [ApprovalStatus, setApprovalStatus] = useState({ label: 'Pending', value: 'PENDING' });
  const [CostType, setCostType] = useState({ label: 'All', value: '' });
  const [rejectModal, setRejectModal] = useState(false);
  const [empName, setEmpName] = useState('');
  const [rejectAll, setRejectAll] = useState(false);
  const [rejectDetails, setRejectDetails] = useState();
  const [companyId, setCompnayId] = useState(props?.companyId);
  const [approveModal, setApproveModal] = useState(false);
  const [showCreditLimitModal, setShowCreditLimitModal] = useState(false);
  const [approveSingleADC, setApproveSingleADC] = useState({});
  const [approveAll, setApproveAll] = useState(false);
  const [reloadRangePicker, setReloadRangePicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showRechargeSummaryModal, setShowRechargeSummaryModal] = useState(false);
  const [showActionBar, setShowActionBar] = useState(false);
  const [showRibbonForRecharge, setShowRibbonForRecharge] = useState(false);
  const [showCriticalBalancePill, setShowCriticalBalancePill] = useState(false);
  const [showInsuffFunds, setShowInsuffFunds] = useState(false);

  const pageSizeOptions = [];
  const pageSizes = [10, 25, 50, 100];
  let index = 0;

  // ADC Setting Modal
  const [isOpenAdcModal, setOpenAdcModal] = useState(false);
  const [adcNotification, setAdcNotification] = useState({});
  useEffect(() => {
    const fetchAdcSettings = async () => {
      try {
        const response = await getAdcSettingsConfig({ send_adc_computed_data: true });
        const adcModalNotification = response?.data?.adcNotification || {};
        if (
          (adcModalNotification?.showAdcAlert &&
            !adcModalNotification?.manuallyApprovedAdcCount &&
            !adcModalNotification?.manuallyApprovedAdcDelayInDays) ||
          (adcModalNotification?.showAdcAlert &&
            adcModalNotification?.manualApprovalPendingAdcCount === 0)
        )
          setOpenAdcModal(true);
      } catch (err) {
        console.error('ADC Settings Error: ', err);
      }
    };
    fetchAdcSettings();
  }, []);
  // Navigate ADC settings Tab
  const handleNavigationToAdcSettings = () => {
    history.push('/settings?tab=adc');
  };

  /** This functions helps in remount of the range picker component to clear it's input fields */
  const clearRangePickerInput = () => {
    setReloadRangePicker(true);

    setTimeout(() => {
      setReloadRangePicker(false);
    }, 50);
  };
  do {
    const value = pageSizes[index] > count ? count : pageSizes[index];

    if (!value) break;

    pageSizeOptions.push(
      <option key={index} value={value}>
        {value}
      </option>
    );
  } while (pageSizes[index++] < count);

  const fetchApprovalList = (
    where = {
      limit: 10,
      offset: offset,
      from_date: null,
      to_date: null,
      status: null,
      charge_type: null,
      candidate: null
    }
  ) => {
    if (where.status === null) {
      setApprovalStatus({ label: 'All', value: '' });
    }

    if (where.charge_type === null) {
      setCostType({ label: 'All', value: '' });
    }

    props
      ?.getApprovalList(where)
      .then(() => setLoading(false))
      .catch((error) => {
        console.error(error);
      });
  };

  const getFormattedData = () => {
    let data = approvalList?.rows?.reduce((accumulator, value) => {
      if (value?.status === 'APPROVAL_PENDING' && !value?.paymentsCompanyDeletedAdditionalCharge) {
        let obj = {
          userId: value?.user?.id,
          id: value?.id
        };
        accumulator.push(obj);
      }

      return [...accumulator];
    }, []);

    const finalData = {
      all: true,
      companyId,
      additionalChargeMapping: [...data]
    };
    return finalData;
  };

  useEffect(() => {
    setCount(approvalList?.count);

    const walletBal = props?.approvalList?.amount;
    const pendingCost = props?.approvalList?.pendingAmount;

    if (walletBal !== null) {
      if (pendingCost !== 0) setShowRibbonForRecharge(pendingCost > walletBal);
      setShowCriticalBalancePill(pendingCost > walletBal);
    } else {
      setShowRibbonForRecharge(false);
      setShowCriticalBalancePill(false);
    }

    if (approvalList) {
      const filterData = { rows: [] };

      approvalList?.rows?.map((appr, index) => {
        if (!appr?.paymentsCompanyDeletedAdditionalCharge?.isAutoDeleted) {
          filterData.rows.push(appr);
        }
      });

      setFilteredApprovalList(filterData);
    }
  }, [approvalList]);

  useEffect(() => {
    getPaymentDetails();
    setShowActionBar(approvalList?.totalPendingCount >= 1);
  }, [approvalList?.totalPendingCount]);

  const searchComponent = () => {
    return (
      <div className={styles.searchInput}>
        <Image name='searchInputIcon.svg' className={styles.searchIcon} />
        <Input
          placeholder={'Search by candidate name or ID'}
          className={styles.inputText}
          value={empName}
          onChange={(event) => {
            setLoading(true);
            paginate(0, 0);
            setEmpName(event.target.value);
          }}
          onBlur={() => {
            setEmpName(getTrimmedValue(empName, true));
          }}
        />
        {empName ? (
          <Image
            onClick={() => {
              setLoading(true);
              setEmpName('');
            }}
            style={{ width: '20px' }}
            name='closeIcon.svg'
          />
        ) : null}
      </div>
    );
  };

  const debounceSearchTerm = useDebounce(getTrimmedValue(empName, true), 1500);

  useEffect(() => {
    fetchApprovalList({
      limit,
      offset,
      from_date: dateRange.startDate,
      to_date: dateRange.endDate,
      status: ApprovalStatus.value,
      charge_type: CostType.value,
      candidate: getTrimmedValue(empName, true)
    });
  }, [offset, ApprovalStatus, CostType, dateRange, limit]);

  useEffect(() => {
    fetchApprovalList({
      limit,
      offset,
      from_date: dateRange?.startDate,
      to_date: dateRange?.endDate,
      status: ApprovalStatus?.value,
      charge_type: CostType?.value,
      candidate: getTrimmedValue(empName, true)
    });
  }, [debounceSearchTerm]);

  const getStatusBadge = (value) => {
    if (value?.paymentsCompanyDeletedAdditionalCharge) {
      return <div>Deleted</div>;
    }
    switch (value?.status) {
      case 'APPROVED':
        return <di>Approved</di>;
      case 'AUTO_APPROVED':
        return <div>Auto-Approved</div>;
      case 'REJECTED':
        return <div>Rejected</div>;
      case 'AUTO_REJECTED':
        return <div>Auto-Rejected</div>;
      case 'APPROVAL_PENDING':
        return (
          <div className={`${styles.pendingPill} badge badge-pill badge-warning py-2`}>Pending</div>
        );
      case 'AUTO_APPROVED_INSUFF_FUNDS':
        return (
          <div className={`badge badge-pill badge-danger py-2 ${styles.autoAppPill}`}>
            <Image name='warningWhite.svg' className={styles.imageAutoApp} /> Auto Approved
          </div>
        );
      default:
        return <div className='badge badge-pill badge-primary py-1'>{value?.status}</div>;
    }
  };

  const getApproveCost = () => {
    const data = approveSingleADC?.data;
    const approveData = {
      companyId,
      additionalChargeMapping: [
        {
          userId: data?.user?.id,
          id: data?.id
        }
      ]
    };
    // for PREPAID / POSTPAID =>> call Approve API if ADC amount is less then company limit/balance
    if (data?.amount <= approvalList?.amount) {
      approveHandler(approveData);
    } else if (approvalList?.amount == null && paymentModelType === 'POSTPAID') {
      // this if means unlimited credits so approve adc
      approveHandler(approveData);
    } else {
      if (paymentModelType === 'PREPAID') {
        openBuyPage();
      } else {
        // insuf funds for approving show credit limit exhausted modal
        if (data?.amount > 0 && data?.amount > approvalList?.amount) {
          setShowCreditLimitModal(true);
        }
      }
    }
  };

  // Get Cleared Date
  const getAdcClearedDate = (data) => {
    return moment(data?.fundsClearedOn || data?.approvedOrRejectedOn).format('DD-MM-YYYY');
  };

  const getActionForInsuf = (data) => {
    if (paymentModelType === 'PREPAID') {
      return (
        <div>
          <div>Insufficient balance</div>
          <div className={styles.displayFlex}>
            <div className={`${styles.actiontext} ${styles.actionbtn}`}>
              <div
                onClick={() => {
                  setApproveSingleADC({});
                  openBuyPage();
                }}
              >
                <a
                  className={styles.anchorTextColor}
                  href={`${AppConstants.baseURL}buy?flow=approval`}
                >
                  Pay to process
                </a>
              </div>
            </div>
            <div className={styles.arrowMargin}>{`>`}</div>
          </div>
        </div>
      );
    } else {
      return <>Credit limit exhausted</>;
    }
  };
  const actions = (data) => {
    if (data?.paymentsCompanyDeletedAdditionalCharge) {
      const deleteData = data?.paymentsCompanyDeletedAdditionalCharge;
      return (
        <div className={styles.actiontext}>
          <div>Deleted & refunded by:</div>
          <div>
            <span className={styles.deleteInfo}>
              {'SpringVerify'} on {moment(deleteData?.createdAt).format('DD-MM-YYYY')}
            </span>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip id={'deleteNote'}>
                  <p>
                    {
                      DELETE_REASON.find((reason) => reason.value === deleteData?.deletionReason)
                        .label
                    }
                  </p>
                </Tooltip>
              }
            >
              <Image name='InfoIcon.svg' className={styles.image} />
            </OverlayTrigger>
          </div>
        </div>
      );
    }
    switch (data?.status) {
      case 'APPROVED':
        let name = '';
        let isUserCa = false;
        const approvedText = data?.approvedOrRejectedBy ? '(via SV)' : '';
        if (data?.caApproveOrRejectedBy) {
          // approved from company side
          name = data?.caApproveOrRejectedBy?.name || '';
          isUserCa = true;
        } else {
          name = data?.user?.name;
        }
        return (
          <div className={styles.actiontext}>
            <div>Cleared by {approvedText}:</div>
            <div>
              <span
                className={
                  isUserCa && data?.caApproveOrRejectedBy?.deletedAt
                    ? styles.deletedCaActiontext
                    : ''
                }
              >
                {name} &nbsp;
              </span>
              <span>on {getAdcClearedDate(data)}</span>
            </div>
          </div>
        );
      case 'AUTO_APPROVED':
        return (
          <div className={styles.actiontext}>
            <div>Cleared by:</div>
            <div>Automatically on {getAdcClearedDate(data)}</div>
          </div>
        );

      case 'AUTO_APPROVED_INSUFF_FUNDS':
        return getActionForInsuf(data);
      case 'REJECTED':
        const rejectedText = data?.approvedOrRejectedBy ? '(via SV)' : '';
        return (
          <div className={styles.actiontext}>
            <div>Rejected by {rejectedText}:</div>
            <div>
              <span
                className={data?.caApproveOrRejectedBy?.deletedAt ? styles.deletedCaActiontext : ''}
              >
                {data?.caApproveOrRejectedBy?.name}&nbsp;
              </span>
              <span>on {getAdcClearedDate(data)}</span>
            </div>
          </div>
        );
      case 'AUTO_REJECTED':
        return (
          <div className={styles.actiontext}>
            <div>Rejected by:</div>
            <div>Automatically on {getAdcClearedDate(data)}</div>
          </div>
        );
      case 'APPROVAL_PENDING':
        return (
          <div className={styles.modalFooter}>
            <div className={styles.modalFooterButtons}>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip>
                    <p>Approve</p>
                  </Tooltip>
                }
              >
                <div
                  className={styles.btnAction}
                  onClick={() => {
                    if (paymentModelType === PAYMENT_MODEL_TYPE.POSTPAID && showRibbonForRecharge) {
                      setShowCreditLimitModal(true);
                    } else {
                      setRejectAll(false);
                      setApproveAll(false);
                      setApproveSingleADC({ approve: true, data }); // setting data for approving single adc

                      const walletBalance = props?.approvalList?.amount;
                      const pendingAmount = data?.amount;

                      // Wallet balance is null means unlimited credits
                      if (pendingAmount > walletBalance && walletBalance !== null) {
                        setShowInsuffFunds(true);
                      } else {
                        setApproveModal(true);
                      }
                    }
                  }}
                >
                  <Image name='circledCheck.svg' />
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip>
                    <p>Reject</p>
                  </Tooltip>
                }
              >
                <div
                  className={styles.btnAction}
                  onClick={() => {
                    setRejectDetails({
                      companyId,
                      additionalChargeMapping: [
                        {
                          userId: data?.user?.id,
                          id: data?.id
                        }
                      ]
                    });
                    setRejectAll(false);
                    setRejectModal(true);
                  }}
                >
                  <Image name='uncheckCircle.svg' />
                </div>
              </OverlayTrigger>
            </div>
          </div>
        );
      case 'DELETED':
        return (
          <div className={styles.actiontext}>
            <div>Deleted and refunded by:</div>
            <div>
              <span
                className={data?.caApproveOrRejectedBy?.deletedAt ? styles.deletedCaActiontext : ''}
              >
                {data?.user?.name}&nbsp;
              </span>
              <span>on {moment(data?.approvedOrRejectedOn).format('DD-MM-YYYY')}</span>
            </div>
          </div>
        );
      default:
        return <div className='badge badge-pill badge-primary py-1'>-</div>;
    }
  };

  const getChargeType = (value) => {
    switch (value?.chargeType) {
      case 'PASS_THROUGH':
        return 'Pass Through';
      case 'INTERNATIONAL':
        return 'International';
      case 'RE_INITIATION':
        return 'Re-Initiation';
      case 'OTHER':
        const charge = (
          <>
            Others
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip id={'deleteNote'}>
                  <p>{value.publicNote}</p>
                </Tooltip>
              }
            >
              <Image name='InfoIcon.svg' className={styles.image} />
            </OverlayTrigger>
          </>
        );
        return charge;
      default:
        break;
    }
  };

  const getDescriptionText = (val) => {
    if (val) {
      return `(${val})`;
    }
    return '';
  };

  const getDescription = (check, eduChargeFor = '') => {
    switch (check?.checkType) {
      case 'CUSTOM':
        return (
          <>
            <div>Custom</div>
            <div className={styles.fontLightGrey}>{getDescriptionText(check?.checkName)}</div>
          </>
        );
      case 'ID':
        return (
          <>
            <div>Identity</div>
            <div className={styles.fontLightGrey}>
              {getDescriptionText(getIDCheck(check?.type))}
            </div>
          </>
        );
      case 'EMPLOYMENT':
        return (
          <>
            <div>Employment</div>
            <div className={styles.fontLightGrey}>{getDescriptionText(check?.companyName)}</div>
          </>
        );
      case 'EDUCATION':
        return (
          <>
            <div>Education</div>
            {eduChargeFor === EDU_CHARGE_TYPE.INSTITUTE && (
              <div className={styles.fontLightGrey}>{getDescriptionText(check?.instituteName)}</div>
            )}

            {eduChargeFor === EDU_CHARGE_TYPE.UNIVERSITY && (
              <div className={styles.fontLightGrey}>
                {getDescriptionText(check?.universityName) || '(University)'}
              </div>
            )}
          </>
        );
      case 'ADDRESS':
        return (
          <>
            <div>Address</div>
            <div className={styles.fontLightGrey}>
              {getDescriptionText(check?.type?.replace('_', ' '))}
            </div>
          </>
        );
      case 'CIBIL':
        return (
          <>
            <div>Credit Check</div>
            <div className={styles.fontLightGrey}>{getDescriptionText(check?.institute_name)}</div>
          </>
        );
      case 'REF':
        return (
          <>
            <div>Reference</div>
            <div className={styles.fontLightGrey}>{getDescriptionText(check?.employerName)}</div>
          </>
        );
      case 'COURT':
        return (
          <>
            <div>Court</div>
            <div className={styles.fontLightGrey}>
              {getDescriptionText(check?.type?.replace('_', ' '))}
            </div>
          </>
        );
      case 'DRUG':
        return (
          <>
            <div>Drug</div>
            <div className={styles.fontLightGrey}>{getDescriptionText(check?.panelType)}</div>
          </>
        );
      case 'WORLD':
        return (
          <>
            <div>World</div>
          </>
        );
      case 'SOCIAL_MEDIA':
        return (
          <>
            <div>Social Media Check</div>
          </>
        );

      default:
        return (
          <>
            <div>other</div>
          </>
        );
    }
  };
  const paginate = (pageNumber, offset) => {
    setPageNumber(pageNumber);
    setOffset(offset);
  };

  const dateChange = () => {
    if (dateRangeShow?.startDate !== null && dateRangeShow?.endDate !== null) {
      setOpenDateSelector(false);
      setDateRange((prevState) => ({
        ...prevState,
        startDate: moment(dateRangeShow?.startDate).format('YYYY-MM-DD'),
        endDate: moment(dateRangeShow?.endDate).format('YYYY-MM-DD')
      }));
    }
  };

  useEffect(() => {
    dateChange();
  }, [dateRangeShow]);

  const clearDateFilter = () => {
    clearRangePickerInput();
    setFocusedInput('startDate');

    setDateRangeShow((prevState) => ({
      ...prevState,
      startDate: null,
      endDate: null
    }));
    setDateRange((prevState) => ({
      ...prevState,
      startDate: null,
      endDate: null
    }));
  };

  const approveHandler = (data = null) => {
    /**
     * if approveAll === true,then call getFormattedData this and get all ADC in pending state and call approveADC API
     * if approveAll === false, then just call approveADC API with clicked ADC (data)
     */
    const where = approveAll ? getFormattedData() : data;
    where['send_adc_computed_data'] = true;

    if (where.additionalChargeMapping?.length) {
      approveADC(where)
        .then((response) => {
          const data = toCamelCase(response?.data?.data);
          const showAdcModal = Cookies.get('showSetAdcPopup' + userID);
          if (!showAdcModal && data?.adcNotification?.showAdcAlert) {
            const expirationDate = new Date();
            expirationDate.setHours(23, 59, 59, 999);
            Cookies.set('showSetAdcPopup' + userID, 'NO', { expires: expirationDate });
            setAdcNotification(data?.adcNotification);
            setOpenAdcModal(true);
          }
          toast.success('Charge Approved Successfully');
          fetchApprovalList();
        })
        .catch((error) => {
          console.error('error: ', error);
          fetchApprovalList();
          errToastMessage(error);
        });
    } else {
      errToastMessage('No Charge to Approve');
    }
  };

  const handleRejectHandler = () => {
    /**
     * if rejectAll === true,then call getFormattedData this and get all ADC in pending state and call rejectADC API
     * if rejectAll === false, then just call rejectADC API with clicked ADC (rejectDetails)
     */
    const where = rejectAll ? getFormattedData() : rejectDetails;
    if (where.additionalChargeMapping?.length) {
      rejectADC(where)
        .then((response) => {
          setRejectModal(false);
          fetchApprovalList();
          toast.success('Charge Reject Successfully');
        })
        .catch((error) => {
          errToastMessage(error);
          setRejectModal(false);
        });
    } else {
      errToastMessage('No Charge to Reject');
    }
  };

  const getApproveAllCost = () => {
    const sum = approvalList?.pendingAmount;

    // if sum == 0, show error popup
    if (sum === 0) {
      return errToastMessage('No Additional Cost to approve');
    }

    // for PREPAID / POSTPAID =>> call Approve API if pending amount is less then company limit/balance
    if (sum <= approvalList?.amount) {
      approveHandler(null);
    } else if (approvalList?.amount == null && paymentModelType === 'POSTPAID') {
      // this if means unlimited credits so approve adc
      approveHandler(null);
    } else {
      if (paymentModelType === 'PREPAID') {
        if (sum > 0 && sum > approvalList?.amount) {
          openBuyPage();
        }
      } else {
        if (sum > 0 && sum > approvalList?.amount) {
          setShowCreditLimitModal(true);
        }
      }
    }
  };
  const getRejectModal = () => {
    const count = approvalList?.totalPendingCount;

    if (count <= 0) {
      errToastMessage('No Charge to Reject');
    } else {
      setApproveAll(false);
      setRejectAll(true);
      setRejectModal(true);
    }
  };

  // get className based on company balance / credits
  const getClass = () => {
    const sum = approvalList?.pendingAmount;
    if (paymentModelType === 'POSTPAID' && approvalList?.amount === null) {
      return `${styles.creditLimit} ${styles.creditLimitBlackText} ${styles.amount}`;
    } else if (sum <= approvalList?.amount) {
      return `${styles.creditLimit} ${styles.creditLimitBlackText} ${styles.amount} `;
    } else {
      return `${styles.creditLimit} ${styles.creditLimitRedText} ${styles.amount}`;
    }
  };

  const openBuyPage = () => {
    window.location.href = `${AppConstants.baseURL}buy?flow=approval`;
  };

  // get cost for clear all charges
  const getTotalCost = () => {
    if (approvalList?.amount <= 0) {
      return (
        approvalList?.pendingAmount +
        Math.abs(approvalList?.insuffAmount) +
        approvalList?.additionalChargesInsuffFunds +
        Math.abs(approvalList?.amount)
      );
    } else if (approvalList?.amount > 0 && approvalList?.insuffAmount < 0) {
      let value;
      if (approveSingleADC?.data) {
        value =
          Math.abs(approvalList?.pendingAmount) +
          Math.abs(approvalList?.insuffAmount) +
          approvalList?.additionalChargesInsuffFunds -
          approvalList?.amount;
      } else {
        value =
          approvalList?.pendingAmount +
          Math.abs(approvalList?.amount + approvalList?.insuffAmount) +
          approvalList?.additionalChargesInsuffFunds;
      }
      return value;
    } else if (approvalList?.amount > 0 && !approvalList?.insuffAmount) {
      return Math.abs(approvalList?.amount - approvalList?.pendingAmount);
    } else {
      return approvalList?.pendingAmount;
    }
  };

  // get recommended cost
  const getRecomendedCost = () => {
    let cost = 0;
    if (approvalList?.amount <= 0) {
      // if compnay balance = -ve
      let cost1 =
        approvalList?.pendingAmount * 2 +
        Math.abs(approvalList.amount) +
        Math.abs(approvalList?.insuffAmount);
      return Math.ceil(cost1 / 1000) * 1000;
    } else if (approvalList?.amount > 0 && approvalList?.insuffAmount < 0) {
      cost =
        approvalList?.pendingAmount + Math.abs(approvalList?.amount + approvalList?.insuffAmount);
      return Math.ceil((cost * 2) / 1000) * 1000;
    } else {
      cost = approvalList?.pendingAmount;
      return Math.ceil((cost * 2) / 1000) * 1000;
    }
  };

  const handleOutSideClick = () => {
    setTimeout(() => {
      setOpenDateSelector(false);
    }, 100);
  };

  const getTopHeightAdjustment = () => {
    if (showActionBar && showRibbonForRecharge) {
      return styles.topHeightMax;
    }

    if (showRibbonForRecharge) {
      return styles.topHeightForRibbonCase;
    }

    if (showActionBar) {
      return styles.topHeightMid;
    }
  };

  const getAvailableBalance = () => {
    const amount = props?.approvalList?.amount;
    const isPostPaidCompany = paymentModelType === 'POSTPAID';

    if (amount === null) {
      return isPostPaidCompany ? 'Unlimited' : '';
    }

    const isAmountNegative = amount < 0;
    const res = convertToIndianNumeration(isAmountNegative ? 0 : amount);

    return res;
  };

  const getPendingCost = () => {
    return convertToIndianNumeration(props?.approvalList?.pendingAmount);
  };

  const getPendingAdcCount = () => {
    return convertToIndianNumeration(approvalList?.totalPendingCount);
  };

  return (
    <>
      <div className={styles.chargesWrapper}>
        <div className={styles.header}>
          <div className={styles.heading}>{'Additional Costs Review'}</div>
          {adcNotificationConfig?.showAdcAlert && (
            <Alert variant='light' className={styles.adcAlert}>
              <div className={styles.adcAlertContent}>
                <div className='d-flex align-items-center'>
                  <Image name='clock.svg' className={styles.clockImage} />
                  <p className={styles.adcContent}>
                    {!adcNotificationConfig?.manuallyApprovedAdcDelayInDays ||
                    !adcNotificationConfig?.manuallyApprovedAdcCount ? (
                      <>
                        Set additional costs on <strong>Auto-approval mode</strong> for faster
                        candidate verification.
                      </>
                    ) : (
                      <>
                        <strong>
                          {adcNotificationConfig?.manuallyApprovedAdcDelayInDays} days
                        </strong>{' '}
                        of delay due to{' '}
                        <strong>
                          {adcNotificationConfig?.manuallyApprovedAdcCount} manual approvals
                        </strong>
                        . Set additional costs on <strong>Auto-approval mode</strong> for faster
                        candidate verification.
                      </>
                    )}
                  </p>
                </div>
                <Button
                  variant='outline-primary'
                  onClick={handleNavigationToAdcSettings}
                  className={styles.adcSetNowBtn}
                >
                  Set now
                </Button>
              </div>
            </Alert>
          )}
        </div>
        <Card className={styles.padding24}>
          <div className={styles.topItemsDiv}>
            {showRibbonForRecharge && (
              <div className={styles.ribbonRow}>
                <div>
                  <FontAwesomeIcon
                    className={styles.warningIcon}
                    icon={faExclamationCircle}
                    color={'#FF7D7D'}
                  />
                </div>
                {paymentModelType === PAYMENT_MODEL_TYPE.PREPAID ? (
                  <div className={styles.ribbonTextDiv}>
                    {`Your wallet balance is insufficient to approve all pending costs.`}{' '}
                    <a className={styles.link} href={`${AppConstants.baseURL}buy?flow=approval`}>
                      Recharge immediately
                    </a>
                  </div>
                ) : (
                  <div className={styles.ribbonTextDiv}>
                    {`Credit limit is exhausted. Please reach out to`}{' '}
                    <a className={styles.link} href='mailto:cs@springverify.com'>
                      SpringVerify team
                    </a>{' '}
                    {`for assistance.`}
                  </div>
                )}
              </div>
            )}
            {showActionBar && (
              <div className={styles.actionBarRow}>
                <div className={styles.parentOne}>
                  <div className={showCriticalBalancePill ? styles.width70 : styles.width80}>
                    <Row>
                      <div
                        className={styles.pendingCostsTextDiv}
                      >{`Total ${getPendingAdcCount()} Pending Costs`}</div>
                    </Row>
                    <Row>
                      <div className={styles.pendingCostsAmountDiv}>
                        <span className={styles.rupeeFont}>&#8377;</span> {`${getPendingCost()}`}
                      </div>
                    </Row>
                  </div>
                  {paymentModelType === PAYMENT_MODEL_TYPE.PREPAID && (
                    <div className={showCriticalBalancePill ? styles.width30 : styles.width20}>
                      <Row className={styles.walletBalText}>Wallet balance:</Row>
                      <Row>
                        <div className={styles.walletBalDiv}>
                          <div className={styles.walletAmount}>
                            <span className={styles.rupeeFont}>&#8377;</span>{' '}
                            {`${getAvailableBalance()}`}
                          </div>
                          {showCriticalBalancePill && (
                            <div className={styles.criticalBalPill}>{`Critical balance`}</div>
                          )}
                        </div>
                      </Row>
                    </div>
                  )}
                </div>
                <Row className={styles.actionButtonsCol}>
                  <div>
                    <Button
                      variant='success'
                      active
                      className={`ml-3 ${styles.approveBtn}`}
                      onClick={() => {
                        if (
                          paymentModelType === PAYMENT_MODEL_TYPE.POSTPAID &&
                          showRibbonForRecharge
                        ) {
                          setShowCreditLimitModal(true);
                        } else {
                          setApproveSingleADC({});
                          setRejectAll(false);
                          setApproveAll(true);

                          if (showRibbonForRecharge) {
                            setShowInsuffFunds(true);
                          } else {
                            setApproveModal(true);
                          }
                        }
                      }}
                    >
                      Approve all
                    </Button>
                    <Button
                      variant='danger'
                      className={`ml-3 ${styles.rejectBtn}`}
                      onClick={() => {
                        getRejectModal();
                      }}
                    >
                      Reject all
                    </Button>
                  </div>
                </Row>
              </div>
            )}
          </div>
          <div className={`mt-4 px-0 ${styles.contentDivStyle}`}>
            <div className={styles.secondheader}>
              <div className={styles.filters}>
                <div className={styles.dateFilterField}>
                  <div style={{ display: 'flex' }}>
                    <div className={`${styles.filterByDateLabel} ${getTopHeightAdjustment()}`}>
                      Date range
                    </div>
                  </div>
                  {!reloadRangePicker ? (
                    <div
                      onClick={() => {
                        if (isEmpty(dateRange.startDate) && isEmpty(dateRange.endDate))
                          setOpenDateSelector(!openDateSelector);
                      }}
                      className={styles.dateInputField}
                    >
                      <div>
                        <div className={styles.dateTextField}>
                          <div
                            className={
                              !isEmpty(dateRange.startDate) && !isEmpty(dateRange.endDate)
                                ? styles.paddingLeft
                                : styles.topTextPadding
                            }
                          >
                            {!isEmpty(dateRange.startDate) && !isEmpty(dateRange.endDate) ? (
                              <p className={`${styles.dateText} ${styles.dateTextWidth}`}>
                                {moment(dateRangeShow?.startDate).format('DD-MM-YY')} to{' '}
                                {moment(dateRangeShow?.endDate).format('DD-MM-YY')}
                              </p>
                            ) : openDateSelector ? (
                              <p className={styles.textDateRange}>Select date range</p>
                            ) : (
                              <p className={styles.filterLabelText}>All time</p>
                            )}
                          </div>
                          <div
                            className={
                              !isEmpty(dateRange.startDate) && !isEmpty(dateRange.endDate)
                                ? styles.paddingRight
                                : styles.topTextPadding
                            }
                          >
                            {!isEmpty(dateRange.startDate) && !isEmpty(dateRange.endDate) ? (
                              <Image onClick={clearDateFilter} name='crossCircle.svg' />
                            ) : (
                              <Image singleDateRange={true} minimumNights={0} name='Mask.svg' />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div style={{ width: '181px' }}></div>
                  )}
                </div>
                <div className={styles.filterItems}>
                  <div className={`${styles.approvalStatusLabel} ${getTopHeightAdjustment()}`}>
                    Approval status
                  </div>
                  <DropdownList
                    data={APPROVAL_STATUS}
                    placeholder={<span className={styles.dropDownPlaceholder}>Pending</span>}
                    containerClassName={styles.dropDownStyle}
                    value={ApprovalStatus.label === 'Pending' ? null : ApprovalStatus.label}
                    textField='label'
                    name='approvalStatus'
                    selectIcon={<Image name='Mask.svg' />}
                    onChange={(status) => {
                      paginate(0, 0);
                      setApprovalStatus(status);
                    }}
                  />
                </div>
                <div className={styles.filterItems}>
                  <div className={`${styles.costTypeLabel} ${getTopHeightAdjustment()}`}>
                    Cost type
                  </div>
                  <DropdownList
                    containerClassName={styles.dropDownStyle}
                    placeholder={<span className={styles.dropDownPlaceholder}>All</span>}
                    data={COST_TYPE}
                    value={CostType.label === 'All' ? null : CostType.label}
                    textField='label'
                    name='costType'
                    selectIcon={<Image name='Mask.svg' />}
                    onChange={(cost) => {
                      paginate(0, 0);
                      setCostType(cost);
                    }}
                  />
                </div>
              </div>
              <div className={styles.actionsbtnsrow}>
                <div className={styles.filterItemsSearch}>{searchComponent()}</div>
              </div>
            </div>
            <div className={styles.dateRangePickerDiv}>
              {openDateSelector && (
                <DayPickerRangeController
                  onOutsideClick={handleOutSideClick}
                  numberOfMonths={2}
                  isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                  startDate={dateRangeShow.startDate}
                  endDate={dateRangeShow.endDate}
                  onDatesChange={({ startDate, endDate }) =>
                    setDateRangeShow({
                      startDate: startDate,
                      endDate: endDate
                    })
                  }
                  minimumNights={0}
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) =>
                    setFocusedInput(!focusedInput ? 'startDate' : focusedInput)
                  }
                />
              )}
            </div>
            {!approvalList?.loading && !loading ? (
              <Card className={styles.candidatesCard}>
                <Table className={styles.approvalChargesTable}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Cost Date</th>
                      <th className=''>Candidate Name</th>
                      <th className=''>Check</th>
                      <th>
                        <div>Approval</div>
                        <div>{`Delay(days)`}</div>
                      </th>
                      <th className=''>Approval Status</th>
                      <th className=''>Type/Amount</th>
                      <th className=''>Actions</th>
                    </tr>
                  </thead>
                  {filteredApprovalList?.rows.length > 0 ? (
                    <tbody>
                      {filteredApprovalList?.rows?.map((appr, index) => (
                        <tr key={index}>
                          <td>{pageNumber * 10 + index + 1}</td>
                          <td>{moment(appr?.createdAt).utc().format('DD-MM-YYYY')}</td>
                          <td>
                            <Link
                              to={'/viewCandidate?candidateId=' + appr?.candidate?.id}
                              target='_blank'
                              rel='noopener noreferrer'
                              style={{ textDecoration: 'none' }}
                              className={styles.linkStyle}
                            >
                              <div>{capitalizeName(appr?.candidate?.name || '')}</div>
                            </Link>
                            <div className={styles.fontLightGrey}>({appr?.candidate?.id})</div>
                          </td>
                          <td>{getDescription(appr?.check, appr?.educationChargedFor)}</td>
                          <td>
                            <div className='text-left'>{appr?.allAdcDelayInDays}</div>
                          </td>
                          <td>{getStatusBadge(appr)}</td>
                          <td>
                            <div className='text-left'>{getChargeType(appr)}</div>
                            <div className='text-left'>
                              <span className={styles.rupeeFont}>&#8377;</span>
                              {convertToIndianNumeration(appr?.amount)}
                              {appr?.proofDocUrl && (
                                <OverlayTrigger
                                  placement='top'
                                  overlay={
                                    <Tooltip>
                                      <span className={styles.fileTooltip}>Proof</span>
                                    </Tooltip>
                                  }
                                >
                                  <a
                                    href={getDocPipingUrlCA(
                                      appr.proofDocUuid,
                                      checkNameMap[appr.check.checkType],
                                      appr.candidate.id
                                    )}
                                    target={'_blank'}
                                    rel='noopener, noreferrer'
                                  >
                                    <img src={receipt} alt='file' />
                                  </a>
                                </OverlayTrigger>
                              )}
                            </div>
                          </td>
                          <td>{actions(appr)}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={8}>
                          <div className={styles.noDataWrapper}>
                            {isEmpty(ApprovalStatus.value) ? (
                              <Image name='NoAdc.svg' />
                            ) : ApprovalStatus.value === 'PENDING' ? (
                              <Image name={'noPurchaseHistory.svg'} />
                            ) : (
                              <Icon icon={'NoData'} color={'#666666'} width={100} height={100} />
                            )}

                            <div className={styles.noDataContent}>
                              {isEmpty(ApprovalStatus.value)
                                ? 'No additional costs found'
                                : ApprovalStatus.value === 'PENDING'
                                ? 'No Pending ADC'
                                : 'No results matched your search query'}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </Table>

                {approvalList?.count > 0 && (
                  <Form.Row className={styles.paginationWrapper}>
                    <Col className={styles.showPagination}>
                      Show{' '}
                      <select
                        className={styles.selectPagination}
                        value={limit}
                        onChange={(event) => {
                          setLimit(parseInt(event.target.value));
                          setPageSize(parseInt(event.target.value));
                          paginate(0, 0);
                        }}
                      >
                        {pageSizeOptions}
                      </select>{' '}
                      items of {count}
                    </Col>
                    <Col>
                      <Pagination
                        forcePage={pageNumber}
                        onPageChange={({ selected }) => {
                          paginate(selected, selected);
                        }}
                        pageCount={Math.ceil(count / pageSize)}
                      />
                    </Col>
                    <Col />
                  </Form.Row>
                )}
              </Card>
            ) : (
              <Card className={styles.loadingChargesCard}>
                <Loader className={styles.viewChargesLoader} />
              </Card>
            )}
          </div>
        </Card>
        {rejectModal && (
          <RejectModal
            rejectModal={rejectModal}
            setRejectModal={() => {
              setRejectModal(false);
              setRejectAll(false);
            }}
            rejectHandle={handleRejectHandler}
          />
        )}

        {showInsuffFunds && (
          <InsuffFundsModal
            setShowInsuffFunds={() => setShowInsuffFunds(false)}
            showInsuffFunds={showInsuffFunds}
            onClickInsuff={() => {
              setShowInsuffFunds(false);
              openBuyPage();
            }}
          />
        )}
        {approveModal && (
          <ApproveAllModal
            showApproveModal={approveModal}
            hiddeApproveAllModal={() => {
              setApproveSingleADC({});
              setApproveModal(false);
              setApproveAll(false);
            }}
            handleApproveAll={() => {
              setApproveSingleADC({});
              setApproveModal(false);
              getApproveAllCost();
            }}
            approveSingleADC={approveSingleADC}
            approveSingleAdcHandler={() => {
              setApproveModal(false);
              getApproveCost();
            }}
          />
        )}
        {showCreditLimitModal && (
          <CreditLimitMoal
            showCreditLimitModal={showCreditLimitModal}
            setShowCreditLimitModal={() => setShowCreditLimitModal(false)}
          />
        )}
      </div>
      <AdcInfoModal
        isOpen={isOpenAdcModal}
        adcNotification={adcNotification || {}}
        navigateToAdc={handleNavigationToAdcSettings}
        onClose={() => {
          setOpenAdcModal(false);
        }}
      />
    </>
  );
}
