import React from 'react';
import BasicDetails from '../BgvStepBodyContianer/BasicDetails';
import ContactDetails from '../BgvStepBodyContianer/ContactDetails';
import ConsentDetails from '../BgvStepBodyContianer/ConsentDetails';
import Education from '../BgvStepBodyContianer/Education';
import Identity from '../BgvStepBodyContianer/Identity';
import Employment from '../BgvStepBodyContianer/Employment';
import Address from '../BgvStepBodyContianer/Address';
import Feedback from '../BgvStepBodyContianer/Feedback';
import SocialMedia from '../BgvStepBodyContianer/SocialMedia';
export default function BgvStepContainer(props) {
  const {
    step,
    isNestedStep,
    nestedStepValue,
    finalSubmitCheck,
    candidateId,
    BGVStartDate,
    nextStep,
    prevStep,
    totalSteps,
    config,
    validateFinalSubmit,
    isConsentOnly,
    isCompany,
    internalLoading,
    isShowFeedBackPage
  } = props;
  switch (step) {
    case String(-1):
      return (
        <BasicDetails
          nextStep={nextStep}
          finalSubmitCheck={finalSubmitCheck}
          totalSteps={totalSteps}
          candidateId={candidateId}
          internalLoading={internalLoading}
        />
      );
    case String(-2):
      return (
        <ContactDetails
          nextStep={nextStep}
          prevStep={prevStep}
          finalSubmitCheck={finalSubmitCheck}
          totalSteps={totalSteps}
          candidateId={candidateId}
          internalLoading={internalLoading}
        />
      );
    case String(1):
      return (
        <Identity
          nextStep={nextStep}
          prevStep={prevStep}
          config={config}
          finalSubmitCheck={finalSubmitCheck}
          totalSteps={totalSteps}
          validateFinalSubmit={validateFinalSubmit}
          candidateId={candidateId}
          internalLoading={internalLoading}
        />
      );
    case String(4):
      return (
        <Employment
          nextStep={nextStep}
          prevStep={prevStep}
          finalSubmitCheck={finalSubmitCheck}
          totalSteps={totalSteps}
          validateFinalSubmit={validateFinalSubmit}
          candidateId={candidateId}
          internalLoading={internalLoading}
        />
      );
    case String(5):
      return (
        <Education
          nextStep={nextStep}
          prevStep={prevStep}
          isNestedStep={isNestedStep}
          nestedStepValue={nestedStepValue}
          finalSubmitCheck={finalSubmitCheck}
          totalSteps={totalSteps}
          validateFinalSubmit={validateFinalSubmit}
          candidateId={candidateId}
          internalLoading={internalLoading}
        />
      );
    case String(3):
      return (
        <Address
          nextStep={nextStep}
          prevStep={prevStep}
          config={config}
          finalSubmitCheck={finalSubmitCheck}
          totalSteps={totalSteps}
          validateFinalSubmit={validateFinalSubmit}
          candidateId={candidateId}
          isNestedStep={isNestedStep}
          nestedStepValue={nestedStepValue}
          internalLoading={internalLoading}
        />
      );
    case String(6):
      return (
        <SocialMedia
          nextStep={nextStep}
          prevStep={prevStep}
          config={config}
          finalSubmitCheck={finalSubmitCheck}
          totalSteps={totalSteps}
          validateFinalSubmit={validateFinalSubmit}
          candidateId={candidateId}
          internalLoading={internalLoading}
        />
      );
    case String(-3):
      return (
        <ConsentDetails
          prevStep={prevStep}
          nextStep={nextStep}
          finalSubmitCheck={finalSubmitCheck}
          isConsentOnly={isConsentOnly}
          totalSteps={totalSteps}
          validateFinalSubmit={validateFinalSubmit}
          isCompany={isCompany}
          candidateId={candidateId}
          BGVStartDate={BGVStartDate}
          config={config}
          internalLoading={internalLoading}
        />
      );
    case String(-4):
      return (
        <Feedback
          config={config}
          isShowFeedBackPage={isShowFeedBackPage}
          isCompany={isCompany}
          candidateId={candidateId}
        />
      );
    default:
      return (
        <Feedback
          isShowFeedBackPage={isShowFeedBackPage}
          isCompany={isCompany}
          candidateId={candidateId}
        />
      );
  }
}
