import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import styles from './Razorpay.module.scss';
import Image from '../Image';
import { getErrorMessage } from '../Utilities/utilities';
import AppConstants from '../../../../../core-components/AppConstants';
import { errToastMessage } from '../../utils/Utilities';
import { checkPaymentStatus } from '../../../../../api/company';

function RazorpayButton({
  createOrder,
  createOrderPayload,
  buttonText,
  displayValue,
  buttonIcon,
  setPaymentStatus,
  onPaymentSuccess,
  setOrderId,
  disable,
  onHide,
  setShowLoader,
  setTransactionId,
  invalidateCreditTxnDetails,
  isSA
}) {
  const [loading, setLoading] = useState(false);
  const [successOrderId, setSuccessOrderId] = useState(null);

  useEffect(() => {
    if (!successOrderId) return;

    const fetchStatus = async () => {
      try {
        const response = await checkPaymentStatus({ order_id: successOrderId });

        // Stop polling if payment is completed
        if (response.data.status === "SUCCESS") {
          if (setShowLoader !== undefined) setShowLoader(false);
          if (onPaymentSuccess) onPaymentSuccess();
          if (setTransactionId) {
            setTransactionId(response.data?.transaction_id);
          }
          setPaymentStatus('completed');
          return;
        }

        // Call again after the interval
        setTimeout(fetchStatus, 5000);
      } catch (error) {
        console.error("Error fetching payment status:", error);
      }
    };

    fetchStatus(); // Call immediately

  }, [successOrderId]);


  let orderID = '';
  const initializeRazorpayFlow = () => {
    setLoading(true);
    invalidateCreditTxnDetails();
    createOrder(createOrderPayload)
      .then((svResponse) => {
        let createOrderResponse = svResponse.data.data;
        orderID = createOrderResponse?.razorPayResp?.razor_pay_order_id
        setOrderId ? setOrderId(orderID) : null;
        let rzpPayload = {
          order_id: orderID,
          payableAmountWithTax: createOrderResponse?.razorPayResp?.inr
        };
        loadRazorpay(rzpPayload);
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.data?.message?.includes('exceeds')) {
          errToastMessage(
            'Max transaction limit allowed is 20 Lakhs. Contact cs@springverify.com to complete this purchase'
          );
        } else {
          errToastMessage(getErrorMessage(error) || error.message);
        }
        setLoading(false);
      });
  };

  const loadRazorpay = ({ order_id, payableAmountWithTax }) => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      displayRazorpay({
        order_id: order_id,
        amount: payableAmountWithTax,
        handler: (response) => {
          let formattedResponse = {
            amount: payableAmountWithTax,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature
          };
          setSuccessOrderId(response.razorpay_order_id);
          createOrderPayload.company_id
            ? (formattedResponse.company_id = createOrderPayload.company_id)
            : null;
          typeof onHide === 'function' && onHide();

          if (setShowLoader !== undefined) setShowLoader(true);
        }
      });
    }
  };

  const displayRazorpay = (options) => {
    options = { ...options, ...AppConstants.razorpayDefault };
    var rzp = new Razorpay(options);
    rzp.open();
    rzp.on('payment.failed', function (response) {
      setPaymentStatus('pending');
    });
  };

  return (
    <div className={styles.addBtnContainer}>
      <Button
        variant={'primary'}
        disabled={disable || loading}
        className={styles.payBtn}
        onClick={() => initializeRazorpayFlow()}
      >
        {buttonIcon && <Image className='mr-1' name={buttonIcon} isSA={isSA} />}
        {buttonText ? <span>{buttonText} </span> : <span>{'Pay'}</span>}
        {displayValue && (
          <span className={styles.rupee} style={{ marginLeft: '5px' }}>
            &#8377;
            {displayValue || 0}
          </span>
        )}
      </Button>
    </div>
  );
}

export default RazorpayButton;
