// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gJ8vu06WgUH8KOGupMwR {
  min-height: 160px !important;
}

.kmsBDMFGgXO_PO6niEc_ {
  max-width: 1200px;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .kmsBDMFGgXO_PO6niEc_ {
    width: 1200px;
  }
}
.kmsBDMFGgXO_PO6niEc_ .icZpLwxNBKVko5UFhH1m {
  display: none;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ {
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: -190px auto 48px;
  font-family: Poppins, sans-serif;
  color: #333333;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce {
  padding: 41px 52px 32px 57px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .AXq6Wt8P01FiLOCpNxbS {
  display: flex;
  justify-content: space-between;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .AXq6Wt8P01FiLOCpNxbS .hs2Dt6AHJpwYw8sQDXcF .XaVtzVsT0hqzGHn1KsL7 {
  display: flex;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .AXq6Wt8P01FiLOCpNxbS .hs2Dt6AHJpwYw8sQDXcF .XaVtzVsT0hqzGHn1KsL7 .bWFeRAIZnsBU7syOHesm {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin-right: 16px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .AXq6Wt8P01FiLOCpNxbS .hs2Dt6AHJpwYw8sQDXcF .XaVtzVsT0hqzGHn1KsL7 .mPryu2qoDYOQDizhYPkA {
  height: 24px;
  font-size: 14px;
  line-height: 21px;
  color: #2755fe;
  margin-top: 8px;
  padding-left: 16px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .AXq6Wt8P01FiLOCpNxbS .hs2Dt6AHJpwYw8sQDXcF .XaVtzVsT0hqzGHn1KsL7 .mPryu2qoDYOQDizhYPkA a,
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .AXq6Wt8P01FiLOCpNxbS .hs2Dt6AHJpwYw8sQDXcF .XaVtzVsT0hqzGHn1KsL7 .mPryu2qoDYOQDizhYPkA a:hover {
  text-decoration: none;
  color: #2755fe;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .AXq6Wt8P01FiLOCpNxbS .hs2Dt6AHJpwYw8sQDXcF .ZFemJrTCkBCrfXzZsiOt {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .AXq6Wt8P01FiLOCpNxbS .hs2Dt6AHJpwYw8sQDXcF .ZFemJrTCkBCrfXzZsiOt svg {
  margin-top: -2px;
  margin-right: 8px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .AXq6Wt8P01FiLOCpNxbS .hs2Dt6AHJpwYw8sQDXcF .ZFemJrTCkBCrfXzZsiOt .O_lNvynBqc7HCsOWbAXU {
  margin-left: 12px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .AXq6Wt8P01FiLOCpNxbS .Vrq7ys1wb5hqXsI0NPqV {
  display: flex;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .AXq6Wt8P01FiLOCpNxbS .Vrq7ys1wb5hqXsI0NPqV .JlA9L0dau84VTuROhJVc {
  background: #2755fe;
  border: 1px solid #2755fe;
  box-sizing: border-box;
  border-radius: 4px;
  height: 48px;
  width: 128px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding: 0;
  margin-right: 13px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .AXq6Wt8P01FiLOCpNxbS .Vrq7ys1wb5hqXsI0NPqV .JlA9L0dau84VTuROhJVc a,
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .AXq6Wt8P01FiLOCpNxbS .Vrq7ys1wb5hqXsI0NPqV .JlA9L0dau84VTuROhJVc a:hover {
  text-decoration: none;
  color: #ffffff;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .AXq6Wt8P01FiLOCpNxbS .Vrq7ys1wb5hqXsI0NPqV .jlTOpydXzsx9m4ubodzp button {
  width: 48px;
  height: 48px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .h433GHrti4MJwLK1eVdA {
  -webkit-animation: Kd5TPxRFxhl5ibeP3SjD 0.01s forwards;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-delay: 3s;
  animation: Kd5TPxRFxhl5ibeP3SjD 0.01s forwards;
  animation-iteration-count: 1;
  animation-delay: 3s;
  margin-top: 2px;
  right: 0;
  margin-right: 73px;
  position: absolute;
  z-index: 2;
  background: #e5f5e2;
  border: 1px solid #29aa10;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 12px 24px 12px 6px;
  font-size: 14px;
  line-height: 21px;
  color: #29aa10;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .h433GHrti4MJwLK1eVdA svg {
  margin-left: 10px;
}
@-webkit-keyframes Kd5TPxRFxhl5ibeP3SjD {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: -9999px;
    position: absolute;
  }
}
@keyframes Kd5TPxRFxhl5ibeP3SjD {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: -9999px;
    position: absolute;
  }
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e {
  display: flex;
  justify-content: space-between;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .oFWBIkBq2jhFOphIDE_x {
  margin-top: 60px;
  width: 420px;
  display: grid;
  grid-template-columns: 50% 50%;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .oFWBIkBq2jhFOphIDE_x .zlgBJlSJ_HyRiN2PnChU {
  margin-bottom: 16px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .oFWBIkBq2jhFOphIDE_x .zlgBJlSJ_HyRiN2PnChU .TuJsFzZTF31oD8DJ1JOq {
  font-size: 12px;
  line-height: 18px;
  color: #666666;
  margin-bottom: 2px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .oFWBIkBq2jhFOphIDE_x .zlgBJlSJ_HyRiN2PnChU .cUgZOY3pT_kA26d1ZAF5 {
  font-size: 14px;
  line-height: 21px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .dO4lToo1RZRrrHmUtPDz {
  margin-top: 60px;
  width: 320px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .zlgBJlSJ_HyRiN2PnChU .TuJsFzZTF31oD8DJ1JOq {
  margin-top: -28px;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
  margin-bottom: 2px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .zlgBJlSJ_HyRiN2PnChU .cUgZOY3pT_kA26d1ZAF5 {
  display: flex;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 44px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .zlgBJlSJ_HyRiN2PnChU .cUgZOY3pT_kA26d1ZAF5 .eGND9qGpIKf9n4_PPqZQ .isQLpCVjlVjgwJ5S4VGh {
  display: inline-block;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins, sans-serif;
  padding: 2px 8px;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  margin-right: 8px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .zlgBJlSJ_HyRiN2PnChU .cUgZOY3pT_kA26d1ZAF5 .ThVx2mFMaw5eFQTPm2WD {
  margin-left: 8px;
  border: 1px transparent solid;
  background: transparent;
  border-radius: 50%;
  padding: 0 2px;
  height: 28px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .zlgBJlSJ_HyRiN2PnChU .cUgZOY3pT_kA26d1ZAF5 .ThVx2mFMaw5eFQTPm2WD:hover {
  background: #ebefff;
  border: 1px #ebefff solid;
  border-radius: 50%;
  cursor: pointer;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .zlgBJlSJ_HyRiN2PnChU .wUZpxmxYmhSTn6N91qRw {
  display: flex;
  margin-bottom: 30px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .zlgBJlSJ_HyRiN2PnChU .wUZpxmxYmhSTn6N91qRw .rf1UZdgrQU0dePG3V8w2 {
  display: flex;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .zlgBJlSJ_HyRiN2PnChU .wUZpxmxYmhSTn6N91qRw .rf1UZdgrQU0dePG3V8w2 select {
  width: 200px;
  height: 32px;
  border: 1px solid #ebedf2;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 16px;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .zlgBJlSJ_HyRiN2PnChU .wUZpxmxYmhSTn6N91qRw .rf1UZdgrQU0dePG3V8w2 .Ge5HlPvbDPEap2Wm2w1K {
  width: 200px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .zlgBJlSJ_HyRiN2PnChU .wUZpxmxYmhSTn6N91qRw .rf1UZdgrQU0dePG3V8w2 .BBGQ7b_p6nLQ3rj5bzu6 {
  font-size: 24px;
  margin-left: 3px;
  line-height: 28px;
  height: 28px;
  border: 1px transparent solid;
  background: transparent;
  border-radius: 50%;
  padding: 0 5px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .zlgBJlSJ_HyRiN2PnChU .wUZpxmxYmhSTn6N91qRw .rf1UZdgrQU0dePG3V8w2 .BBGQ7b_p6nLQ3rj5bzu6:hover {
  color: #2755fe;
  background: #ebefff;
  border: 1px #ebefff solid;
  border-radius: 50%;
  cursor: pointer;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .zlgBJlSJ_HyRiN2PnChU .wUZpxmxYmhSTn6N91qRw .WUFlwL52bYVE3x8qr0Mv {
  display: flex;
  margin-left: 24px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .zlgBJlSJ_HyRiN2PnChU .wUZpxmxYmhSTn6N91qRw .WUFlwL52bYVE3x8qr0Mv .jMTtKXHbUHBJOau1bgjV {
  background: #2755fe;
  border: 1px solid #2755fe;
  border-radius: 4px;
  width: 90px;
  height: 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .zlgBJlSJ_HyRiN2PnChU .wUZpxmxYmhSTn6N91qRw .WUFlwL52bYVE3x8qr0Mv .VrbRM3NBWoYPJyeOsBcX {
  font-size: 17px;
  font-weight: 500;
  margin-left: 3px;
  line-height: 28px;
  height: 28px;
  border: 1px transparent solid;
  background: transparent;
  border-radius: 50%;
  padding: 0 5px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .zlgBJlSJ_HyRiN2PnChU .wUZpxmxYmhSTn6N91qRw .WUFlwL52bYVE3x8qr0Mv .VrbRM3NBWoYPJyeOsBcX:hover {
  color: #2755fe;
  background: #ebefff;
  border: 1px #ebefff solid;
  border-radius: 50%;
  cursor: pointer;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .CxRf4tNkFur8rDi3LB9n .FMGVjXZG3ydnFjN6Vkpw {
  display: flex;
  width: 300px;
  height: 70px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .CxRf4tNkFur8rDi3LB9n .FMGVjXZG3ydnFjN6Vkpw .N2vVooaYtt0v09TE__yZ {
  display: flex;
  flex-direction: column;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .CxRf4tNkFur8rDi3LB9n .FMGVjXZG3ydnFjN6Vkpw .TCiQRBMmLEIoPcehyO_A .fdEVmPqQStI3cT7aWzoo {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .qKhe006g7EjHvShjfcce .ABMioOjvKccx7OXsG36e .CxRf4tNkFur8rDi3LB9n .FMGVjXZG3ydnFjN6Vkpw .TCiQRBMmLEIoPcehyO_A .HPNo3W56fhNFCefUVIwE {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .F3MTPMHj607_4RT2GzPv {
  position: absolute;
  top: 15.5rem;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .F3MTPMHj607_4RT2GzPv .TuJsFzZTF31oD8DJ1JOq {
  font-size: 12px;
  line-height: 18px;
  color: #666666;
  margin-bottom: 2px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .F3MTPMHj607_4RT2GzPv .cUgZOY3pT_kA26d1ZAF5 {
  display: flex;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 44px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .F3MTPMHj607_4RT2GzPv .cUgZOY3pT_kA26d1ZAF5 .MtbfpHrMRSf8Q0yu7SmQ {
  max-width: 180px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .F3MTPMHj607_4RT2GzPv .cUgZOY3pT_kA26d1ZAF5 .eGND9qGpIKf9n4_PPqZQ .isQLpCVjlVjgwJ5S4VGh {
  display: inline-block;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Poppins, sans-serif;
  padding: 2px 8px;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  margin-right: 8px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .F3MTPMHj607_4RT2GzPv .cUgZOY3pT_kA26d1ZAF5 .ThVx2mFMaw5eFQTPm2WD {
  margin-left: 8px;
  border: 1px transparent solid;
  background: transparent;
  border-radius: 50%;
  padding: 0 2px;
  height: 28px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .F3MTPMHj607_4RT2GzPv .cUgZOY3pT_kA26d1ZAF5 .ThVx2mFMaw5eFQTPm2WD:hover {
  background: #ebefff;
  border: 1px #ebefff solid;
  border-radius: 50%;
  cursor: pointer;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .rplIuu4xBg5OFkoSzExo {
  min-height: 1030px;
  display: flex;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .rplIuu4xBg5OFkoSzExo .JpSvUyeLZguVetk75huB {
  border-right: 1px solid #e5e5e5;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .rplIuu4xBg5OFkoSzExo .JpSvUyeLZguVetk75huB .ESR6fkhw17RnpWTsNZ0W {
  width: 288px;
  padding: 12px 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: flex-start;
  color: #666666;
  cursor: pointer;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .rplIuu4xBg5OFkoSzExo .JpSvUyeLZguVetk75huB .ESR6fkhw17RnpWTsNZ0W img {
  margin-right: 32px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .rplIuu4xBg5OFkoSzExo .JpSvUyeLZguVetk75huB .ESR6fkhw17RnpWTsNZ0W:hover {
  background: #f6f6f6;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .rplIuu4xBg5OFkoSzExo .JpSvUyeLZguVetk75huB .vmRmNTMyd4v1U2e1swUR {
  color: #2755fe !important;
  background: #ebefff !important;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .rplIuu4xBg5OFkoSzExo .JpSvUyeLZguVetk75huB .vmRmNTMyd4v1U2e1swUR img {
  margin-right: 32px;
}
.kmsBDMFGgXO_PO6niEc_ .VTXVZgdqQwiwbakAUC1_ .rplIuu4xBg5OFkoSzExo .b2d6hyutwWr_2b0ExO0C {
  padding: 48px 32px 0 42px;
  width: 100%;
}

.s1S62rRUzJgHDgD6V6RK {
  max-width: 1200px;
  height: 1315px;
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: -190px auto 48px;
  font-family: Poppins, sans-serif;
  color: #333333;
}
@media only screen and (max-width: 1200px) {
  .s1S62rRUzJgHDgD6V6RK {
    width: 1200px;
  }
}
.s1S62rRUzJgHDgD6V6RK .JZ_H_MrG98dG8utbIvft {
  position: fixed;
  top: 50%;
  left: 50%;
}

.cQCoxisXgF0Z5lh58wSg {
  width: 1200px;
  height: 1315px;
  background: #ffffff;
  box-shadow: 0px 0px 13px rgba(82, 103, 65, 0.16);
  border-radius: 4px;
  margin: -190px auto 48px;
  font-family: Poppins, sans-serif;
  color: #333333;
  padding: 84px;
}
.cQCoxisXgF0Z5lh58wSg .JYvaE2wyRpK5KCxDEos1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  color: #666666;
}
.cQCoxisXgF0Z5lh58wSg .CpgtjnW_ANU4AVe88S2b {
  margin-top: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #2755fe;
  cursor: pointer;
}

.lXhGQyw9FRhN3VYO6zUo {
  min-height: 400px;
  font-family: Poppins, sans-serif;
  text-align: center;
  color: #666666;
}

.z0vG1QIrcwvG5jkq7Fdf {
  padding-left: 3px;
  text-decoration: none;
  color: #2755fe;
}

.z0vG1QIrcwvG5jkq7Fdf:hover {
  text-decoration: none;
  color: #2755fe;
}

.noHO63CW5FCTzEfAnQet {
  margin-top: 30px;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
}

.fvQcEhG5XA_DeCUEkRm5 {
  margin-top: -30px;
  margin-bottom: 40px;
  font-size: 13px;
  color: #dc3545;
}

.NOVsSmz3J9_lzs2DceEa {
  color: #999999;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `gJ8vu06WgUH8KOGupMwR`,
	"viewCandidateWrapper": `kmsBDMFGgXO_PO6niEc_`,
	"displayNone": `icZpLwxNBKVko5UFhH1m`,
	"viewCandidateCard": `VTXVZgdqQwiwbakAUC1_`,
	"candidateInfoCard": `qKhe006g7EjHvShjfcce`,
	"infoHeader": `AXq6Wt8P01FiLOCpNxbS`,
	"infoHeaderLeft": `hs2Dt6AHJpwYw8sQDXcF`,
	"info": `XaVtzVsT0hqzGHn1KsL7`,
	"candidateName": `bWFeRAIZnsBU7syOHesm`,
	"resumeLink": `mPryu2qoDYOQDizhYPkA`,
	"status": `ZFemJrTCkBCrfXzZsiOt`,
	"overlayTooltip": `O_lNvynBqc7HCsOWbAXU`,
	"infoHeaderRight": `Vrq7ys1wb5hqXsI0NPqV`,
	"viewReportBtn": `JlA9L0dau84VTuROhJVc`,
	"optionBtn": `jlTOpydXzsx9m4ubodzp`,
	"emailNotification": `h433GHrti4MJwLK1eVdA`,
	"seconds": `Kd5TPxRFxhl5ibeP3SjD`,
	"infoContent": `ABMioOjvKccx7OXsG36e`,
	"basicInfo": `oFWBIkBq2jhFOphIDE_x`,
	"infoCard": `zlgBJlSJ_HyRiN2PnChU`,
	"infoLabel": `TuJsFzZTF31oD8DJ1JOq`,
	"infoValue": `cUgZOY3pT_kA26d1ZAF5`,
	"categoryInfo": `dO4lToo1RZRrrHmUtPDz`,
	"tagWrapper": `eGND9qGpIKf9n4_PPqZQ`,
	"tag": `isQLpCVjlVjgwJ5S4VGh`,
	"editCategoryIcon": `ThVx2mFMaw5eFQTPm2WD`,
	"editCategoryContainer": `wUZpxmxYmhSTn6N91qRw`,
	"categoryDropdown": `rf1UZdgrQU0dePG3V8w2`,
	"widgetCategoryDropdown": `Ge5HlPvbDPEap2Wm2w1K`,
	"addIcon": `BBGQ7b_p6nLQ3rj5bzu6`,
	"editCategoryBtns": `WUFlwL52bYVE3x8qr0Mv`,
	"saveBtn": `jMTtKXHbUHBJOau1bgjV`,
	"cancelBtn": `VrbRM3NBWoYPJyeOsBcX`,
	"moreInfo": `CxRf4tNkFur8rDi3LB9n`,
	"timelineSingle": `FMGVjXZG3ydnFjN6Vkpw`,
	"tlProgress": `N2vVooaYtt0v09TE__yZ`,
	"tlContent": `TCiQRBMmLEIoPcehyO_A`,
	"tlConetntHeading": `fdEVmPqQStI3cT7aWzoo`,
	"tlContentSub": `HPNo3W56fhNFCefUVIwE`,
	"emailAddressInfo": `F3MTPMHj607_4RT2GzPv`,
	"candidateEmail": `MtbfpHrMRSf8Q0yu7SmQ`,
	"candidateVerificationCard": `rplIuu4xBg5OFkoSzExo`,
	"verificationNavbar": `JpSvUyeLZguVetk75huB`,
	"navItem": `ESR6fkhw17RnpWTsNZ0W`,
	"navItemActive": `vmRmNTMyd4v1U2e1swUR`,
	"verificationContent": `b2d6hyutwWr_2b0ExO0C`,
	"loadingviewCandidateCard": `s1S62rRUzJgHDgD6V6RK`,
	"viewCandidateLoader": `JZ_H_MrG98dG8utbIvft`,
	"candidateNotFoundCard": `cQCoxisXgF0Z5lh58wSg`,
	"notFoundTitle": `JYvaE2wyRpK5KCxDEos1`,
	"homeLink": `CpgtjnW_ANU4AVe88S2b`,
	"olderDashboard": `lXhGQyw9FRhN3VYO6zUo`,
	"olderDashboardLink": `z0vG1QIrcwvG5jkq7Fdf`,
	"olderDashboardText": `noHO63CW5FCTzEfAnQet`,
	"dpErrorMsg": `fvQcEhG5XA_DeCUEkRm5`,
	"deletedUser": `NOVsSmz3J9_lzs2DceEa`
};
export default ___CSS_LOADER_EXPORT___;
