// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sab3huZjCbcTO1HVPguU {
  display: flex;
}

.bpme1aFDzRlTCBUBlN0c {
  width: 150px !important;
  position: relative;
  left: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.VOHmM4widrUq5BhBMwqX {
  height: fit-content !important;
}

.I2SBitKFISKrAglIdRmn {
  font-size: 11px !important;
  color: #666666 !important;
  display: flex !important;
  margin-top: 5px !important;
  padding-inline: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: fit-content !important;
}

.TPI_u59GtaVqaOQ82mgM {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.mVkq8KwLec2ErMzUzRy1 {
  margin-right: 24px;
}

.sab3huZjCbcTO1HVPguU {
  width: 100%;
  height: 100%;
}

.ZAZZYcwavMJLZ31mxfOg {
  width: 48px;
  height: 48px;
  transition: transform 0.2s ease-in-out;
}
.ZAZZYcwavMJLZ31mxfOg:hover {
  transform: scale(1.1);
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `sab3huZjCbcTO1HVPguU`,
	"checkNameContainer": `bpme1aFDzRlTCBUBlN0c`,
	"swiperStyles": `VOHmM4widrUq5BhBMwqX`,
	"checkName": `I2SBitKFISKrAglIdRmn`,
	"marquee": `TPI_u59GtaVqaOQ82mgM`,
	"icon": `mVkq8KwLec2ErMzUzRy1`,
	"checkIcon": `ZAZZYcwavMJLZ31mxfOg`
};
export default ___CSS_LOADER_EXPORT___;
