import moment from 'moment';
import alumniValidator from './validators.js';

import * as XLSX from 'xlsx';

export function shortId() {
  return '_' + Math.random().toString(36).substr(2, 9);
}

export function randomColor() {
  return `hsl(${Math.floor(Math.random() * 360)}, 95%, 90%)`;
}

export function convertColumnsToRows(data, count, flow) {
  const keys = Object.keys(data);
  const numRows = data[keys[1]]['rowData'].length;

  const resultArray = [];
  const errorArray = [];

  for (let i = 0; i < numRows; i++) {
    const newObj = {};
    for (const key of keys) {
      if (!data[key].selectedHeader || data[key].selectedHeader.value === -1) {
        continue;
      }
      if (data[key] && data[key]?.type === 'date') {
        const dateFormat = data[key]?.date_format;
        const date = data[key]['rowData'][i]?.replaceAll(' ', '').replace(/\b\d\b/g, '0$&');
        if (moment(date, dateFormat, true).isValid()) {
          newObj[key] = moment(date, dateFormat).format('YYYY-MM-DD');
          continue;
        }
      }
      if (data[key] && (data[key]?.name.includes('Phone') || data[key]?.type === 'email')) {
        let value = data[key]?.['rowData']?.[i]?.replaceAll(' ', '') || '';

        if (
          data[key]?.name.includes('Phone') &&
          value[0] !== '+' &&
          Number.isInteger(parseInt(value[0], 10))
        ) {
          value = `+91${value}`;
        }
        newObj[key] = value;
        continue;
      }
      newObj[key] = data[key]?.['rowData']?.[i]?.replaceAll(/\s+/g, ' ')?.trim() || '';
    }
    resultArray.push(newObj);
    if (flow === 'alumni') errorArray.push(alumniValidator.validateEmployeeFields(newObj));
    else errorArray.push(alumniValidator.validateCandidateFields(newObj, data));
  }
  if (count === -1) return { resultArray, errorArray };
  return { resultArray: resultArray.slice(0, count), errorArray: errorArray.slice(0, count) };
}

export function createFile({ data, type = 'excel', fileName = 'AlumniRecords' }) {
  if (!data) return;
  const ws = XLSX.utils.json_to_sheet(data);
  if (type === 'csv') {
    const csv = XLSX.utils.sheet_to_csv(ws);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${fileName}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return;
  }
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, fileName);
  XLSX.writeFile(wb, `${fileName}.xlsx`);
}

export function getTrimmedValue(value, key) {
  const noSpace = [
    'date_of_birth',
    'date_of_joining',
    'last_working_day',
    'phone_number',
    'rm_phone',
    'rm_email',
    'personal_email',
    'business_email'
  ].includes(key);
  let val = String(value);
  return noSpace ? val?.replaceAll(' ', '') : val?.replaceAll(/\s+/g, ' ')?.trim();
}

export const dateFormats = [
  'DD-MMM-YY',
  'MMM-DD-YYYY',
  'DD-MMM-YYYY',
  'DD-MM-YYYY',
  'MM-DD-YYYY',
  'YYYY-MM-DD',
  'DD/MM/YYYY',
  'MM/DD/YYYY',
  'YYYY/MM/DD',
  'DD.MM.YYYY',
  'MM.DD.YYYY',
  'YYYY.MM.DD'
];

export function getAbsoluteDateFormat(value) {
  if (!value) return null;
  let validCount = 0;
  let dateFormat = '';
  dateFormats.forEach((format) => {
    if (moment(value.replace(/\b\d\b/g, '0$&'), format, true).isValid()) {
      validCount++;
      dateFormat = format;
    }
  });
  if (validCount === 1) {
    return dateFormat;
  }
  return null;
}
