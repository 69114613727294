// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SF5OjRBfIt4afQWO0ZqW {
  padding-bottom: 7px;
  padding-top: 16px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.SF5OjRBfIt4afQWO0ZqW .CazbCdzLODSfEqTrPnOQ {
  border: none;
  cursor: pointer;
}
.SF5OjRBfIt4afQWO0ZqW .CazbCdzLODSfEqTrPnOQ .LM3EpTNhpSSEcBeV5sAK {
  display: flex;
  background: #ffffff;
  border: none;
  padding-left: 0;
  padding-right: 0;
}
.SF5OjRBfIt4afQWO0ZqW .CazbCdzLODSfEqTrPnOQ .LM3EpTNhpSSEcBeV5sAK .yvTTfX1AwunZ35EgX0tw {
  margin-right: 5px;
  position: relative;
}
.SF5OjRBfIt4afQWO0ZqW .CazbCdzLODSfEqTrPnOQ .LM3EpTNhpSSEcBeV5sAK .yvTTfX1AwunZ35EgX0tw .BC6CmLZZg0ySJct_O8iF {
  position: absolute;
  top: -6px;
  left: 11px;
  font-size: 12px;
}
.SF5OjRBfIt4afQWO0ZqW .CazbCdzLODSfEqTrPnOQ .LM3EpTNhpSSEcBeV5sAK .mTEbNURtwiRLMMhV3QcX {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: 8px;
}
.SF5OjRBfIt4afQWO0ZqW .CazbCdzLODSfEqTrPnOQ .LM3EpTNhpSSEcBeV5sAK .mTEbNURtwiRLMMhV3QcX .NxvrKQe_dRaP28j1f7Ka {
  display: flex;
  text-align: right;
  font-weight: normal;
  font-size: 14px;
  color: #666666;
}
.SF5OjRBfIt4afQWO0ZqW .CazbCdzLODSfEqTrPnOQ .LM3EpTNhpSSEcBeV5sAK .mTEbNURtwiRLMMhV3QcX .NxvrKQe_dRaP28j1f7Ka .ryKnjSQbxZPVSvxcIp71 {
  font-family: roboto;
}
.SF5OjRBfIt4afQWO0ZqW .CazbCdzLODSfEqTrPnOQ .LM3EpTNhpSSEcBeV5sAK .mTEbNURtwiRLMMhV3QcX .NxvrKQe_dRaP28j1f7Ka .w_y9HWJlvCqZ7_Z2L2Vk {
  margin-right: 8px;
  margin-left: 5px;
}
.SF5OjRBfIt4afQWO0ZqW .CazbCdzLODSfEqTrPnOQ .BMLPjOqKFqJTvujxhcOG {
  padding-left: 0;
  padding-right: 0;
}
.SF5OjRBfIt4afQWO0ZqW .CazbCdzLODSfEqTrPnOQ .BMLPjOqKFqJTvujxhcOG .lFHTi07lYyRSE2p1mWoR {
  margin-left: 30px;
}
.SF5OjRBfIt4afQWO0ZqW .CazbCdzLODSfEqTrPnOQ .BMLPjOqKFqJTvujxhcOG .lFHTi07lYyRSE2p1mWoR .U_V__3dn9KadZ4ZlU9CW {
  border: none;
  padding: 0;
  display: flex;
  align-items: baseline;
}
.SF5OjRBfIt4afQWO0ZqW .CazbCdzLODSfEqTrPnOQ .BMLPjOqKFqJTvujxhcOG .lFHTi07lYyRSE2p1mWoR .U_V__3dn9KadZ4ZlU9CW .TMFiRHgzfxKdbPc_RUMx,
.SF5OjRBfIt4afQWO0ZqW .CazbCdzLODSfEqTrPnOQ .BMLPjOqKFqJTvujxhcOG .lFHTi07lYyRSE2p1mWoR .U_V__3dn9KadZ4ZlU9CW .xefocVlb45wbYu_eIgkQ {
  font-weight: normal;
  font-size: 14px;
  color: #595959;
  margin-bottom: 12px;
}
.SF5OjRBfIt4afQWO0ZqW .CazbCdzLODSfEqTrPnOQ .BMLPjOqKFqJTvujxhcOG .lFHTi07lYyRSE2p1mWoR .U_V__3dn9KadZ4ZlU9CW .Gl3ZEJOS8sNZ7TrxR6Nz {
  margin-bottom: 0;
}
.SF5OjRBfIt4afQWO0ZqW .CazbCdzLODSfEqTrPnOQ .BMLPjOqKFqJTvujxhcOG .lFHTi07lYyRSE2p1mWoR .U_V__3dn9KadZ4ZlU9CW .nB4iERGITzLSY7S7yM4G {
  margin-left: 18px;
}

.JWwOQ0SryHRyYMkvuLBE svg {
  width: 20px;
  height: 20px;
}

.JWwOQ0SryHRyYMkvuLBE svg path {
  fill: rgba(39, 85, 254, 0.5) !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addonsContainer": `SF5OjRBfIt4afQWO0ZqW`,
	"addonsContainerCard": `CazbCdzLODSfEqTrPnOQ`,
	"addonsContainerHeader": `LM3EpTNhpSSEcBeV5sAK`,
	"iconContainer": `yvTTfX1AwunZ35EgX0tw`,
	"plusIcon": `BC6CmLZZg0ySJct_O8iF`,
	"headingRightContainer": `mTEbNURtwiRLMMhV3QcX`,
	"headingRight": `NxvrKQe_dRaP28j1f7Ka`,
	"rupee": `ryKnjSQbxZPVSvxcIp71`,
	"startFrom": `w_y9HWJlvCqZ7_Z2L2Vk`,
	"cardBody": `BMLPjOqKFqJTvujxhcOG`,
	"listGroup": `lFHTi07lYyRSE2p1mWoR`,
	"listGroupItem": `U_V__3dn9KadZ4ZlU9CW`,
	"otherText": `TMFiRHgzfxKdbPc_RUMx`,
	"label": `xefocVlb45wbYu_eIgkQ`,
	"formGroup": `Gl3ZEJOS8sNZ7TrxR6Nz`,
	"listGroupInside": `nB4iERGITzLSY7S7yM4G`,
	"SocialMediaIcon": `JWwOQ0SryHRyYMkvuLBE`
};
export default ___CSS_LOADER_EXPORT___;
