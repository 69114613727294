import * as Yup from 'yup';

export default Yup.object().shape({
  otherDetails: Yup.string().when('docType', {
    is: 'others',
    then: Yup.string()
      .min(2, 'Please provide a valid document type')
      .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>]*$/, 'Please provide a valid document type')
      .required('Please enter the document type')
  })
});
