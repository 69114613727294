import validator from 'validator';
import moment from 'moment';
import utils from './validators-utils.js';

const { isValidName, isAtLeastNYearsDate, formatPhoneNumber, isValidPhoneNumber } = utils;

// Name validations
const validateName = (fieldName, value, required = false, failFields = {}) => {
  if (required && !value) {
    failFields[fieldName] = `${fieldName.replaceAll('_', ' ')} is a mandatory field`;
    return false;
  }
  if (value && !isValidName(value)) {
    failFields[fieldName] =
      'Only alphabets, space character, hyphen, inverted comma and dot is allowed';
    return false;
  }

  return true;
};

// Date validations
const validateDate = (fieldName, required = false, value, failFields = {}) => {
  if (required && !value && fieldName !== 'date_of_birth') {
    failFields[fieldName] = `${fieldName.replaceAll('_', ' ')} is a mandatory field`;
    return false;
  }
  if (value && !moment(value, 'YYYY-MM-DD', true).isValid()) {
    if (fieldName === 'doj') {
      failFields[fieldName] = `Date of Joining is not in YYYY-MM-DD format`;
    } else {
      failFields[fieldName] = `${fieldName.replaceAll('_', ' ')} is not in YYYY-MM-DD format`;
    }
    return false;
  }

  return true;
};

const validateDateOfJoiningAndLastWorkingDay = (
  date_of_joining,
  last_working_day,
  failFields = {}
) => {
  let isValid = true;

  // Check if date_of_joining is same as last_working_day
  if (date_of_joining === last_working_day) {
    failFields.date_of_joining = 'date of joining cannot be same as last working day';
    isValid = false;
  }
  // Check if date_of_joining  is not greater than last_working_day
  if (date_of_joining > last_working_day) {
    failFields.date_of_joining = 'date of joining has to be before last working day';
    isValid = false;
  }

  return isValid;
};

const validateDateOfBirth = (
  dateOfBirth,
  dateOfJoining = null,
  lastWorkingDay = null,
  failFields = {}
) => {
  const isAtLeast10YearsDate = isAtLeastNYearsDate({
    date: dateOfBirth,
    nYearsToValidate: 10
  });

  let isValid = true;
  if (!isAtLeast10YearsDate) {
    failFields.date_of_birth = 'date of birth cannot be less than 10 years from today';
    isValid = false;
  }

  if (dateOfJoining && dateOfBirth > dateOfJoining) {
    failFields.date_of_birth = 'date of birth has to be before date of joining';
    isValid = false;
  }

  if (lastWorkingDay && dateOfBirth > lastWorkingDay) {
    failFields.date_of_birth = 'date of birth has to be before last working day';
    isValid = false;
  }

  return isValid;
};

// Email Validations
const validateEmail = (fieldName, value, failFields = {}, required = false) => {
  if (value && !validator.isEmail(value.toString())) {
    failFields[fieldName] = 'Invalid email format';
    return false;
  }
  if (required && !value) {
    failFields[fieldName] = `${fieldName.replaceAll('_', ' ')} is a mandatory field`;
    return false;
  }

  return true;
};

// Phone validations
const validatePhoneNumber = (
  fieldName,
  value,
  isRmPhone = false,
  failFields = {},
  required = false,
  isFormatRequired = true
) => {
  if (value) {
    const formattedPhoneNumber =
      isRmPhone || !isFormatRequired ? value : formatPhoneNumber({ phoneNumber: value });

    const { valid: validPhoneNumber } = isValidPhoneNumber(formattedPhoneNumber, isRmPhone);

    if (!validPhoneNumber) {
      failFields[fieldName] = 'Invalid phone number';
      return false;
    } else {
      return true;
    }
  }
  if (required && !value) {
    failFields[fieldName] = `${fieldName.replaceAll('_', ' ')} is a mandatory field`;
    return false;
  }
  return true;
};

// Designation validations
const validateDesignation = (fieldName, value, required = false, failFields = {}) => {
  if (required && !value) {
    failFields[fieldName] = `${fieldName} is a mandatory field`;
    return false;
  }
  if (value && (typeof value !== 'string' || !value.length)) {
    failFields[fieldName] = `invalid ${fieldName.replace('_', ' ')}`;
    return false;
  }
  if (value && !/^[a-zA-Z0-9_ \-.]+$/.test(value)) {
    failFields[fieldName] = 'Please enter a valid exit designation value.';
    return false;
  }
  return true;
};

const validateStaffIdAndDob = (dob, staffId, failFields = {}) => {
  if (!dob && !staffId) {
    failFields['staff_id'] = `Please provide either valid Staff ID or Date of Birth`;
    failFields['date_of_birth'] = `Please provide either valid Staff ID or Date of Birth`;
    return false;
  }
  if (staffId && /^[^a-zA-Z0-9]+$/.test(staffId)) {
    failFields['staff_id'] = 'Please provide valid Staff ID';
    return false;
  }
  return true;
};

const validateEmployeeFields = (employee) => {
  let failFields = {};

  // Name validations
  validateName('full_name', employee.full_name, true, failFields);
  validateName('rm_name', employee.rm_name, false, failFields);

  // Date validations
  const areDatesValid = {};
  ['date_of_joining', 'last_working_day', 'date_of_birth'].forEach((date) => {
    areDatesValid[date] = validateDate(date, true, employee[date], failFields);
  });

  validateStaffIdAndDob(employee['date_of_birth'], employee['staff_id'], failFields);

  const { date_of_joining, last_working_day, date_of_birth } = employee;
  if (areDatesValid['date_of_birth']) {
    validateDateOfBirth(date_of_birth, date_of_joining, last_working_day, failFields);
  }
  if (areDatesValid['last_working_day'] && areDatesValid['date_of_joining']) {
    validateDateOfJoiningAndLastWorkingDay(date_of_joining, last_working_day, failFields);
  }

  // Email Validations
  ['personal_email', 'business_email', 'rm_email'].forEach((email) => {
    validateEmail(email, employee[email], failFields);
  });

  // Phone validations
  validatePhoneNumber('phone_number', employee.phone_number, false, failFields, false, false);
  validatePhoneNumber('rm_phone', employee.rm_phone, false, failFields, false, false);

  // Designation validations
  validateDesignation('designation', employee.designation, true, failFields);
  validateDesignation('rm_designation', employee.rm_designation, false, failFields);

  return Object.keys(failFields).length === 0 ? true : failFields;
};

const validateCandidateFields = (employee, data = {}) => {
  let failFields = {};

  // Name validations
  validateName('full_name', employee.full_name, true, failFields);
  validateName('surname', employee.surname, false, failFields);

  // Email Validations
  validateEmail('personal_email', employee['personal_email'], failFields, true);

  // Phone validations
  validatePhoneNumber('phone_number', employee.phone_number, false, failFields, true, false);

  // doj: Date validations
  validateDate('doj', data?.doj?.required, employee.doj, failFields);

  return Object.keys(failFields).length === 0 ? true : failFields;
};

export default {
  validateName,
  validateDate,
  validateDateOfBirth,
  validateDateOfJoiningAndLastWorkingDay,
  validateEmail,
  validatePhoneNumber,
  validateDesignation,
  validateEmployeeFields,
  validateCandidateFields
};
