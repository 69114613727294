// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mjIwsp7s3ee7DHSqTMFQ {
  padding: 32px;
  box-shadow: 0px 0px 16px rgba(0, 45, 211, 0.25);
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
@media screen and (max-width: 601px) {
  .mjIwsp7s3ee7DHSqTMFQ {
    padding: 16px;
    box-shadow: none;
    border: none;
    padding-bottom: 65px !important;
  }
}
.mjIwsp7s3ee7DHSqTMFQ .Tps27NX_WqO8FRqTQArk {
  padding: 0;
}
.mjIwsp7s3ee7DHSqTMFQ .Tps27NX_WqO8FRqTQArk .VivCLhSydRPJ5NSJ3YjC .iCZS66AadCZeG_M5yHaH {
  border-bottom: 1px solid #ccc; /* Apply border-bottom to all elements */
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.mjIwsp7s3ee7DHSqTMFQ .Tps27NX_WqO8FRqTQArk .VivCLhSydRPJ5NSJ3YjC .iCZS66AadCZeG_M5yHaH:last-child {
  border-bottom: none; /* Remove border-bottom for the last element */
}
.mjIwsp7s3ee7DHSqTMFQ .cCB_dxC9tOPuttnwHKM7,
.mjIwsp7s3ee7DHSqTMFQ .owOMN1KrrOV1PA5OZOMf {
  display: flex;
  align-items: center;
}
.mjIwsp7s3ee7DHSqTMFQ .owOMN1KrrOV1PA5OZOMf {
  margin-bottom: 10px;
}
.mjIwsp7s3ee7DHSqTMFQ .FndTDXeMqtLcM6n6Ov65 {
  margin-right: 20px;
  color: #8c8c8c;
}
.mjIwsp7s3ee7DHSqTMFQ .SJIz6iEV4uI15X3_OPG3 {
  margin-left: 20px;
  color: #8c8c8c;
}
.mjIwsp7s3ee7DHSqTMFQ .VSW87mA6_tF0Wx5s0XSP {
  font-size: 24px;
  line-height: 32px;
  color: #262626;
}
.mjIwsp7s3ee7DHSqTMFQ .FY3LEM871ALXB6Je8Pi0 {
  color: #999999;
  font-style: italic;
  margin-top: 15px;
}
.mjIwsp7s3ee7DHSqTMFQ form {
  margin-top: 25px;
}
.mjIwsp7s3ee7DHSqTMFQ form label {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #333333;
  margin-bottom: 6px;
}
.mjIwsp7s3ee7DHSqTMFQ form label span {
  color: red;
}
.mjIwsp7s3ee7DHSqTMFQ form .evUZMEnPLas3qVTtHiW_ {
  justify-content: space-between !important;
}
.mjIwsp7s3ee7DHSqTMFQ form .g9zeIvEAyRcxBd27L_pV {
  justify-content: unset !important;
  margin-top: 90px !important;
}
.mjIwsp7s3ee7DHSqTMFQ form .g9zeIvEAyRcxBd27L_pV button {
  border-radius: 2px;
  padding: 0.3em 1.4rem;
}
.mjIwsp7s3ee7DHSqTMFQ form .g9zeIvEAyRcxBd27L_pV .WrL3exFF0HH1EaDMQTSJ {
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
  height: 40px;
}
.mjIwsp7s3ee7DHSqTMFQ form .g9zeIvEAyRcxBd27L_pV .hVBien5TedY3jZIeIBYr {
  background: #f5f5f5;
  color: #dfdfdf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}
@media screen and (max-width: 601px) {
  .mjIwsp7s3ee7DHSqTMFQ form .g9zeIvEAyRcxBd27L_pV {
    background: #ffffff;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 12px 16px;
    box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
    display: flex;
    align-items: center;
  }
  .mjIwsp7s3ee7DHSqTMFQ form .g9zeIvEAyRcxBd27L_pV .WrL3exFF0HH1EaDMQTSJ {
    flex: 1;
  }
  .mjIwsp7s3ee7DHSqTMFQ form .g9zeIvEAyRcxBd27L_pV .hVBien5TedY3jZIeIBYr {
    flex: 1;
  }
}

@media screen and (max-width: 601px) {
  .FY3LEM871ALXB6Je8Pi0 {
    display: none;
  }
}
.oH3D9lP304HIEb6oTd16 {
  border: none;
  margin-right: 0 !important;
  background-color: white;
}
.oH3D9lP304HIEb6oTd16 .q3AQdUelsHy64N5A8i4O {
  cursor: pointer;
}
.oH3D9lP304HIEb6oTd16 .q3AQdUelsHy64N5A8i4O :disabled {
  cursor: not-allowed;
}
.oH3D9lP304HIEb6oTd16 .q3AQdUelsHy64N5A8i4O label {
  color: #595959 !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.oH3D9lP304HIEb6oTd16 button {
  border-radius: 2px;
  padding: 0.3em 1.4rem;
  margin: 4px;
}
.oH3D9lP304HIEb6oTd16 .WrL3exFF0HH1EaDMQTSJ {
  background: #2755fe;
  color: #ffffff;
  cursor: pointer;
}
.oH3D9lP304HIEb6oTd16 .hVBien5TedY3jZIeIBYr {
  background: #f5f5f5;
  color: #dfdfdf;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}
@media screen and (max-width: 601px) {
  .oH3D9lP304HIEb6oTd16 .tRMgeYNhq8cjiy86iI3H {
    position: fixed;
    left: 0;
    width: 100%;
    box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
    padding: 12px 16px;
    background: #ffffff;
    bottom: 0;
    display: flex;
    align-items: center;
  }
  .oH3D9lP304HIEb6oTd16 .tRMgeYNhq8cjiy86iI3H button {
    height: 40px;
  }
  .oH3D9lP304HIEb6oTd16 .tRMgeYNhq8cjiy86iI3H .WrL3exFF0HH1EaDMQTSJ {
    flex: 1;
  }
  .oH3D9lP304HIEb6oTd16 .tRMgeYNhq8cjiy86iI3H .hVBien5TedY3jZIeIBYr {
    flex: 1;
  }
}

.GvzjYF483QEFRDUhi5YF label {
  font-family: Poppins;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  letter-spacing: 0% !important;
}

._nbf_NiwM_5lO0gK3k1b {
  margin: 1rem 0;
}
._nbf_NiwM_5lO0gK3k1b .Q9cYvUYCv2MQQ3V_j00T {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
._nbf_NiwM_5lO0gK3k1b .KuF1knWMqcE7CkLWjTli {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"socialMediaCard": `mjIwsp7s3ee7DHSqTMFQ`,
	"socialMediaCardBody": `Tps27NX_WqO8FRqTQArk`,
	"socialMediaForm": `VivCLhSydRPJ5NSJ3YjC`,
	"socialMediaPlatformContainer": `iCZS66AadCZeG_M5yHaH`,
	"socialMediaProgressContainer": `cCB_dxC9tOPuttnwHKM7`,
	"socialMediaProgressContainerMobile": `owOMN1KrrOV1PA5OZOMf`,
	"stepText": `FndTDXeMqtLcM6n6Ov65`,
	"stepTextMobile": `SJIz6iEV4uI15X3_OPG3`,
	"socialMediaHeading": `VSW87mA6_tF0Wx5s0XSP`,
	"socialMediaInfo": `FY3LEM871ALXB6Je8Pi0`,
	"form-row": `evUZMEnPLas3qVTtHiW_`,
	"socialMediaSubmitBtn": `g9zeIvEAyRcxBd27L_pV`,
	"activeBtn": `WrL3exFF0HH1EaDMQTSJ`,
	"disabledBtn": `hVBien5TedY3jZIeIBYr`,
	"socialMediaFooter": `oH3D9lP304HIEb6oTd16`,
	"skipBtn": `q3AQdUelsHy64N5A8i4O`,
	"socialSubmitBtn": `tRMgeYNhq8cjiy86iI3H`,
	"socialUnavaliableCheckbox": `GvzjYF483QEFRDUhi5YF`,
	"noteContainer": `_nbf_NiwM_5lO0gK3k1b`,
	"noteHeading": `Q9cYvUYCv2MQQ3V_j00T`,
	"noteText": `KuF1knWMqcE7CkLWjTli`
};
export default ___CSS_LOADER_EXPORT___;
