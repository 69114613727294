import { getIntlPhoneNumber } from '../../../utils/utilities';

const filterTagCategory = (arr) => {
  const finalFilter = arr && arr.length > 0 ? arr.map((item) => item.value) : [];
  return finalFilter;
};
const intlPhoneNumber = (phoneNumber) => {
  let intlPhnNo = phoneNumber;
  if (phoneNumber) {
    intlPhnNo = getIntlPhoneNumber(phoneNumber);
  }
  return intlPhnNo;
};

const mapTagsCategory = (val) => {
  return val.map((item) => {
    return {
      category: item.label,
      id: item.value
    };
  });
};
export const formValuesToAPI = (formValues, companyServices) => {
  let service = {};
  let k = 0;
  companyServices.map((value, index) => {
    k = value.id;
    service[k] = null;
  });
  const getServices = () => {
    let j = 0;
    for (let i = 1; i <= k; i++) {
      if (j < formValues.services.length && formValues.services[j].id === i) {
        if (i === 1) {
          service[i] = {
            isSelected: true,
            selectedIdentity: formValues.identityType
          };
        } else {
          service[i] = true;
        }
        j++;
      } else {
        if (i === 1) {
          service[i] = {
            isSelected: false,
            selectedIdentity: formValues.identityType
          };
        } else {
          service[i] = false;
        }
      }
    }
    return JSON.stringify(service);
  };

  return {
    name: formValues.name,
    email: formValues.email,
    phone: intlPhoneNumber(formValues.phoneNumber),
    altPhone: intlPhoneNumber(formValues.altPhone),
    employeeId: formValues.employeeId,
    doj: formValues.doj,
    uanNumber: formValues.uanNumber,
    gender: formValues.gender,
    resume: formValues.resume,
    userCategoryId: formValues.category ? [parseInt(formValues.category)] : [],
    categoryId: filterTagCategory(formValues.tag), //need to be implement after changes in API
    services: getServices(),
    consent: formValues.consent,
    consentDoc: formValues.consentDoc,
    type: 'candidate',
    id: formValues.candidateId,
    companyCandidateMappingId: formValues.companyCandidateMappingId,
    invite: formValues.invite
  };
};

export const editCandidateAPIFormValues = (formValues) => {
  return {
    name: formValues.name,
    email: formValues.email,
    phone: `${formValues.countryCode}${formValues.phoneNumber}`,
    gender: parseInt(formValues.gender),
    type: 'candidate',
    doj: formValues.doj,
    candidateId: formValues.candidateId,
    // companyCandidateMappingId: formValues.companyCandidateMappingId,
    uanNumber: formValues.uanNumber,
    tags: mapTagsCategory(formValues.tag || []),
    category: formValues.category,
    employeeId: formValues.employeeId,
    resume: formValues.resume,
    resumeLink: formValues.resumeLink,
    phone_numbers: formValues.phone_numbers
  };
};
