// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kCBDlHxVrJK0V5rOxet8 {
  font-family: Poppins, sans-serif;
  color: #333333;
}
.kCBDlHxVrJK0V5rOxet8 .V9Ssp4rYAgA6yr3fvF0O {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.kCBDlHxVrJK0V5rOxet8 .n09WUwqIWMNHhMg9odL_ {
  font-size: 14px;
  line-height: 22px;
  color: #2755fe;
}
.kCBDlHxVrJK0V5rOxet8 .n09WUwqIWMNHhMg9odL_:hover {
  color: #2755fe;
  background-color: transparent;
  border: none;
}
.kCBDlHxVrJK0V5rOxet8 .jFjeZJ1oCtz3wurAR06O {
  width: 835px;
  margin-top: 40px;
}
.kCBDlHxVrJK0V5rOxet8 .jFjeZJ1oCtz3wurAR06O .L8GKtW_z4d7yBPI5fwd9 {
  margin-top: 8px;
  display: inline-block;
  margin-right: 65px;
  width: 350px;
}
.kCBDlHxVrJK0V5rOxet8 .jFjeZJ1oCtz3wurAR06O .L8GKtW_z4d7yBPI5fwd9 .K4ATNL7hYeu1lrIjCqXV {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.kCBDlHxVrJK0V5rOxet8 .jFjeZJ1oCtz3wurAR06O .L8GKtW_z4d7yBPI5fwd9 .K4ATNL7hYeu1lrIjCqXV img {
  margin-right: 16px;
}
.kCBDlHxVrJK0V5rOxet8 .jFjeZJ1oCtz3wurAR06O .L8GKtW_z4d7yBPI5fwd9 .r8hnAVR3wNafSi7GeCnN {
  height: 150px;
  overflow: auto;
}
.kCBDlHxVrJK0V5rOxet8 .jFjeZJ1oCtz3wurAR06O .L8GKtW_z4d7yBPI5fwd9 .r8hnAVR3wNafSi7GeCnN .JPq4RJEMRGf1wZZticXz {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.kCBDlHxVrJK0V5rOxet8 .jFjeZJ1oCtz3wurAR06O .L8GKtW_z4d7yBPI5fwd9 .r8hnAVR3wNafSi7GeCnN .JPq4RJEMRGf1wZZticXz .PD_QIYiHye60UNlq0H3l {
  max-width: 11rem;
  margin-left: 40px;
  font-size: 16px;
  line-height: 24px;
  color: #555555;
}
.kCBDlHxVrJK0V5rOxet8 .jFjeZJ1oCtz3wurAR06O .L8GKtW_z4d7yBPI5fwd9 .r8hnAVR3wNafSi7GeCnN .JPq4RJEMRGf1wZZticXz .NCNrybLmCK46AjODoHj9 {
  padding-top: 3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.kCBDlHxVrJK0V5rOxet8 .jFjeZJ1oCtz3wurAR06O .L8GKtW_z4d7yBPI5fwd9 .r8hnAVR3wNafSi7GeCnN .JPq4RJEMRGf1wZZticXz .NCNrybLmCK46AjODoHj9 svg {
  margin-right: 8px;
}
.kCBDlHxVrJK0V5rOxet8 .jFjeZJ1oCtz3wurAR06O .L8GKtW_z4d7yBPI5fwd9 .r8hnAVR3wNafSi7GeCnN .daa2lLxVnHpMyQt6O0Oo {
  justify-content: flex-end !important;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overViewContainer": `kCBDlHxVrJK0V5rOxet8`,
	"overviewHeading": `V9Ssp4rYAgA6yr3fvF0O`,
	"buttonText": `n09WUwqIWMNHhMg9odL_`,
	"overviewContent": `jFjeZJ1oCtz3wurAR06O`,
	"verificationCard": `L8GKtW_z4d7yBPI5fwd9`,
	"cardHeader": `K4ATNL7hYeu1lrIjCqXV`,
	"cardContentWrapper": `r8hnAVR3wNafSi7GeCnN`,
	"cardContent": `JPq4RJEMRGf1wZZticXz`,
	"type": `PD_QIYiHye60UNlq0H3l`,
	"status": `NCNrybLmCK46AjODoHj9`,
	"customCardContentWrapper": `daa2lLxVnHpMyQt6O0Oo`
};
export default ___CSS_LOADER_EXPORT___;
