import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith } from 'lodash';

import validationSchema from './AlumniRecords.validation';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const initialValues = {
  fullName: '',
  staffId: '',
  isStaffIdNotAvailable: false,
  dateOfBirth: '',
  personalEmail: '',
  businessEmail: '',
  countryCode: '',
  phoneNumber: '',
  designation: '',
  dateOfJoining: '',
  lastWorkingDay: '',
  employmentType: '',
  exitType: '',
  exitReason: '',
  ctc: '',
  eligibleForRehire: '',
  rmName: '',
  rmEmail: '',
  rmCountryCode: '',
  rmPhone: '',
  createdAt: '',
  updatedAt: '',
  source: '',
  location: '',
  department: ''
};

let propsValue = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      return mergeWith({}, initialValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema,
    displayName: 'Alumni Records'
  })
);
