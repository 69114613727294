import axios from 'axios';
import { omitBy } from 'lodash';
import AppConstants from '../core-components/AppConstants';
import AppHelper from '../core-components/AppHelper';
import { covertObjToQueryString, toSnakeCase } from '../utils/utilities';
import http from './http';
import localStorage from './localStorage';
const googleEnvironmentConstant = require('../config/google-auth.json');

export const getCandidates = (
  limit,
  offset,
  recentlyAdded = false,
  status = null,
  search = null,
  category = null,
  userCategory = null,
  days = null,
  dateRange = null,
  email_bounce
) => {
  const params = {
    limit,
    offset
  };
  if (status) params.status = status;
  if (search) params.search = search;
  if (category) params.category = category;
  if (userCategory) params.userCategory = userCategory;
  if (days !== null) params.days = days;
  if (dateRange.to !== null && dateRange.from !== null) params.dateRange = dateRange;
  if (email_bounce !== null) params.email_bounce = email_bounce;
  if (recentlyAdded) params.recentlyAdded = recentlyAdded;

  return http.get('company/getcandidates', { params });
};

export const getStatusCount = () => {
  return http.get('company/getDashboardCount');
};

export const getCategories = (limit, offset) => {
  return http.get('company/usercategories', {
    params: {
      limit,
      offset
    }
  });
};

export const addCategory = (data) => {
  return http.post('company/usercategory', data);
};

export const editCategory = (data) => {
  return http.post('company/usercategory', data);
};

export const deleteCategory = (id) => {
  return http.delete('company/usercategory', {
    params: {
      id: id
    }
  });
};

export const getAdministrators = (limit, offset) => {
  return http.post('company/getadmins', { limit, offset });
};

export const getRequestPaymentUsers = () => {
  return http.get('payments/purchase-order/user-details');
};
export const addAdministrators = (data) => {
  return http({
    method: 'post',
    url: `${AppConstants.saDevelopementAPIURL}company/admin`,
    data: data
  });
};

export const editAdministrators = (data) => {
  return http({
    method: 'put',
    url: `${AppConstants.saDevelopementAPIURL}company/admin`,
    data: data
  });
};

export const deleteAdministrators = (id) => {
  return http({
    method: 'delete',
    url: `${AppConstants.saDevelopementAPIURL}company/admin`,
    params: {
      admin_id: id
    }
  });
};

export const searchCandidates = (query) => {
  return http.post('company/searchcandidate', query);
};

export const editCandidate = (data) => {
  const value = toSnakeCase(data);

  return http({
    method: 'put',
    url: `${AppConstants.saDevelopementAPIURL}candidate/profile`,
    data: value,
    headers: { 'Content-Type': 'application/json' }
  });
};

export const editEmail = (data) => {
  // Creating separate because we have to pass Json data not form data
  const value = toSnakeCase(data);
  return http({
    method: 'put',
    url: `${AppConstants.saDevelopementAPIURL}candidate/profile`,
    data: value,
    headers: { 'Content-Type': 'application/json' }
  });
};

export const deleteCandidate = (id) => {
  return http({
    method: 'delete',
    url: `${AppConstants.saDevelopementAPIURL}candidate/profile?candidate_id=${id}`
  });
};

export const getCompanyServices = () => {
  return http.get('company/getcompanyservicemapping', {
    params: {
      status: 'active'
    }
  });
};

export const getInsufficiencies = (limit, offset, status) => {
  return http.post('company/insufficency', { limit, offset, status });
};

export const bgvUrl = (id) => {
  return http.get('company/bgvlink', {
    params: {
      candidateId: id
    }
  });
};

export const checkCandidateEmail = (email) => {
  return http.get('company/fetchcandidatedetail', {
    params: {
      email
    }
  });
};

export const getCandidateFormConfig = () => {
  return http.get('company/getbgvconfig');
};

export const checkCandidatePhone = (phone, candidateToExclude) => {
  return http.get('company/fetchcandidatedetail', {
    params: {
      phone,
      candidateToExclude
    }
  });
};

export const checkCandidateEmployeeId = (id) => {
  return http.get('company/fetchcandidatedetail', {
    params: {
      employeeId: id
    }
  });
};

export const fetchCandidateDetails = (id) => {
  return http.get('company/fetchcandidatedetail', {
    params: {
      candidateId: id
    }
  });
};

export const sendConsentUrl = (data) => {
  return http.post(`${AppConstants.saDevelopementAPIURL}candidate/profile/consent-mail`, data);
};

export const resendVerificationLink = (data) => {
  const value = toSnakeCase(data);
  return http({
    method: 'post',
    url: `${AppConstants.saDevelopementAPIURL}candidate/profile/bgv-link`,
    data: value,
    headers: { 'Content-Type': 'application/json' }
  });
};

export const editCandidateTag = (data) => {
  return http.put('company/editCandidateCategory', data);
};

export const editCandidateCategory = (data) => {
  return http.put('company/candidateusercategory', data);
};

export const getTags = (limit, offset) => {
  return http.get('company/categories', {
    params: {
      limit,
      offset
    }
  });
};

export const addTag = (data) => {
  return http.post('company/categories', data);
};

export const editTag = (data) => {
  return http.put('company/categories', data);
};

export const deleteTag = (id) => {
  return http.delete('company/categories', {
    params: {
      categoryId: id
    }
  });
};

export const exportCandidates = (data) => {
  return http.post('company/analyticsexcel', data);
};

export const getNewInsufficiencies = (data) => {
  let query = toSnakeCase(data);
  return http.get('/company/insufficency-ticket-list', {
    params: {
      ...query
    }
  });
};

// Fetching Insuff comments
export const getNewInsufficienciesComments = (data) => {
  return http.get('company/manual-insufficiency/comments', {
    params: {
      ...data
    }
  });
};

//Email setting get api
export const getEmailPreferences = (adminId, companyId) => {
  return http({
    method: 'get',
    url: `${AppConstants.saDevelopementAPIURL}company/settings/email-reminder?company_id=${companyId}&admin_id=${adminId}`
  });
};

//Email setting post api
export const postEmailPreferences = (data) => {
  const value = toSnakeCase(data);
  return http({
    method: 'post',
    url: `${AppConstants.saDevelopementAPIURL}company/settings/email-reminder`,
    data: value,
    headers: { 'Content-Type': 'application/json' }
  });
};

// ********** PRE-PAID PACKAGES************

// Purchase history setting post api
export const getPurchaseHistory = (limit, offset) => {
  return http.get('/payments/credits/purchase-history', {
    params: {
      limit,
      offset
    }
  });
}; // end : puchase history

// Purchase history setting post api
export const getPurchaseHistoryByID = (txnID) => {
  return http.get('/payments/credits/purchase-history', {
    params: {
      transaction_id: txnID
    }
  });
};
/**
 * Get config for add candidate
 * @param   {object} companyId company ID
 * @return  {promise}
 */
export const getAddCandidateConfig = (companyId = null) => {
  return http({
    method: 'get',
    url: `${AppConstants.saDevelopementAPIURL}company/candidate/config`
  });
};

/**
 * Get packages list for view and buy
 * @param   {object}
 * @return  {promise}
 */
export const getViewBuyPackages = (data) => {
  return http.get('payments/packages', {
    params: {
      limit: data.limit,
      offset: data.offset
    }
  });
};

/**
 * Get selected checks and addons details of a candidate
 * @param   {integer} candidateId
 * @return  {promise}
 */
export const getSelectedCheckDetails = (candidateId) => {
  return http({
    method: 'get',
    url: `${AppConstants.saDevelopementAPIURL}payments/packages/candidate-package-details?candidate_id=${candidateId}`
  });
};

/**
 * Get selected checks and addons details of a candidate
 * @param   {integer} packageId
 * @return  {promise}
 */
export const getPaymentsPackagesReviewOrder = (packageId) => {
  return http.get('payments/packages/review-order', {
    params: {
      package_id: packageId
    }
  });
};

export const buyPackage = (data) => {
  return http.post('/payments/packages/purchase', data);
};

export const getCreditTransactionHistory = (data) => {
  const where = {
    limit: data.limit,
    offset: data.offset,
    company_id: data.company_id
  };

  return http.get('payments/credits/transaction-history', {
    params: {
      ...where
    }
  });
};

export const getInsuffCredits = (data) => {
  const where = {
    limit: data.limit,
    offset: data.offset
  };

  return http.get('payments/credits/insuff-funds', {
    params: {
      ...where
    }
  });
};

export const purchaseCredits = (data) => {
  return http.post('/payments/credits/purchase', data);
};
export const checkPaymentStatus = (data) => {
  return http.post('/payments/packages/purchase/status', data);
};
export const checkPaymentStatusRequestPayment = (data) => {
  return http.post('/payments/purchase-order/purchase/status', data);
};

export const purchaseCreditsSuccess = (data) => {
  return http.post('/payments/credits/purchase-complete', data);
};

export const purchaseCreditsFailure = (data) => {
  return http.post('/payments/credits/purchase-failure', data);
};

export const finalizeRazorpayPayment = (data) => {
  return http.post('/payments/packages/purchase/finalize-razorpay', data);
};

export const finalizeRazorpayPaymentPurchaseOrder = (data) => {
  return http.post('/payments/purchase-order/purchase/finalize-razorpay', data);
};
export const submitAddCandidateConfig = (data) => {
  const value = toSnakeCase(data);

  return http({
    method: 'post',
    url: `${AppConstants.saDevelopementAPIURL}candidate/profile`,
    data: value,
    headers: { 'Content-Type': 'application/json' }
  });
};

export const getInvoiceHeader = (data) => {
  return http.get('/payments/billing/company-invoice-details');
};

export const getInvoices = (query) => {
  return http.get('/payments/billing/invoices', {
    params: { ...toSnakeCase(query) }
  });
};

export const getInvoiceDetails = (id, where) => {
  return http.get(`/payments/billing/invoice/details?id=${id}${where}`);
};

export const getApprovalList = (data) => {
  const where = {
    limit: data.limit,
    offset: data.offset,
    from_date: data.from_date,
    to_date: data.to_date,
    status: data.status,
    charge_type: data.charge_type,
    candidate: data.candidate
  };

  return http.get('company/additional-charges', {
    params: {
      ...where
    }
  });
};
/**
 * Common File Upload SA API
 * @param {JSON} data
 */
export const fileUpload = (data) => {
  let formData = new FormData();

  if (data.candidateId) {
    formData.append('candidate_id', data.candidateId);
  }
  if (data.fileType) {
    formData.append('file_type', data.fileType);
  }
  if (data.fetchResumeDetails) {
    formData.append('fetch_resume_details', true);
  }

  formData.append('file', data.file);

  return http({
    method: 'post',
    url: `${AppConstants.saDevelopementAPIURL}candidate/common/upload-file`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
}; //end of func : fileUpload

/**
 *  funtion to get Payment details for prepaid/postpaid company to show different banners
 */

export const getPaymentDetails = () => {
  return http.get('payments/packages/details');
};

/** function to reject ADC
 */
export const rejectADC = (data) => {
  return http.put(`${AppConstants.saDevelopementAPIURL}candidate/additional-charges/reject`, data);
};

/** function to approve ADC
 */
export const approveADC = (data) => {
  return http.put(`${AppConstants.saDevelopementAPIURL}candidate/additional-charges/approve`, data);
};
/** function to approve ADC
 */
export const validateDataForBulkUpload = (data) => {
  return http.post(
    `${AppConstants.saDevelopementAPIURL}candidate/profile/validations`,
    toSnakeCase(data)
  );
};

export const uploadCandidateCSV = (data) => {
  return http.post(`${AppConstants.saDevelopementAPIURL}company/candidate-bulk-upload`, data);
};

// bulk upload
export const candidatesBulkUpload = (data) => {
  const value = toSnakeCase(data);

  return http({
    method: 'post',
    url: `${AppConstants.saDevelopementAPIURL}candidate/profile/bulk-candidates`,
    data: value,
    headers: { 'Content-Type': 'application/json' }
  });
};

// validating friedly name
export const validateFriendlyName = (data) => {
  return http.post(
    `${AppConstants.saDevelopementAPIURL}payments/packages/check-subtype-friendly-name`,
    toSnakeCase(data)
  );
};

//creating friendly name for subtype
export const createFriendlyName = (data) => {
  return http.put(
    `${AppConstants.saDevelopementAPIURL}payments/packages/subtype-friendly-name`,
    toSnakeCase(data)
  );
};
export const getAddressGeocode = (data) => {
  const addressRequest = axios.create({
    transformRequest: (data, headers) => {
      delete headers.common['Authorization'];
      return data;
    }
  });
  return addressRequest({
    url: `https://maps.googleapis.com/maps/api/geocode/json?`,
    method: 'GET',
    params: {
      address: `${data.postalCode}, ${data.country}`,
      key: `${googleEnvironmentConstant[AppHelper.getEnv()].googleMapKey}`
    }
  });
};

export const getDocumentMimetype = (uuid, candidateId) => {
  return http.get(
    `${AppConstants.saDevelopementAPIURL}candidate/report/get-report-mimetype?uuid=${uuid}&candidate_id=${candidateId}`
  );
};
export const getExternalApiTokenList = (data) => {
  return http.get(
    `${AppConstants.saDevelopementAPIURL}company/api-tokens?limit=${data.limit}&offset=${data.offset}`
  );
};

export const editExternalApiTokenName = (data) => {
  return http.patch(`${AppConstants.saDevelopementAPIURL}company/api-tokens`, toSnakeCase(data));
};

export const deleteExternalApiToken = (data) => {
  return http.delete(`${AppConstants.saDevelopementAPIURL}company/api-tokens?id=${data.id}`);
};

export const postExternalApiTokenName = (data) => {
  return http.post(`${AppConstants.saDevelopementAPIURL}company/api-tokens`, toSnakeCase(data));
};

export const adminEmailValidation = (adminId, email) => {
  return http.get(
    `company/validate-admin-email?adminId=${adminId}&email=${encodeURIComponent(email)}`
  );
};
/** API endpoints for the Integrations page */
export const getIntegrations = () => {
  return http.get(`${AppConstants.saDevelopementAPIURL}company/integrations`);
};

export const postIntegrations = (data) => {
  return http({
    method: 'post',
    url: `${AppConstants.saDevelopementAPIURL}company/integrations`,
    data: data
  });
};

export const postAlumniIntegrations = (data) => {
  return http({
    method: 'post',
    url: `${AppConstants.developmentAPIURL}alumni-verification/integrations/connect`,
    data: data
  });
};

export const putIntegrations = (data) => {
  return http({
    method: 'put',
    url: `${AppConstants.saDevelopementAPIURL}company/integrations`,
    data: data
  });
};

export const deleteIntegrations = (source) => {
  return http.delete(`${AppConstants.saDevelopementAPIURL}company/integrations?source=${source}`);
};

export const getKNITAccessToken = ({ source, category }) => {
  return http.get(
    `${AppConstants.saDevelopementAPIURL}company/integrations/knit/access-token?source=${source}&category=${category}`
  );
};

export const getTRUTOLinkToken = () => {
  return http.get(`${AppConstants.saDevelopementAPIURL}company/integrations/truto/link-token`);
};

export const getTRUTOHRMSGroups = ({ integration_id }) => {
  return http.get(
    `${AppConstants.saDevelopementAPIURL}company/integrations/truto/hrms-groups?integration_id=${integration_id}`
  );
};

export const getTRUTOHRMSLocations = ({ integration_id }) => {
  return http.get(
    `${AppConstants.saDevelopementAPIURL}company/integrations/truto/hrms-locations?integration_id=${integration_id}`
  );
};

export const getTRUTOHRMSJobRoles = ({ integration_id }) => {
  return http.get(
    `${AppConstants.saDevelopementAPIURL}company/integrations/truto/hrms-job-roles?integration_id=${integration_id}`
  );
};

export const getTRUTOATSApplicationStages = ({ integration_id, source }) => {
  return http.get(
    `${AppConstants.saDevelopementAPIURL}company/integrations/truto/ats-job-interview-stages?integration_id=${integration_id}&source=${source}`
  );
};

export const getTRUTOJobs = ({ integration_id, source = null }) => {
  const params = {
    integration_id
  };
  if (source) params.source = source;

  return http.get(`${AppConstants.saDevelopementAPIURL}company/integrations/truto/ats-jobs`, {
    params
  });
};

export const initializeReputeConnection = (data) => {
  return http.post(`/repute/initialize`, data);
};

export const getUsageLogs = (companyId, limit = 10, offset = 0) => {
  return http.get(
    `${AppConstants.saDevelopementAPIURL}payments/credits/balance-logs?company_id=${companyId}&limit=${limit}&offset=${offset}`
  );
};

export const getAllowedDomains = () => {
  return http.get(`${AppConstants.saDevelopementAPIURL}company/security-settings/allowed-domains`);
};

export const getSecuritySettings = (companyId) => {
  return http.get(`${AppConstants.saDevelopementAPIURL}company/security-settings`);
};

export const postSecuritySettings = (data) => {
  return http.put(`${AppConstants.saDevelopementAPIURL}company/security-settings`, data);
};

export const editEmailCommunication = (data) => {
  return http.put(
    `${AppConstants.saDevelopementAPIURL}company/communication-settings`,
    toSnakeCase(data)
  );
};

// Additional Charges Apis with optional Query params.
export const getAdditionalChargeSettings = (params) => {
  const queryParams = covertObjToQueryString(params);
  return http.get(
    `${AppConstants.saDevelopementAPIURL}company/additional-charge-settings/company-settings?` +
      queryParams
  );
};

export const updateAdditionalChargeSettings = (data) => {
  return http.put(
    `${AppConstants.saDevelopementAPIURL}company/additional-charge-settings/company-settings`,
    data
  );
};

// Webhook endpoint url
export const integrationRequestWebhookUrlApi = (data) => {
  let webhookUrl;
  if (AppConstants.env == 'production') {
    webhookUrl = 'https://springworks.app.n8n.cloud/webhook/f1ab5e89-c727-4914-8ab7-0fc18c1bc0ab';
  } else {
    webhookUrl = 'https://springworks.app.n8n.cloud/webhook/f2e01722-15f7-419d-aebc-196f99e7cfc6';
  }
  return axios.get(
    `${webhookUrl}?email=${data.email}&ca_name=${data.ca_name}&ca_id=${data.ca_id}&phone=${data.phone}&type=${data.type}&company_name=${data.company_name}&company_id=${data.company_id}&env=${AppConstants.env}`
  );
};

export const getWalletDetail = () => {
  return http.get(`payments/credits/wallet-details`);
};

export const buyOrder = (data) => {
  return http.post('/payments/packages/purchase', data);
};

export const buyOrderByOrderId = (data) => {
  return http.post('/payments/purchase-order/purchase', data);
};
export const getPurchaseOrders = (limit = 10, offset = 0) => {
  return http.get(`/payments/purchase-order?limit=${limit}&offset=${offset}`);
};
export const requestPayment = (data) => {
  return http.post('/payments/purchase-order/request-payment', data);
};
export const cancelRequestPayment = (data) => {
  return http.put('/payments/purchase-order/cancel-order', data);
};
export const getRequestOrderDetails = (data) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${localStorage.getToken() ? localStorage.getToken() : data.token}`
    },
    proxy: {
      port: 4444
    },
    maxContentLength: 1024 * 1024 * 20
  };
  const url = `${AppConstants.developmentAPIURL}payments/purchase-order/request-payment?order_id=${data.orderId}&company_id=${data.companyId}`;
  return axios.get(url, config);
};
export const getLeverStages = () => {
  return http.get(`${AppConstants.saDevelopementAPIURL}company/integrations/lever/stages`);
};

export const getLeverTags = () => {
  return http.get(`${AppConstants.saDevelopementAPIURL}company/integrations/lever/tags`);
};

export const triggerIntegrationStage = (data) => {
  return http.put(`${AppConstants.saDevelopementAPIURL}company/integrations/trigger_stage`, data);
};

export const saveFieldMapping = (data) => {
  return http.post(
    `${AppConstants.saDevelopementAPIURL}company/integrations/field_subtype_mapping`,
    data
  );
};

export const getIntegrationFieldSubtypeMapping = (data) => {
  return http.get(
    `${AppConstants.saDevelopementAPIURL}company/integrations/field_subtype_mapping`,
    { params: data }
  );
};

export const getGreenHouseCustomFields = () => {
  return http.post(
    `${AppConstants.saDevelopementAPIURL}company/integrations/greenhouse/custom-fields`
  );
};
export const getGreenHouseCustomFieldsJobs = () => {
  return http.get(
    `${AppConstants.saDevelopementAPIURL}company/integrations/greenhouse/custom-fields-jobs`
  );
};

export const getGreenHouseUsers = () => {
  return http.get(`${AppConstants.saDevelopementAPIURL}company/integrations/greenhouse/users`);
};

export const getOrderDetailsByID = (orderID) => {
  return http.get('/payments/purchase-order', {
    params: {
      order_id: orderID
    }
  });
};

export const expirePasswordAPI = (data) => {
  return http.put('auth/expire-password', data);
};
export const getAlumniTableData = (data, tableType) => {
  const queryParams = covertObjToQueryString(omitBy(data, (v) => v == null || v == undefined));
  let apiEndpoint = '';
  switch (tableType) {
    case 'DATA_SYNC':
      apiEndpoint = '/alumni-verification/dashboard/data-sync-logs?';
      break;
    case 'ALUMNI_RECORDS':
      apiEndpoint = '/alumni-verification/dashboard/alumni-records?';
      break;
    default:
      apiEndpoint = '/alumni-verification/dashboard/recent-requests?';
      break;
  }
  return http.get(apiEndpoint + queryParams);
};

export const getUniqueRecords = (data) => {
  return http.get(`company/unique-records?source=ALUMNI`, {
    params: data
  });
};

export const getAlumniRequestTrends = () => {
  return http.get(`/alumni-verification/analytics/requests-trend`);
};

export const getAlumniRecordTrends = () => {
  return http.get(`/alumni-verification/analytics/records-trend`);
};

export const getAlumniRequestOverview = (month) => {
  return http.get(`/alumni-verification/analytics/request-overview?date_range=${month}`);
};

export const getAlumniRecordOverview = () => {
  return http.get(`/alumni-verification/analytics/records-overview`);
};

export const getAlumniRecord = (data) => {
  return http.get(`/alumni-verification/alumni/record`, {
    params: {
      employee_record_id: data.employeeRecordId
    }
  });
};

export const addAlumniRecord = (data) => {
  return http.post(`/alumni-verification/alumni`, data);
};

export const getHRMSSyncData = () => {
  return http.get(`/alumni-verification/dashboard/data-sync-logs?mode=HRMS&limit=1&offset=0`);
};

export const manualSync = (source) => {
  return http.post(`/alumni-verification/integrations/manual-sync`, {
    source
  });
};

export const manualSyncSuperQueryIntegrations = (source) => {
  return http.post(`/alumni-verification/integrations/manual-sync-super-query`, {
    source
  });
};

export const disconnectHRMS = (source, integration_type) => {
  return http.delete(
    `/alumni-verification/integrations/disconnect?source=${source}&integration_type=${integration_type}`
  );
};

export const getHRMSDetails = () => {
  return http.get(`alumni-verification/integrations/get-details`);
};

export const getBrandSettings = () => {
  return http.get(`/company/brand-settings`);
};

export const getAlumniSettings = () => {
  return http.get(`/alumni-verification/settings`);
};

export const editAlumniSettings = (data) => {
  return http.put(`/alumni-verification/settings`, toSnakeCase(data));
};

export const editBrandSettings = (data) => {
  return http.put(`/company/brand-settings`, toSnakeCase(data));
};

export const validateBulkUploadFile = (data) => {
  return http.post(`/alumni-verification/alumni/validate-bulk-upload-file`, data);
};

export const uploadExEmpCSV = (data) => {
  return http.post(`/alumni-verification/alumni/bulk-upload`, data);
};

export const validateExEmpData = (data) => {
  return http.post(`/alumni-verification/alumni/validate-records`, toSnakeCase(data));
};
export const referHRContacts = (data) => {
  return http.post('referral', data);
};
export const getReferralsAPI = (data) => {
  return http.get(`referral`, {
    params: data
  });
};
export const referralEmailValidate = (email) => {
  return http.get(`referral/validate?email_id=${email}`);
};

/**
 * API to update the recommendation events.
 * @param {Object} data required payload for the api.
 * @returns
 */
export const updateExploreNowEvent = (data) => {
  return http.put(`${AppConstants.saDevelopementAPIURL}company/upsell/recommendation-events`, data);
};

/**
 * API to update the recommendation checks list and trigger slack notification.
 * @param {Object} data required payload for the api.
 * @returns
 */
export const updateRecommendedChecks = (data) => {
  return http.put(
    `${AppConstants.saDevelopementAPIURL}company/upsell/recommendations-explore`,
    data
  );
};

/**
 * API to get the recommended checks list.
 * @param {Object} data required payload for the api.
 * @returns
 */
export const getRecommendedChecks = async (data) => {
  return http.get(`${AppConstants.saDevelopementAPIURL}company/upsell/recommendations`, {
    params: {
      page: data.page
    }
  });
};

// API for Slack Connect.
export const connectSlack = () => {
  return http.post(`${AppConstants.saDevelopementAPIURL}company/slack-connect/`);
};
// API for Teams Connect.
export const connectTeams = () => {
  return http.post(`${AppConstants.saDevelopementAPIURL}company/teams-connect/`);
};
// Get report Blob data
export const downloadReportAPI = (companyId, candidateId, token) => {
  const params = { candidate_id: candidateId, token };
  if (companyId) params['company_id'] = companyId;
  return http.get(`${AppConstants.saDevelopementAPIURL}candidate/report/pdf`, {
    params: params,
    responseType: 'blob'
  });
};

export const getReports = (data) => {
  return http.get(
    `${AppConstants.saDevelopementAPIURL}candidate/report?candidate_id=${data.candidateId}&is_internal=${data.isInternal}`
  );
};

export const getReportsExternal = (data) => {
  return http({
    method: 'get',
    url: `${AppConstants.saDevelopementAPIURL}external/v1/candidate/report?candidate_id=${data.candidateId}`,
    headers: { Authorization: `Bearer: ${data.token}` }
  });
};

export const matchColumns = (columns, options) => {
  return http.post(`${AppConstants.developmentAPIURL}ai/matchColumns`, {
    prompt: {
      columns,
      options
    }
  });
};

export const deleteAlumniRecord = (employee_record_id) => {
  return http.delete(`/alumni-verification/alumni?employee_record_id=${employee_record_id}`);
};

// API for put check on hold.
export const putCheckOnHoldOrCloseAPI = (data) => {
  return http({
    method: 'put',
    url: `${AppConstants.saDevelopementAPIURL}candidate/common/hold-close-check`,
    data: data
  });
};

/**
 * Sends a Slack notification.
 *
 * @param {Object} data - The data to be sent in the request body.
 * @returns {Promise} A promise that resolves to the response from the server.
 */
export const sendSlackNotification = (data) => {
  return http.post(`${AppConstants.saDevelopementAPIURL}client/company/slack-notification`, data);
};

// get DAV logs
export const getDAVLogs = (check_id, limit, offset) => {
  return http({
    method: 'get',
    url: `${AppConstants.saDevelopementAPIURL}dav/logs`,
    params: {
      check_id,
      limit,
      offset
    }
  });
};
