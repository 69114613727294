// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fQ5m_iAaQjUr5T5mQKbk {
  padding: 32px;
  border: 1px solid rgba(51, 51, 51, 0.1);
}
.fQ5m_iAaQjUr5T5mQKbk .bW6FdzZGpkP3LWp992De {
  font-weight: 600;
  font-size: 20px;
  color: #464457;
  margin-bottom: 32px;
}
.fQ5m_iAaQjUr5T5mQKbk .AguCFREvcHsatcBLe0BK {
  margin-bottom: 32px;
}
.fQ5m_iAaQjUr5T5mQKbk .RTKUzv5YEGMsuzTvMZsG {
  color: #999999;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 6px;
}
.fQ5m_iAaQjUr5T5mQKbk .EkE0yDStqBUgKhiMHdlb {
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}
.fQ5m_iAaQjUr5T5mQKbk .FaevizrnxF2QyU2BSKH9 {
  color: #666666;
  font-size: 14px;
  font-weight: 500;
}
.fQ5m_iAaQjUr5T5mQKbk .Km8s5ctkZ_rcxgSYtFMv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.fQ5m_iAaQjUr5T5mQKbk .sM7nU3zMceEqljQ4J1Aq {
  font-family: roboto;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #666666;
}
.fQ5m_iAaQjUr5T5mQKbk .d8L_qaTWAtmOQBQsvNy5 {
  font-size: 14px;
  line-height: 16px;
  color: #666666;
}
.fQ5m_iAaQjUr5T5mQKbk .J4LLoCpD6iafSzPgNWkB {
  border-top: 1px solid rgba(51, 51, 51, 0.1);
}
.fQ5m_iAaQjUr5T5mQKbk .OfXzpsAPfnuRvT1wQ7fw {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.fQ5m_iAaQjUr5T5mQKbk .OfXzpsAPfnuRvT1wQ7fw p {
  margin-bottom: 0;
}
.fQ5m_iAaQjUr5T5mQKbk .hzI0l6A9HZ9M22Yk_1G2 {
  color: #333333;
  font-size: 14px;
}
.fQ5m_iAaQjUr5T5mQKbk .zY1p81tQujqEq1iPuXf7 {
  font-family: roboto;
  color: #333333;
  font-size: 14px;
}
.fQ5m_iAaQjUr5T5mQKbk .eTnvfXVh2eu70RtBjDJR {
  font-family: roboto;
  font-size: 14px;
}
.fQ5m_iAaQjUr5T5mQKbk .bESb05MvY7dXkITnv7dC {
  color: #999999;
  font-size: 14px;
}
.fQ5m_iAaQjUr5T5mQKbk .lt6td8kqsuxUisVVqurg {
  font-family: roboto;
  color: #999999;
  font-size: 14px;
}
.fQ5m_iAaQjUr5T5mQKbk .pH1qqcbuRNL_MGiHOMLa {
  font-family: roboto;
}
.fQ5m_iAaQjUr5T5mQKbk .h5Yj5v6BCt6thRl0v2fZ button {
  width: 100%;
  border-radius: 2px;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fQ5m_iAaQjUr5T5mQKbk .h5Yj5v6BCt6thRl0v2fZ button .ptb7oCUIYKJ3amRXbSyN {
  font-family: roboto;
}
.fQ5m_iAaQjUr5T5mQKbk .h5Yj5v6BCt6thRl0v2fZ .bflP0vsk3IsV0rcpdHob {
  background: #f5f5f5;
  color: #bfbfbf;
  border: 1px solid #d9d9d9;
}
.fQ5m_iAaQjUr5T5mQKbk .h5Yj5v6BCt6thRl0v2fZ .GIsBsAdV9VRWfrTIRwVp {
  background: #2755fe;
}

.azahzYfpZdWZZH6fP27W {
  margin-top: 16px;
}
.azahzYfpZdWZZH6fP27W .FlmTTif8CCo5DVlX7OsC {
  display: flex;
  padding: 13px 17px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}
.azahzYfpZdWZZH6fP27W .FlmTTif8CCo5DVlX7OsC p {
  margin-bottom: 0;
}

.Pn6ZZUFj0tnDEPzXDbfT {
  font-family: Roboto;
}

.jyTK1378_YJwyX2Y0Fx1 {
  display: flex;
  flex-direction: column;
}

.OnrnODArYHttNEaE_Cp0 {
  font-size: 14px;
  line-height: 16px;
  color: #666666;
  margin-bottom: 10px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summaryContainer": `fQ5m_iAaQjUr5T5mQKbk`,
	"summaryHeadingText": `bW6FdzZGpkP3LWp992De`,
	"candidateDetailsSection": `AguCFREvcHsatcBLe0BK`,
	"heading": `RTKUzv5YEGMsuzTvMZsG`,
	"headingInfo": `EkE0yDStqBUgKhiMHdlb`,
	"headingDescription": `FaevizrnxF2QyU2BSKH9`,
	"summaryChecksContainer": `Km8s5ctkZ_rcxgSYtFMv`,
	"priceText": `sM7nU3zMceEqljQ4J1Aq`,
	"priceTextCheck": `d8L_qaTWAtmOQBQsvNy5`,
	"divider": `J4LLoCpD6iafSzPgNWkB`,
	"amountDetailsContainer": `OfXzpsAPfnuRvT1wQ7fw`,
	"creditBalanceText": `hzI0l6A9HZ9M22Yk_1G2`,
	"creditBalancePriceText": `zY1p81tQujqEq1iPuXf7`,
	"insuffBalancePriceText": `eTnvfXVh2eu70RtBjDJR`,
	"taxText": `bESb05MvY7dXkITnv7dC`,
	"taxPrice": `lt6td8kqsuxUisVVqurg`,
	"fontFamilyRoboto": `pH1qqcbuRNL_MGiHOMLa`,
	"addBtnContainer": `h5Yj5v6BCt6thRl0v2fZ`,
	"rupee": `ptb7oCUIYKJ3amRXbSyN`,
	"payDisableBtn": `bflP0vsk3IsV0rcpdHob`,
	"payBtn": `GIsBsAdV9VRWfrTIRwVp`,
	"infoWarningContainer": `azahzYfpZdWZZH6fP27W`,
	"alert": `FlmTTif8CCo5DVlX7OsC`,
	"robotoFont": `Pn6ZZUFj0tnDEPzXDbfT`,
	"socialMediaContainerStyles": `jyTK1378_YJwyX2Y0Fx1`,
	"socialPriceTextCheck": `OnrnODArYHttNEaE_Cp0`
};
export default ___CSS_LOADER_EXPORT___;
