import React from 'react';
import Table from 'react-bootstrap/Table';
import ContentLoader from 'react-content-loader';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { STATUSES } from '../../../../utils';
import styles from './RecentlyAdded.module.scss';
import Loader from '../../../../core-components/Loader';
import Image from '../../../../core-components/Image';
import { isEmpty, has } from 'lodash';
import { capitalizeName } from '../../frontend-common/utils/Utilities';

export default ({ data, loading, ...props }) => {
  const handleRowClicks = (rowData) => {
    if (!isEmpty(rowData) && has(rowData, 'candidateId') && rowData.candidateId) {
      const newTab = window.open(`/viewCandidate?candidateId=${rowData.candidateId}`, '_blank');

      if (newTab) {
        newTab.focus();
      }
    }
  };

  return (
    <Card className={styles.recentlyAddedCard}>
      <Link to='/candidates' className={styles.noTextDecoration}>
        <div className={styles.cardHeader}>
          <div className={styles.heading}>{'Recently Added Candidates'}</div>
          <div className={styles.viewAllLink}>
            <Link to='/candidates'>{'View all'}</Link>
          </div>
        </div>
      </Link>
      <Table className={styles.recentlyAddedTable}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Documents Submitted On</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data && !loading
            ? Object.values(data).map((rowData, index) => {
                return (
                  <tr key={index} onClick={() => handleRowClicks(rowData)}>
                    <td className={styles.name}>
                      <p>{capitalizeName(rowData.candidate.name)}</p>
                    </td>
                    <td>
                      {rowData.status !== 3
                        ? rowData.consentLetterUrl !== null && rowData.consentAddedAt !== null
                          ? moment(rowData.consentAddedAt, 'YYYY-MM-DDThh:mm:ss')
                              .add(5.5, 'hours')
                              .format('DD-MM-YYYY h:mm a')
                          : rowData.formFilled !== null
                          ? moment(rowData.formFilled, 'YYYY-MM-DDThh:mm:ss')
                              .add(5.5, 'hours')
                              .format('DD-MM-YYYY h:mm a')
                          : '-'
                        : '-'}
                    </td>
                    <td>
                      {rowData.status === -1
                        ? 'Insufficiency'
                        : STATUSES.values[rowData.status].value}
                    </td>
                  </tr>
                );
              })
            : Array.apply(null, { length: 10 })
                .map(Number.call, Number)
                .map((loadData, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <ContentLoader height={20} width={100}>
                          <rect x='0' y='0' width='100' height='14' />
                        </ContentLoader>
                      </td>
                      <td>
                        {index === 4 ? (
                          loading ? (
                            <Loader />
                          ) : (
                            <div className={styles.noDataWrapper}>
                              <Image name={'NoData.svg'} />
                              <div className={styles.noDataContentF}>{'No data found'}</div>
                            </div>
                          )
                        ) : (
                          <ContentLoader height={20} width={100}>
                            <rect x='0' y='0' width='100' height='14' />
                          </ContentLoader>
                        )}
                      </td>
                      <td>
                        <ContentLoader height={20} width={100}>
                          <rect x='0' y='0' width='100' height='14' />
                        </ContentLoader>
                      </td>
                    </tr>
                  );
                })}
        </tbody>
      </Table>
    </Card>
  );
};
