import * as Yup from 'yup';
import { getTrimmedValue } from '../../../../utils/utilities';
import {
  employeeIdValidationSchema,
  getEmailValidationSchema,
  getNameValidationSchema,
  getPhoneValidationSchema,
  getUANValidationSchema
} from '../../../../utils/ValidationSchema';

export default Yup.object().shape({
  name: getNameValidationSchema({ message: 'name.', isRequired: true }),
  email: getEmailValidationSchema({
    isRequired: false,
    isNullable: false,
    msg: 'Please enter a valid email address.'
  }).when('requiredFields', {
    is: (requiredFields) => {
      return requiredFields.includes('EMAIL');
    },
    then: getEmailValidationSchema({
      isRequired: true,
      isNullable: false,
      msg: 'Please enter a valid email address.'
    })
  }),
  alternateEmail: getEmailValidationSchema({
    isRequired: false,
    isNullable: true,
    msg: 'Please enter a valid email address.'
  }).when('requiredFields', {
    is: (requiredFields) => {
      return requiredFields.includes('ALTERNATE_EMAIL');
    },
    then: getEmailValidationSchema({
      isRequired: true,
      isNullable: false,
      msg: 'Please enter a valid alternate email address.'
    })
  }),
  phone: Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, false))
    .when('phoneNumberExists', {
      is: true,
      then: Yup.string().test({
        name: 'phone',
        message: 'Phone Number already exists.',
        test: (value) => {
          return false;
        }
      })
    })
    .when('requiredFields', {
      is: (requiredFields) => {
        return requiredFields.includes('PHONE');
      },
      then: getPhoneValidationSchema({
        isRequired: true,
        isNullable: false
      })
    }),
  altPhone: Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, false))
    .when('requiredFields', {
      is: (requiredFields) => {
        return requiredFields.includes('ALTERNATE_PHONE');
      },
      then: getPhoneValidationSchema({
        isRequired: true,
        isNullable: false
      })
    }),
  consentDoc: Yup.string().when('consent', {
    is: false,
    then: Yup.string().required('Consent cannot be blank.')
  }),
  resumeLink: Yup.string().when('requiredFields', {
    is: (requiredFields) => requiredFields.includes('RESUME'),
    then: Yup.string().required('Resume cannot be blank.')
  }),
  employeeId: Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, true))
    .when('requiredFields', {
      is: (requiredFields) => requiredFields.includes('EMPLOYEE_ID'),
      then: employeeIdValidationSchema({
        message: 'Employee ID is required.',
        isRequired: true,
        isNullable: false
      })
    })
    .when('employeeIdExists', {
      is: true,
      then: Yup.string('Employee Id already exists.'),
      otherwise: employeeIdValidationSchema({
        message: 'Employee ID already exists.',
        isRequired: false
      })
    }),
  uanNumber: Yup.string()
    .transform((currentVal) => getTrimmedValue(currentVal, true))
    .when('requiredFields', {
      is: (requiredFields) => requiredFields.includes('UAN'),
      then: getUANValidationSchema({ isRequired: true, isNullable: false }).concat(
        Yup.string().required('Please enter a valid UAN')
      ),
      otherwise: getUANValidationSchema({ isRequired: false, isNullable: true })
    }),
  doj: Yup.date()
    .transform((value, originalValue) => {
      const trimmedValue = getTrimmedValue(originalValue, false);
      return trimmedValue ? new Date(trimmedValue) : null;
    })
    .when('requiredFields', {
      is: (requiredFields) => requiredFields.includes('DATE_OF_JOINING'),
      then: (schema) =>
        schema
          .required('Date of joining is required.')
          .nullable()
          .typeError('Please enter a valid date.')
    }),
  tags: Yup.array()
    .of(Yup.object())
    .when('requiredFields', {
      is: (requiredFields) => requiredFields.includes('TAGS'),
      then: Yup.array().of(Yup.object()).min(1, 'Please select at least one tag.'),
      otherwise: Yup.array().of(Yup.object())
    }),
  category_id: Yup.string().when('requiredFields', {
    is: (requiredFields) => requiredFields.includes('CATEGORY'),
    then: Yup.string().required('Category is required.'),
    otherwise: Yup.string()
  }),
  hrInviteNote: Yup.string()
    .min(10, 'HR Invite Note must be at least 10 characters.')
    .max(160, 'HR Invite Note cannot be more than 160 characters.')
    .when('requiredFields', {
      is: (requiredFields) => requiredFields.includes('NOTE_TO_CANDIDATE'),
      then: Yup.string().required('HR Invite Note is required.')
    })
});
