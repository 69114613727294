import * as Yup from 'yup';
import {
  getFacebookUrlValidation,
  getInstagramUrlValidation,
  getLinkedinUrlValidation,
  getTwitterUrlValidation
} from '../../../../../utils/ValidationSchema';

export default Yup.object().shape({
  facebook_url: Yup.string().when(['social_media_config', 'is_facebook_url_not_available'], {
    is: (social_media_config, isFacebookNotAvailable) => {
      return social_media_config.includes('FACEBOOK') && isFacebookNotAvailable === false;
    },
    then: getFacebookUrlValidation({ isRequired: true, isNullable: false }).concat(
      Yup.string().required('Please enter a valid Facebook profile URL')
    ),
    otherwise: getFacebookUrlValidation({ isRequired: false, isNullable: true })
  }),
  twitter_url: Yup.string().when(['social_media_config', 'is_twitter_url_not_available'], {
    is: (social_media_config, isTwitterkNotAvailable) => {
      return social_media_config.includes('TWITTER') && isTwitterkNotAvailable === false;
    },
    then: getTwitterUrlValidation({ isRequired: true, isNullable: false }).concat(
      Yup.string().required('Please enter a valid X (Twitter) profile URL')
    ),
    otherwise: getTwitterUrlValidation({ isRequired: false, isNullable: true })
  }),
  linkedin_url: Yup.string().when(['social_media_config', 'is_linkedin_url_not_available'], {
    is: (social_media_config, isLinkedinNotAvailable) => {
      return social_media_config.includes('LINKEDIN') && isLinkedinNotAvailable === false;
    },
    then: getLinkedinUrlValidation({ isRequired: true, isNullable: false }).concat(
      Yup.string().required('Please enter a valid LinkedIn profile URL')
    ),
    otherwise: getLinkedinUrlValidation({ isRequired: false, isNullable: true })
  }),
  instagram_url: Yup.string().when(['social_media_config', 'is_instagram_url_not_available'], {
    is: (social_media_config, isInstagramNotAvailable) => {
      return social_media_config.includes('INSTAGRAM') && isInstagramNotAvailable === false;
    },
    then: getInstagramUrlValidation({ isRequired: true, isNullable: false }).concat(
      Yup.string().required('Please enter a valid Instagram profile URL')
    ),
    otherwise: getInstagramUrlValidation({ isRequired: false, isNullable: true })
  })
});
