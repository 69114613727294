import { filterObjectByKeyCaseInsensitive } from '../../../../../utils/utilities';
import { calculateDrugPanelPrices } from '../../helpers/drugPanelHelper';

export const getArraySum = (arr = []) => {
  let sum = arr.reduce((acc, item) => {
    return acc + item;
  }, 0);
  return sum;
};

export const getEduPrice = (values, config) => {
  let price = 0;
  if (values.education && values.education.method) {
    switch (values.education.method) {
      case 'OFFICIAL':
        price = config.addons.education?.price || 0;
        break;
      case 'HYBRID':
        price = config.addons.educationHybrid?.price || 0;
        break;
      case 'REGIONAL_PARTNER':
        price = config.addons.educationThirdParty?.price || 0;
        break;

      default:
        break;
    }
  }
  return price;
};

export const calculateTotalPrice = (values = {}, config = {}) => {
  let price = 0;
  const step3Values = Object.assign({}, values);

  if (step3Values.identity && (step3Values.identity.any || step3Values.identity.config.length)) {
    price += step3Values.identity.any ? config.addons.identity.price * step3Values.identity.any : 0;
    price +=
      step3Values.identity.config && step3Values.identity.config.length
        ? config.addons.identity.price * step3Values.identity.config.length
        : 0;
  }

  if (
    step3Values.address &&
    step3Values?.address?.config.length &&
    step3Values?.address?.config[0]?.verificationType
  ) {
    const allAddonPrice = getAddonPrice(
      step3Values?.address?.config[0]?.verificationType.replace(/_/g, ''),
      step3Values?.address?.config?.length,
      config.addons
    );
    price +=
      step3Values.address.config && step3Values.address.config.length
        ? Number.isNaN(allAddonPrice)
          ? 0
          : allAddonPrice
        : 0;
  }

  if (
    step3Values.court &&
    step3Values.court.config.length &&
    step3Values?.court?.config[0]?.verificationType
  ) {
    const allAddonPrice = getAddonPrice(
      step3Values?.court?.config[0]?.verificationType.replace(/_/g, ''),
      step3Values?.court?.config?.length,
      config.addons
    );
    price +=
      step3Values?.court.config && step3Values?.court?.config?.length
        ? Number.isNaN(allAddonPrice)
          ? 0
          : allAddonPrice
        : 0;
  }
  if (
    step3Values.educationOfficial &&
    (step3Values.educationOfficial.count || step3Values.educationOfficial.config.length)
  ) {
    price += step3Values.educationOfficial.count
      ? config.addons.education.price * step3Values.educationOfficial.count
      : 0;
    price += step3Values.educationOfficial.config.length
      ? config.addons.education.price * step3Values.educationOfficial.config.length
      : 0;
  }

  if (
    step3Values.educationHybrid &&
    (step3Values.educationHybrid.count || step3Values.educationHybrid.config.length)
  ) {
    price += step3Values.educationHybrid.count
      ? config.addons.educationHybrid.price * step3Values.educationHybrid.count
      : 0;
    price += step3Values.educationHybrid.config.length
      ? config.addons.educationHybrid.price * step3Values.educationHybrid.config.length
      : 0;
  }

  if (
    step3Values.educationRegionalPartner &&
    (step3Values.educationRegionalPartner.count ||
      step3Values.educationRegionalPartner.config.length)
  ) {
    price += step3Values.educationRegionalPartner.count
      ? config.addons.educationThirdParty.price * step3Values.educationRegionalPartner.count
      : 0;
    price += step3Values.educationRegionalPartner.config.length
      ? config.addons.educationThirdParty.price * step3Values.educationRegionalPartner.config.length
      : 0;
  }

  if (
    step3Values.education &&
    (step3Values.education.count ||
      (step3Values.education.config && step3Values.education.config.length))
  ) {
    price +=
      step3Values.education.count && step3Values.education.type !== 'SPECIFIC'
        ? getEduPrice(step3Values, config) * step3Values.education.count
        : 0;
    price += step3Values.education.config.length
      ? getEduPrice(step3Values, config) * step3Values.education.config.length
      : 0;
  }

  if (step3Values.employment) {
    price += config.addons.employment.price * step3Values.employment;
  }

  if (step3Values?.socialMedia?.config.length) {
    price += config.addons.socialMedia.price;
  }

  if (
    step3Values.reference &&
    step3Values.reference.config &&
    step3Values.reference.config.length
  ) {
    price += config.addons.reference.price * getArraySum(step3Values.reference.config);
  }

  if (step3Values.world) {
    price += config.addons.world.price;
  }

  if (step3Values.cibil) {
    price += config.addons.cibil.price;
  }

  const drugPanelPrice = calculateDrugPanelPrices(step3Values, config);
  price += drugPanelPrice;

  return Number.isNaN(price) ? 0 : price;
};

export const getAddonPrice = (type, units, addonDetails) => {
  // Get addon price based on verification type of addon
  const filteredObj = filterObjectByKeyCaseInsensitive(addonDetails, type, true);

  return Object.values(filteredObj)[0]?.price * units;
};
