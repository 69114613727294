import React, { useState } from 'react';
import ordinal from 'ordinal';
import { Accordion, Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { PLAIN_CHECK_ICONS } from '../../../../../../../utils/commonConstant';
import { maskPriceForAdmins } from '../../../../../frontend-common/CreditBuyPackagePage/UtilComponents/UtilitiesFunction';
import Image from '../../../../../../../core-components/Image';
import CustomCounter from '../../../../../../../core-components/CustomCounter';
import styles from './Reference.module.scss';

function Reference(props) {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const {
    values,
    onValueChange,
    heading,
    price,
    accessLevel,
    paymentModelType,
    packageReferenceCount
  } = props;
  const decoratedOnClick = (eventKey = 0) => {
    setActiveAccordion(activeAccordion === eventKey ? null : eventKey);
  };

  const handleConfigChange = (value, index) => {
    let updatedConfig = [...values.config];
    updatedConfig[index] = value;
    onValueChange('config', updatedConfig);
  };

  // Ensure we have exactly 4 employment reference slots
  if (!values.config || values.config.length !== 4) {
    onValueChange('config', new Array(4).fill(0));
  }

  // Calculate total reference addon count
  const totalReferenceAddonCount = values.config?.reduce((sum, count) => sum + count, 0) || 0;

  // Calculate if counter should be disabled
  const isCounterDisabled = packageReferenceCount + totalReferenceAddonCount > 9;

  return (
    <div className={styles.addonsContainer}>
      <Accordion>
        <Card className={styles.addonsContainerCard}>
          <Accordion.Toggle
            as={Card.Header}
            eventKey='0'
            className={styles.addonsContainerHeader}
            onClick={() => decoratedOnClick(0)}
          >
            <div>
              <span className={styles.iconContainer}>
                <FontAwesomeIcon icon={faPlusCircle} color='#389E0D' className={styles.plusIcon} />
                <FontAwesomeIcon
                  icon={PLAIN_CHECK_ICONS['reference']?.icon}
                  color='rgba(39, 85, 254, 0.5)'
                  style={{ fontSize: '18px' }}
                />
              </span>
            </div>
            <div className={styles.headingRightContainer}>
              <div>{heading}</div>
              <div className={styles.headingRight}>
                <span className={styles.startFrom}>
                  <span className={styles.rupee}>₹</span>
                  {maskPriceForAdmins(price, accessLevel, paymentModelType)}
                  {' per check'}
                </span>
                {activeAccordion === 0 ? (
                  <Image name='UpAngle.svg' svg='UpAngle.svg' />
                ) : (
                  <Image name='DownAngle.svg' svg='DownAngle.svg' />
                )}
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='0'>
            <Card.Body className={styles.cardBody}>
              <Row>
                {values.config?.map((item, index) => (
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    key={index}
                    className={styles.refCounterContainer}
                  >
                    <span className={styles.refNumber}>
                      {index === 0 ? 'Last employment' : `${ordinal(index + 1)} last employment`}
                    </span>
                    <CustomCounter
                      limit={50}
                      totalCount={0}
                      value={item}
                      index={index}
                      disabled={isCounterDisabled && item === 0} // Disable if total count reached and current value is 0
                      valueChange={(value, index) => {
                        // Only allow decreasing values when disabled
                        if (isCounterDisabled && value > item) return;
                        handleConfigChange(value, index);
                      }}
                    />
                  </Col>
                ))}
              </Row>
              {isCounterDisabled && (
                <div className={styles.limitMessage}>
                  Maximum total reference count (10) reached
                </div>
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

export default Reference;
