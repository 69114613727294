export default {
  identity: {
    any: 0,
    config: []
  },
  address: {
    any: 0,
    config: []
  },
  court: {
    any: 0,
    config: []
  },
  employment: 0,
  education: {
    count: 0,
    config: [],
    type: '',
    method: ''
  },
  educationOfficial: {
    count: 0,
    config: [],
    type: '',
    method: 'OFFICIAL'
  },
  educationHybrid: {
    count: 0,
    config: [],
    type: '',
    method: 'HYBRID'
  },
  educationRegionalPartner: {
    count: 0,
    config: [],
    type: '',
    method: 'REGIONAL_PARTNER'
  },
  educationType: '',
  reference: {
    count: 0,
    config: []
  },
  socialMedia: { count: 0, config: [] },
  world: false,
  cibil: false,
  drug5Panel: false,
  drug6Panel: false,
  drug7Panel: false,
  drug8Panel: false,
  drug9Panel: false,
  drug10Panel: false,
  drug11Panel: false,
  drug12Panel: false,
  hair5Panel: false
};
