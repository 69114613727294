import React, { useEffect, useState } from 'react';

import { Row, Col, Button } from 'react-bootstrap';

import Package from '../PackageListing/Package';
import Addons from './Addons';
import Customize from '../PackageListing/Customize';
import ConfirmationModal from '../../../../core-components/ConfirmationModal';
import Summary from './Summary';
import Step3Values from '../Step3IntialJson';
import styles from './SelectedPackage.module.scss';
import { isEmpty } from 'lodash';
import { getPackageType } from '../../../../utils/utilities';
import Loader from '../../../../core-components/Loader';

export default (props) => {
  const packageType = getPackageType() === 'PREPAID' ? 'PREPAID' : 'POSTPAID';
  const [loading, setLoading] = useState(true);
  const [formattedPackage, setFormattedPackage] = useState(null);
  const [openCustomise, setOpenCustomise] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [updatedValues, setUpdatedValues] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const {
    handleStepChange,
    createCandConfig,
    handleStepSubmit,
    savedValues,
    customizeValues,
    pDetails,
    initialValues,
    isBulkUpload,
    bulkCandidates
  } = props;

  useEffect(() => {
    getFormattedDataForRender();
  }, [customizeValues, pDetails]);

  const getFormattedDataForRender = () => {
    let formattedConfig = Object.assign({}, pDetails?.config);

    if (customizeValues) {
      if (customizeValues.identity) {
        let dummyIds = [...formattedConfig.identity];
        if (formattedConfig.identity.indexOf(`ANY_${customizeValues.identity.baseCount}`) >= 0) {
          dummyIds.splice(
            formattedConfig.identity.indexOf(`ANY_${customizeValues.identity.baseCount}`),
            1
          );
        }
        dummyIds = [...customizeValues.identity.config, ...dummyIds];
        if (customizeValues.identity.any) {
          dummyIds.push(`ANY_${customizeValues.identity.any}`);
        }
        formattedConfig.identity = dummyIds;
      }

      if (
        formattedConfig.address?.length === 1 &&
        formattedConfig.address.some((item) => item.type === 'ANY_1') &&
        customizeValues.address.value !== 'ANY_1'
      ) {
        formattedConfig.address = [
          { ...customizeValues.address.baseConfig[0], type: customizeValues.address.value }
        ];
      }

      if (
        formattedConfig.court?.length === 1 &&
        formattedConfig.court.some((item) => item.type === 'ANY_1') &&
        customizeValues.court.value !== 'ANY_1'
      ) {
        formattedConfig.court = [
          { ...customizeValues.court.baseConfig[0], type: customizeValues.court.value }
        ];
      }
      if (customizeValues.reference) {
        formattedConfig.reference = [...customizeValues.reference.config];
      }
    }
    setFormattedPackage({ ...pDetails, config: formattedConfig });
    setLoading(false);
  };

  const customiseSubmitStart = (values) => {
    setUpdatedValues(values);
    setOpenConfirm(true);
  };

  const handleCustomiseSubmit = () => {
    setOpenConfirm(false);
    handleStepSubmit(
      ['step2', 'step3'],
      [updatedValues, JSON.parse(JSON.stringify(Step3Values))],
      null
    );
    setOpenCustomise(false);
  };

  return (
    <>
      <div className={styles.selectedPackageContainer}>
        {showLoader ? (
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Loader />
              <div className='text-center'>
                <span>
                  Please wait while we confirm payment status..
                </span>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col lg={8} md={8} sm={8} xs={8}>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  {!loading && formattedPackage ? (
                    <Package
                      index={null}
                      pDetails={formattedPackage}
                      showBorder={false}
                      handleChoose={() => setOpenCustomise(true)}
                      isAadhaarAllowed={
                        formattedPackage.isAadhaarAllowed
                      }
                      isStep3={true}
                    />
                  ) : null}
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                  {!isEmpty(createCandConfig.addons) && (
                    <Addons
                      setFieldValue={(values) => {
                        props.handleStepSubmit(['step3'], [values]);
                      }}
                      packageType={packageType}
                      pDetails={formattedPackage}
                      values={props.savedValues}
                    />
                  )}

                  <Button
                    variant='outline-primary'
                    onClick={() => props.handleStepChange(2)}
                    className={styles.goButton}
                  >
                    {'Go back'}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col lg={4} md={4} sm={4} xs={4}>
              <Summary
                setShowLoader={setShowLoader}
                candidateConfig={createCandConfig || null}
                step1Values={props?.step1Values || null}
                step2Values={props?.pDetails || null}
                step3Values={props?.savedValues || null}
                packageType={packageType}
                setPaymentStatus={props?.setPaymentStatus}
                handleStepSubmit={props?.handleStepSubmit}
                isSubmitting={props?.isSubmitting}
                costPerCandidate={formattedPackage?.costPerCandidate}
                paymentDetails={props?.paymentDetails}
                insuffBalance={props?.createCandConfig?.insuffBalance}
                isBulkUpload={isBulkUpload}
                bulkCandidates={bulkCandidates}
                setFieldValue={(tdsValue) => {
                  props.handleStepSubmit(['step3'], { tdsValue });
                }}
              />
            </Col>
          </Row>
        )}
      </div>

      {openCustomise ? (
        <Customize
          show={openCustomise}
          pDetails={pDetails}
          onHide={() => {
            setOpenCustomise(false);
          }}
          savedValues={{ customise: customizeValues }}
          onSubmit={(values) => {
            customiseSubmitStart(values);
          }}
        />
      ) : null}

      {openConfirm ? (
        <ConfirmationModal
          show={openConfirm}
          onClose={() => {
            setOpenConfirm(false);
          }}
          heading={'Reset add-ons?'}
          subHeading={'Customizing package will reset any add-ons selected.'}
          sBtnText={'Reset add-ons'}
          enableBtn={true}
          onSubmit={() => {
            handleCustomiseSubmit();
          }}
        />
      ) : null}
    </>
  );
};
