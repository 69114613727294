import React, { useEffect, useState } from 'react';

import styles from '../Integrations.module.scss';
import { Container, Row } from 'react-bootstrap';

import TopTextArea from '../Utils/TopTextArea';
import IntegrationLayout from '../Utils/Layout';
import Button from '../../core-components/Button';
import WarningModal from '../WarningModal';
import PackageMappingIdentifier from '../Utils/PackageConfiguration';
import AppConstants from '../../../../../core-components/AppConstants';
import _, { isEmpty } from 'lodash';

import { toast } from 'react-toastify';
import { errToastMessage } from '../../utils/Utilities';
import { zohoDetails } from '../Utils/constants';
import {
  deleteIntegrations,
  getViewBuyPackages,
  getIntegrationFieldSubtypeMapping
} from '../../../../../api/company';

export default function ZohoRecruit(props) {
  const [integrationData, setIntegrationData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [triggerUseEffect, setTriggerUseEffect] = useState(false);
  const { setFieldValue, values, handleSubmit, isSubmitting } = props;
  const [isPackageMapped, setIsPackageMapped] = useState(false);
  const [identifierDataLoading, setIdentifierDataLoading] = useState(true);
  const [identifierValues, setIdentifierValues] = useState([]);
  const [packages, setPackages] = useState([]);
  const [reputeSubtypeMapping, setReputeSubtypeMapping] = useState([]);
  const [selectedIdentifierValues, setSelectedIdentifierValues] = useState({});
  const [dropdownIdentifierValues, setDropdownIdentifierValues] = useState([]);

  const backStepHandler = () => {
    props.history.push('/integrations');
  };

  const isFormValid = () => {
    return (
      packages.length > 0 &&
      !isEmpty(values.identifierValue) &&
      Object.values(values?.tags).filter((tag) => tag && !isEmpty(tag)).length === packages.length
    );
  };

  useEffect(() => {
    const integrationData = props?.integrationData;
    setIntegrationData(integrationData);

    // Fetching all packages
    getViewBuyPackages({ limit: 50, offset: 0 })
      .then((result) => {
        setPackages(result?.data?.data?.rows);
      })
      .catch((error) => {
        errToastMessage(error);
      });

    if (integrationData?.zohoRecruit?.accessToken) {
      setIsPackageMapped(false);
      let subtypeMapping = {};

      // Fetching Integration field subtype mapping
      getIntegrationFieldSubtypeMapping({
        companyId: props.values.companyId,
        integrationName: 'ZOHO_RECRUIT'
      })
        .then((res) => {
          if (res?.data.length > 0) {
            setIsPackageMapped(true);
          }
          res?.data.map((mapping) => {
            if (subtypeMapping.hasOwnProperty(mapping.subtype_id_fk)) {
              subtypeMapping[mapping.subtype_id_fk] = {
                packageId: mapping.package_id_fk,
                tags: [
                  ...subtypeMapping[mapping.subtype_id_fk].tags,
                  ...mapping.integration_field_value.split(';')
                ]
              };
            } else {
              subtypeMapping[mapping.subtype_id_fk] = {
                packageId: mapping.package_id_fk,
                tags: mapping.integration_field_value.split(';')
              };
            }
          });
          setReputeSubtypeMapping(subtypeMapping);
          setSelectedIdentifierValues(subtypeMapping);
        })
        .catch((err) => console.error(err));

      // Mapping Identifier values
      mapAPICallToIdentifier();
    }
  }, [props?.integrationData]);

  useEffect(() => {
    const selectedTagsObj = { ...selectedIdentifierValues };
    const allTagsArr = Object.values(selectedTagsObj).map((obj) => !isEmpty(obj) && obj.tags);
    setDropdownIdentifierValues(_.difference(identifierValues, _.union(...allTagsArr)));
  }, [selectedIdentifierValues, identifierValues]);

  const checkIfTagsNotAvailable = () => {
    const selectedIdentifierValuesObj = { ...selectedIdentifierValues };
    const allTagsArr = Object.values(selectedIdentifierValuesObj).map(
      (obj) => !isEmpty(obj) && obj?.tags
    );
    return _.difference(_.union(...allTagsArr), identifierValues).length > 0;
  };

  useEffect(() => {
    const integrationData = props?.integrationData;
    setIntegrationData(integrationData);
  }, [props?.integrationData]);

  useEffect(() => {
    props.setIntegrationAPI();
    props.getViewBuyPackages({ limit: 50, offset: 0 });
  }, []);

  const removeIntegrationsApi = () => {
    deleteIntegrations('ZOHO_RECRUIT')
      .then((resp) => {
        props.setIntegrationAPI();
        toast.success('Removed connection successfully.');
        setTriggerUseEffect(!triggerUseEffect);
        setIsOpen(false);
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };

  const onRemoveConnectionHandler = () => {
    removeIntegrationsApi();
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const message = urlParams.get('message');
    const success = urlParams.get('success');
    if (success) {
      toast.success(message);
    } else {
      errToastMessage(message);
    }
  }, []);

  const connectZohoRecruit = () => {
    const svToken = localStorage.getItem('token');
    const clientId =
      AppConstants.env === 'production'
        ? '1000.3PXN3UQPNT8FD0I1RE6RA5TG6900CW'
        : '1000.VKAU1MTDMFWMOUG0KEEV4LD00G17MC';
    // TODO: Update redirect url on Zoho Developer console since the same URL has to be used for both Zoho People and Zoho Recruit.
    // Need to update the same on partner conect lambda as well.
    const redirectUri =
      AppConstants.env === 'production'
        ? 'https://partner-connect.in.springverify.com/zoho'
        : 'https://dev-partner-connect.in.springverify.com/zoho';

    const url = `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoRecruit.modules.ALL&client_id=${clientId}&response_type=code&access_type=offline&redirect_uri=${redirectUri}&state=zoho-recruit;${svToken}`;
    window.location.href = url;
  };

  const setTagsValue = (packageId, subtypeId, value) => {
    let allSubtypesWithTags = { ...reputeSubtypeMapping, ...selectedIdentifierValues };

    allSubtypesWithTags[subtypeId] =
      value && value.length > 0 ? { packageId: packageId, tags: [...value] } : {};

    setSelectedIdentifierValues(allSubtypesWithTags);
    setFieldValue('tags', allSubtypesWithTags);
  };

  useEffect(() => {
    setFieldValue('tags', reputeSubtypeMapping);
  }, [reputeSubtypeMapping]);

  const preDefinedPackagesMapping = [
    'Upstart Verifier',
    'Master Verifier',
    'Elite Verifier',
    'Custom Verifier'
  ];

  const mapAPICallToIdentifier = async () => {
    setIdentifierDataLoading(true);
    const identifierFormattedData = [];
    setIdentifierDataLoading(false);
    preDefinedPackagesMapping.map((item) => identifierFormattedData.push(item));
    const uniqueIdentifierData = Array.from(new Set(identifierFormattedData));
    setIdentifierValues(uniqueIdentifierData);
  };

  const onClickIdentifierHandler = () => {
    mapAPICallToIdentifier();
  };

  const handleSetTagsValue = (packageId, subtypeId, value) => {
    setTagsValue(packageId, subtypeId, value);
  };

  const packageMappingComponent = {
    headerText: 'Setup Package Association',
    identifierValue: `${props.values?.identifierValue}`,
    integrationName: 'zohorecruit',
    onClickIdentifierHandler: onClickIdentifierHandler,
    identifierValues: identifierValues,
    dropdownIdentifierValues: dropdownIdentifierValues,
    values: values,
    handleSetTagsValue: handleSetTagsValue,
    packages: packages,
    identifierDataLoading: identifierDataLoading
  };

  return (
    <>
      <IntegrationLayout>
        <div className={styles.parentDivTopTextArea}>
          <TopTextArea
            title={'Zoho Recruit'}
            icon={'zoho.png'}
            showConnected={integrationData?.zohoRecruit?.accessToken}
          />
          {!integrationData?.zohoRecruit?.accessToken && (
            <Container>
              <div className='mt-5'>
                {zohoDetails.map((step, index) => {
                  return (
                    <Row>
                      <h6>{step}</h6>
                    </Row>
                  );
                })}
              </div>
            </Container>
          )}
          {integrationData?.zohoRecruit?.accessToken && (
            <PackageMappingIdentifier
              integrationName={'Zoho Recruit'}
              packageMappingComponent={packageMappingComponent}
            />
          )}
        </div>
        <Container>
          <div className={`${styles.parentDivInputArea} mb-5`}>
            <div className={styles.rowDisplay}>
              <Row>
                <div
                  className={
                    integrationData?.zohoRecruit?.accessToken
                      ? styles.step2FooterContainer
                      : styles.step1FooterContainer
                  }
                >
                  <Button className={styles.goBackKNITBtn} onClick={() => backStepHandler()}>
                    Go Back
                  </Button>
                  {!integrationData?.zohoRecruit?.accessToken ? (
                    <Button className={styles.connectButton} onClick={() => connectZohoRecruit()}>
                      Connect
                    </Button>
                  ) : (
                    <div>
                      <Button
                        className={styles.removeButtonRepute}
                        onClick={() => setIsOpen(true)}
                        variant='outline-danger'
                        disabled={!isPackageMapped && identifierDataLoading}
                      >
                        Disconnect
                      </Button>
                      <Button
                        className={styles.activeBtn}
                        onClick={handleSubmit}
                        disabled={!isFormValid() || checkIfTagsNotAvailable() || isSubmitting}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </Row>
              {isOpen ? (
                <WarningModal
                  show={isOpen}
                  onHide={() => {
                    setIsOpen(false);
                  }}
                  title={'Zoho Recruit'}
                  onConfirm={() => {
                    onRemoveConnectionHandler();
                  }}
                />
              ) : null}
            </div>
          </div>
        </Container>
      </IntegrationLayout>
    </>
  );
}
