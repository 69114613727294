import React, { useState, useRef, useEffect } from 'react';
import { Badge, Tooltip, OverlayTrigger } from 'react-bootstrap';

import Image from '../../../../../../../core-components/Image';

import styles from './CommonUploadComponent.module.scss';
import AppConstants from '../../../../../../../core-components/AppConstants';

//function to render ui when error come in document upload
const RenderErrorUI = (props) => {
  return (
    <div className={styles.errorClass}>
      <Image name='BgvErrorUploadDoc.png' />
      <div className={styles.errorClassContent}>
        <span className={styles.errorText}>{props.errorText}</span>
        <span
          className={styles.refreshIconContainer}
          onClick={() => props.handleClick(props.type, props.index)}
        >
          <Image data-testid='refreshIcon' name='BgvErrorRefresh.png' />
        </span>
      </div>
    </div>
  );
};

//function to render ui file are uploaded successfully
const RenderUploadedFile = (props) => {
  const [showBadge, setShowBadge] = useState(false);
  const wrapperRef = useRef(null);
  const token = new URLSearchParams(document.location.search).get('token');
  useOutsideAlerter(wrapperRef);

  const isImageLoading = props?.allItems?.some(item => item.loading);
  const handleDeleteFile = () => {
    props.handleDeleteImage(props.type, props.index);
    setShowBadge(false);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowBadge(false);
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div className={styles.certificateUploadedFileContainer}>
      {props.isPdf ? (
        <Image name='pdf_icon.png' />
      ) : (
        <Image
          src={`${AppConstants.developmentAPIURL}auth/bgv-docs?token=${token}&url=${props.item.link}`}
          alt='Unable to load image'
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      )}
      <div className={styles.deletePreviewImageContainer}>
        {props.isPdf ? (
          <span
            //onClick={() => props.handleImagePreview(props.item.link)}
            className={styles.actionIconContainer}
          >
            <a
              href={`${AppConstants.developmentAPIURL}auth/bgv-docs?token=${token}&url=${props.item.link}`}
              target='_blank'
            >
              <Image name='BgvIdView.png' />
            </a>
          </span>
        ) : (
          <span
            onClick={() => props.handleImagePreview(props.item.link)}
            className={styles.actionIconContainer}
            data-testid='viewIcon'
          >
            <Image name='BgvIdView.png' />
          </span>
        )}
        {showBadge ? (
          <Badge
            onClick={() => {
              handleDeleteFile();
            }}
            pill
            variant='danger'
            className={styles.deleteBadge}
            ref={wrapperRef}
          >
            Remove?
          </Badge>
        ) : (
          !isImageLoading &&
          <span onClick={() => setShowBadge(true)} className={styles.actionIconContainer}>
            <Image name='BgvIdDelete.png' />
          </span>
        )}
      </div>
      {props.item.password && (
        <OverlayTrigger
          key={`password_protected`}
          placement='top'
          overlay={
            <Tooltip id={`password_protected`}>
              <p>Password is saved</p>
            </Tooltip>
          }
        >
          <div className={styles.passwordProtectIcon}>
            <Image
              style={{
                marginRight: '5px',
                cursor: 'pointer',
                height: '24px',
                width: '24px'
              }}
              name='passwordSaved.png'
            />
          </div>
        </OverlayTrigger>
      )}
    </div>
  );
};

export { RenderErrorUI, RenderUploadedFile };
