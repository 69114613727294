// React and Other libraries imports
import React from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { Field } from 'formik';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

// Core components and utils import
import ValidatedFormInputField from '../../../../../../../core-components/ValidatedFormInputField';
import {
  capitalizeName,
  eduCapitalizeName,
  getTrimmedValue,
  handleApostrophe
} from '../../../../../../CompanyAdmin/frontend-common/utils/Utilities';

// Styles import
import styles from './AddEditModal.module.scss';

const currentYear = new Date().getFullYear();
const YEARS = Array.from({ length: 61 }, (_, i) => currentYear - 50 + i);

class AddEditModal extends React.Component {
  constructor(props) {
    super(props);
  }

  makeBtnEnable = () => {
    const {
      universityName,
      registrationNumber,
      startDate,
      endDate,
      educationLevel,
      degreeType,
      collegeName
    } = this.props.values;
    return universityName &&
      registrationNumber &&
      startDate &&
      endDate &&
      educationLevel &&
      degreeType &&
      collegeName
      ? true
      : false;
  };

  render() {
    const { handleSubmit, isSubmitting, values, errors, setFieldValue, touched, setFieldTouched } =
      this.props;
    const enableBtn =
      !isSubmitting &&
      isEmpty(errors) &&
      this.makeBtnEnable() &&
      moment(values.startDate) <= moment(values.endDate) &&
      moment(values.startDate) <= moment() &&
      values.degreeType &&
      values.educationLevel
        ? true
        : false;
    const educationLevel = [
      { name: 'Tenth', value: '10' },
      { name: 'Twelfth/Diploma', value: '12/Diploma' },
      { name: 'Graduation', value: 'Undergraduate' },
      { name: 'Post Graduation', value: 'Postgraduate' },
      { name: 'Phd', value: 'PHD' },
      { name: 'Other', value: 'Other' },
      { name: 'Medical Registration Certificate', value: 'MEDICAL REGISTRATION CERTIFICATE' }
    ];
    const degreeType = ['Full Time', 'Part Time', 'Distance'];

    const handleYearChange = (e, fieldname, changeYear, changeMonth) => {
      const SY = e.target.value; // Selected Year
      const CD = new Date(); // Current Date
      const CM = CD?.getMonth(); // Current Month

      let SYM = new Date(
        SY,
        values[fieldname] && values[fieldname] instanceof Date ? values[fieldname].getMonth() : CM
      ); // Selected Year and Month
      if (fieldname === 'startDate') {
        SYM = SYM <= CD ? SYM : new Date(SY, CM); // Setting default month is current month
      } else {
      const SD = values['startDate'] ? new Date(values['startDate']) : null; // Start Date
        if (SD) {
          SYM = SYM >= SD ? SYM : new Date(SY, SD?.getMonth());
        }
      }
      setFieldValue(fieldname, SYM);
      changeMonth(SYM?.getMonth());
      changeYear(SY);
    };
    return (
      <Modal
        size='lg'
        show={this.props.show}
        onHide={this.props.onHide}
        backdropClassName={'customBGVModalBackdrop'}
        dialogClassName='bgvModalDialog'
        className='bgvModal'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <div className={styles.educationModalTitle}>
              <span>{this.props.isEdit ? 'Edit Education' : 'Add Education'}</span>
            </div>
            <div className={styles.educationModalSubTitle}>
              <span>{`Step ${this.props.currentSubStep} of ${this.props.totalStep}`}</span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit} className='add-edit-education-form'>
          <Modal.Body className={styles.addEditEducationModalBody}>
            <Row>
              <Col sm={12} xs={12} md={6} lg={6}>
                <Form.Label>
                  Course Type <span>*</span>
                </Form.Label>
                <Form.Group className={styles.formGroup}>
                  <Select
                    menuPosition={'fixed'}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    options={educationLevel}
                    value={educationLevel.filter((e) => e?.value === values?.educationLevel) || ''}
                    getOptionLabel={(p) => p?.name}
                    getOptionValue={(p) => p?.value}
                    onChange={(value) => {
                      setFieldValue('educationLevel', value.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} xs={12} md={6} lg={6}>
                <Form.Label>
                  Attendance Type <span>*</span>
                </Form.Label>
                <Form.Group className={styles.formGroup}>
                  <Select
                    menuPosition={'fixed'}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    options={degreeType}
                    value={degreeType?.filter((d) => d === values?.degreeType) || ''}
                    getOptionLabel={(l) => l}
                    getOptionValue={(v) => v}
                    onChange={(value) => {
                      setFieldValue('degreeType', value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12} md={12} lg={12}>
                <Form.Label>
                  University/Board <span>*</span>
                </Form.Label>
                <Field
                  type='text'
                  name='universityName'
                  component={ValidatedFormInputField}
                  onBlur={(e) => {
                    this.props.handleBlur(e);
                    setFieldValue(
                      'universityName',
                      handleApostrophe(getTrimmedValue(values?.universityName, true))
                    );
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12} md={12} lg={12}>
                <Form.Label>
                  College/Institution/School <span>*</span>
                </Form.Label>
                <Field
                  type='text'
                  name='collegeName'
                  component={ValidatedFormInputField}
                  onBlur={(e) => {
                    this.props.handleBlur(e);
                    setFieldValue(
                      'collegeName',
                      handleApostrophe(getTrimmedValue(values?.collegeName, true))
                    );
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12} md={6} lg={6}>
                <Form.Label>
                  Registration Number <span>*</span>
                </Form.Label>
                <Field
                  type='text'
                  name='registrationNumber'
                  component={ValidatedFormInputField}
                  onBlur={(e) => {
                    this.props.handleBlur(e);
                    setFieldValue('registrationNumber', values?.registrationNumber);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12} md={12} lg={12}>
                <Form.Label>Degree</Form.Label>
                <Field
                  type='text'
                  name='degree'
                  component={ValidatedFormInputField}
                  placeholder='Eg: Bachelor of Technology, Master of Design'
                  disabled={
                    values.educationLevel === '10' || values.educationLevel === '12/Diploma'
                  }
                  onBlur={(e) => {
                    this.props.handleBlur(e);
                    setFieldValue('degree', handleApostrophe(getTrimmedValue(values?.degree, true)));
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12} md={12} lg={12}>
                <Form.Label>Specialization</Form.Label>
                <Field
                  type='text'
                  name='areasOfStudy'
                  component={ValidatedFormInputField}
                  placeholder='Eg: Electrical Engineering, Computer Science'
                  disabled={values.educationLevel === '10'}
                  onBlur={(e) => {
                    this.props.handleBlur(e);
                    setFieldValue(
                      'areasOfStudy',
                      handleApostrophe(getTrimmedValue(values?.areasOfStudy, true))
                    );
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col
                sm={12}
                xs={12}
                md={6}
                lg={6}
                className={
                  (!!errors.startDate && touched.startDate) ||
                  (values.startDate &&
                    values.endDate &&
                    moment(values.startDate) > moment(values.endDate))
                    ? 'empDatePicker'
                    : ''
                }
              >
                <Form.Label>
                  Start Date <span>*</span>
                </Form.Label>
                <Form.Group className={styles.formGroupDate}>
                  <DatePicker
                    placeholderText='From'
                    wrapperClassName={styles.datePickerWrapper}
                    className={styles.datePickerStyle}
                    dateFormat='MM/yyyy'
                    isClearable
                    popperPlacement='auto'
                    minViewMode='months'
                    showMonthYearPicker
                    selected={values.startDate ? moment(values.startDate).toDate() : null}
                    maxDate={new Date()}
                    renderCustomHeader={({ changeYear, changeMonth }) => (
                      <div className={styles.datePickerHeaderStyle}>
                        <select
                          value={
                            values.startDate
                              ? new Date(values.startDate).getFullYear()
                              : new Date().getFullYear()
                          }
                          onChange={(e) =>
                            handleYearChange(e, 'startDate', changeYear, changeMonth)
                          }
                        >
                          {YEARS.map((option) => {
                            const CD = new Date();
                            const CY = CD.getFullYear();
                            return (
                              <option key={option} value={option} disabled={option > CY}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    onChange={(date) => {
                      setFieldValue('startDate', date, true);
                    }}
                    onBlur={(date) => {
                      setFieldTouched('startDate', date, true);
                    }}
                    onChangeRaw={(date) => {
                      if (new Date(date.currentTarget.value) > new Date()) {
                        setFieldValue('startDate', new Date());
                      }
                    }}
                  />
                  {!!errors.startDate && touched.startDate && (
                    <div className={styles.errorMsg}>{errors.startDate}</div>
                  )}
                </Form.Group>
              </Col>
              <Col
                sm={12}
                xs={12}
                md={6}
                lg={6}
                className={
                  (!!errors.endDate && touched.endDate) ||
                  (values.startDate &&
                    values.endDate &&
                    moment(values.startDate) > moment(values.endDate))
                    ? 'empDatePicker'
                    : ''
                }
              >
                <Form.Label>
                  End Date (or Tentative Passing date) <span>*</span>
                </Form.Label>

                <Form.Group className={styles.formGroupDate}>
                  <DatePicker
                    key={'endDate'}
                    placeholderText='To (or tentative passing date)'
                    wrapperClassName={styles.datePickerWrapper}
                    className={styles.datePickerStyle}
                    dateFormat='MM/yyyy'
                    isClearable
                    popperPlacement='auto'
                    minViewMode='months'
                    showMonthYearPicker
                    selected={values.endDate ? moment(values.endDate).toDate() : null}
                    renderCustomHeader={({ changeYear, changeMonth }) => (
                      <div className={styles.datePickerHeaderStyle}>
                        <select
                          value={
                            values.endDate
                              ? new Date(values.endDate).getFullYear()
                              : new Date().getFullYear()
                          }
                          onChange={(e) => handleYearChange(e, 'endDate', changeYear, changeMonth)}
                        >
                          {YEARS.map((option) => {
                            const SD = new Date(values?.startDate) || null;
                            const SY = SD?.getFullYear() || null;
                            return (
                              <option key={option} value={option} disabled={SY && option < SY}>
                                {option}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    onChange={(date) => {
                      setFieldValue('endDate', date, true);
                    }}
                    onBlur={(date) => {
                      setFieldTouched('endDate', date, true);
                    }}
                  />

                  {!!errors.endDate && touched.endDate && (
                    <div className={styles.errorMsg}>{errors.endDate}</div>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer className={styles.educationSubmitBtn}>
            <Button
              className={'bgvModalSecondaryBtn'}
              onClick={(e) => {
                this.props.onHide();
              }}
            >
              Cancel
            </Button>
            <Button className={'bgvModalPrimaryBtn'} disabled={!enableBtn} type='submit'>
              Next
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default AddEditModal;
