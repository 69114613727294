import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  getBgvCandidateDetails,
  getBgvCache,
  saveBgvCache as saveBgvCacheAPI,
  invalidateBgvCandidateDetails,
  getBgvPackageConfig,
  invalidateBgvPackageConfig,
  invalidateBgvCache
} from '../../actions/bgv';

/* identity: 1
    address: 3
    edu: 5
    emp: 4 */

export const mapStateToProps = (state) => {
  return {
    config: !isEmpty(state.bgvPackageConfig.data)
      ? {
          ...state.bgvPackageConfig.data,
          loading: state.bgvPackageConfig.loading
        }
      : null,
    cached_data: !state.bgvCachedData.data
      ? null
      : { ...state.bgvCachedData.data, loading: state.bgvCachedData.loading },
    initial_candidate_details: !isEmpty(state.initialCandidateDetails.data)
      ? { ...state.initialCandidateDetails.data, loading: state.initialCandidateDetails.loading }
      : {
          error: state.initialCandidateDetails.error,
          loading: state.initialCandidateDetails.loading
        },
    referralData: state.bgvHrReferralData || {}
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getBgvCandidateDetails: () => dispatch(getBgvCandidateDetails()),
    getBgvCache: () => dispatch(getBgvCache()),
    getBgvPackageConfig: () => dispatch(getBgvPackageConfig()),
    setBgvCache: (data) => dispatch(saveBgvCacheAPI(data)),
    invalidateBgvCandidateDetails: () => dispatch(invalidateBgvCandidateDetails()),
    invalidateBgvPackageConfig: () => dispatch(invalidateBgvPackageConfig()),
    invalidateBgvCache: () => dispatch(invalidateBgvCache())
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps));
