import React, { useEffect, useState, memo } from 'react';
import ContentEditable from 'react-contenteditable';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { allowOnlyNumbersRegex } from '../../../../../../utils/ValidationSchema';

const Cell = memo(
  ({
     value: initialValue,
     row: { index },
     column: { id, dataType, options },
     dataDispatch,
     error
   }) => {
    const [value, setValue] = useState({ value: initialValue, update: false });
    const onChange = (e) => {
      let value = e.target.value;
      if (id === 'phone_number' || id === 'rm_phone') {
        value = e.target.value.replace(allowOnlyNumbersRegex, '');
      } else if (id === 'date_of_birth' || id === 'last_working_day' || id === 'date_of_joining') {
        value = e.target.value.replace(/[^0-9\-]/g, '');
      }
      setValue({ value, update: false });
    };

    useEffect(() => {
      setValue({ value: initialValue, update: false });
    }, [initialValue]);

    useEffect(() => {
      if (value.update) {
        dataDispatch({
          type: 'update_cell',
          columnId: id,
          rowIndex: index,
          value: value.value
        });
      }
    }, [value, dataDispatch, id, index]);

    const colorCodes = {
      error: {
        backgroundColor: '#FFCDD2',
        color: 'black',
        border: '1px solid red'
      },
      normal: {
        backgroundColor: 'white',
        color: 'black'
      }
    };

    const element = error ? (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip
            style={{
              color: '#ffffff',
              fontSize: '8px'
            }}
            id={'error'}
          >
            <div
              className="shadow-5 border-radius-md"
              style={{
                minWidth: 200,
                padding: '0.6rem'
              }}
            >
              <div className="d-flex flex-wrap-wrap">
                {error && error.charAt(0).toUpperCase() + error.slice(1)}
              </div>
            </div>
          </Tooltip>
        }
      >
        <div className="d-flex cursor-default align-items-center flex-1">
          <ContentEditable
            html={(value.value && value.value.toString()) || ''}
            onChange={onChange}
            onBlur={() =>
              setValue((old) => ({
                value: options?.includes('nospace')
                  ? old.value?.replaceAll(' ', '')
                  : old.value?.replaceAll(/\s+/g, ' ')?.trim(),
                update: true
              }))
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                setValue((old) => ({
                  value: options?.includes('nospace')
                    ? old.value?.replaceAll(' ', '')
                    : old.value?.replaceAll(/\s+/g, ' ')?.trim(),
                  update: true
                }));
              }
            }}
            onPaste={(e) => {
              e.preventDefault();
              const text = event.clipboardData.getData('text/plain');
              document.execCommand('insertText', false, text);
            }}
            style={error ? colorCodes.error : colorCodes.normal}
            className={`data-input ${dataType === 'text' ? '' : 'text-align-right'}`}
          />
        </div>
      </OverlayTrigger>
    ) : (
      <div className="d-flex cursor-default align-items-center flex-1">
        <ContentEditable
          html={(value.value && value.value.toString()) || ''}
          onChange={onChange}
          onBlur={() =>
            setValue((old) => ({
              value: options?.includes('nospace')
                ? old.value?.replaceAll(' ', '')
                : old.value?.replaceAll(/\s+/g, ' ')?.trim(),
              update: true
            }))
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              setValue((old) => ({
                value: options?.includes('nospace')
                  ? old.value?.replaceAll(' ', '')
                  : old.value?.replaceAll(/\s+/g, ' ')?.trim(),
                update: true
              }));
            }
          }}
          onPaste={(e) => {
            e.preventDefault();
            const text = event.clipboardData.getData('text/plain');
            document.execCommand('insertText', false, text);
          }}
          style={error ? colorCodes.error : colorCodes.normal}
          className={`data-input ${dataType === 'text' ? '' : 'text-align-right'}`}
        />
      </div>
    );

    return ['text', 'number'].includes(dataType) ? element : <span></span>;
  },
  (prevProps, nextProps) => {
    return prevProps.value === nextProps.value && prevProps.error === nextProps.error;
  }
);

export default Cell;
