import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isArray, toLower, isEmpty } from 'lodash';

import { editCandidateAPIFormValues } from './EditCandidate.mapper';
import validationSchema from './EditCandidate.validation';
import { errToastMessage, getIntlPhoneNumberObject } from '../../../utils/utilities';
import { capitalizeName } from '../frontend-common/utils/Utilities';
import {
  getCategories,
  INVALIDATE_CATEGORIES,
  getTags,
  INVALIDATE_TAGS,
  getCompanyServices,
  getCandidateFormConfig,
  editCandidate,
  deleteCandidate,
  getAddCandidateConfig as getAddCandidateConfigAPI
} from '../../../actions/company';
import { bgvUrl as bgvUrlAPI, editCandidate as editCandidateAPI } from '../../../api/company';

const mapStateToProps = (state) => {
  return {
    categories: state.categories,
    tags: state.tags,
    companyServices: state.companyServices,
    candidateInfo: state.candidateInfo,
    formConfig: state.formConfig,
    companyId: state.candidateInfo?.companyCandidateMapping?.companyId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invalidateCategories: () => dispatch({ type: INVALIDATE_CATEGORIES }),
    getCategories: () => dispatch(getCategories()),
    invalidateTags: () => dispatch({ type: INVALIDATE_TAGS }),
    getTags: () => dispatch(getTags()),
    getCompanyServices: () => dispatch(getCompanyServices()),
    bgvUrl: (id) => dispatch(bgvUrlAPI(id)),
    editCandidate: (data) => dispatch(editCandidate(data)),
    getCandidateFormConfig: () => dispatch(getCandidateFormConfig()),
    deleteCandidate: (id) => dispatch(deleteCandidate(id)),
    getCandidateConfig: (companyId) => dispatch(getAddCandidateConfigAPI(companyId))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {};
      if (props?.candidateDetails) {
        let category = props?.candidateDetails?.candidateUserCategoryMapping?.userCategoryId
          ? props?.candidateDetails?.candidateUserCategoryMapping?.userCategoryId
          : '';

        let tag =
          isArray(props?.candidateDetails?.candidateCompanyCategoryMappings) &&
          props?.candidateDetails?.candidateCompanyCategoryMappings?.length > 0
            ? props?.candidateDetails?.candidateCompanyCategoryMappings.map((value) => ({
              label: value.companyCategory.category,
              value: value.companyCategoryId
            }))
            : [];

        storedValues = {
          name: capitalizeName(props.candidateDetails.name),
          email: toLower(props.candidateDetails.email),
          employeeId: props.candidateDetails.companyCandidateMapping?.employeeId,
          uanNumber: props.candidateDetails.companyCandidateMapping?.uanNumber || '',
          gender: props.candidateDetails.gender || null,
          resume: !!props.candidateDetails.companyCandidateMapping?.resumeFormat,
          resumeLink: props.candidateDetails.companyCandidateMapping?.resumeFormat ? true : false,
          category,
          tag,
          type: 'candidate',
          altPhoneRef: props?.candidateDetails?.altPhoneRef || {},
          doj: props.candidateDetails?.doj
        };

        if (props.candidateDetails.companyCandidateMapping?.phoneNumber) {
          let phoneNumberObj = getIntlPhoneNumberObject(
            props.candidateDetails.companyCandidateMapping?.phoneNumber,
            props.candidateDetails.companyCandidateMapping?.countryCode
          );
          if (phoneNumberObj) {
            storedValues['phoneNumber'] = phoneNumberObj.nationalNumber;
            storedValues['countryCode'] = `+${phoneNumberObj.countryCallingCode}`;
          } else {
            storedValues['phoneNumber'] =
              props.candidateDetails.companyCandidateMapping?.phoneNumber;
            storedValues['countryCode'] =
              props.candidateDetails.companyCandidateMapping?.countryCode;
          }
        }
        if (props?.candidateDetails?.candidatesMetadata) {
          const altPhoneNumberMeta = props?.candidateDetails?.candidatesMetadata?.find(
            (p) => p.belongsTo === 'CANDIDATE' && p.type === 'PHONENUMBER'
          );
          if (altPhoneNumberMeta) {
            storedValues['altPhoneRef'] = altPhoneNumberMeta;
            let altPhoneNumberObj = getIntlPhoneNumberObject(altPhoneNumberMeta?.data);
            if (altPhoneNumberObj) {
              storedValues['altPhone'] = altPhoneNumberObj.nationalNumber;
              storedValues['altCountryCode'] = `+${altPhoneNumberObj.countryCallingCode}`;
            }
          }
        }
      }
      return mergeWith(
        {
          name: '',
          email: '',
          emailExists: false,
          phoneNumber: '',
          phoneNumberExists: false,
          isValidPhone: true,
          isAltValidPhone: true,
          altPhone: '',
          altCountryCode: '+91',
          countryCode: '+91',
          employeeId: '',
          employeeIdExists: false,
          gender: null,
          uanNumber: '',
          doj: '',
          resume: false,
          resumeLink: '',
          category: '',
          categoryName: '',
          tag: [],
          type: 'candidate',
          isEdit: false
        },
        storedValues,
        (a, b) => (b === null ? a : b)
      );
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
    handleSubmit: async (values, { setFieldError, setSubmitting, resetForm, props }) => {
      try {
        // Remove resumeLink if it is a boolean
        if (typeof values.resumeLink === 'boolean') {
          delete values.resumeLink;
        }

        // Check for various field errors
        const fieldErrors = [
          { condition: values.emailExists, field: 'email', message: 'Email already exists' },
          {
            condition: values.phoneNumberExists,
            field: 'phoneNumber',
            message: 'Phone number already exists'
          },
          {
            condition: values.employeeIdExists,
            field: 'employeeId',
            message: 'Employee Id already exists'
          },
          { condition: values.emailInvalid, field: 'email', message: 'Invalid Email' }
        ];

        for (const { condition, field, message } of fieldErrors) {
          if (condition) {
            setFieldError(field, message);
            setSubmitting(false);
            return;
          }
        }

        // Set default value for uanNumber
        values.uanNumber = values.uanNumber || '';

        // If editing, prepare the formData for the edit API call
        if (values.isEdit) {
          const { candidateId, id: companyCandidateMappingId } =
            props.candidateDetails.companyCandidateMapping;
          values.candidateId = candidateId;
          values.companyCandidateMappingId = companyCandidateMappingId;
          const formData = { ...values, phone_numbers: [] };
          // Add alternative phone number if provided
          if (values.altCountryCode && values.altPhone) {
            const altPhone = {
              ...values.altPhoneRef,
              data: values.altCountryCode + values.altPhone,
              belongs_to: 'CANDIDATE'
            };
            formData?.phone_numbers.push(altPhone);
          } else if (!isEmpty(values?.altPhoneRef) && !values.altPhone) {
            // Delete alternative phone number if removed existing one
            formData?.phone_numbers.push({ ...values?.altPhoneRef, is_deleted: true });
          } else {
            // Remove phone numbers object if not present alt phone
            delete formData?.phone_numbers;
          }
          // Call the edit API and handle the response

          await editCandidateAPI(editCandidateAPIFormValues(formData));
          values.isEdit = false;
          props.onModalHide('reloadCandidate');
        }
      } catch (error) {
        errToastMessage(error);
      } finally {
        setSubmitting(false);
        values.isEdit = false;
      }
    },
    displayName: 'Edit Candidate'
  })
);
