import React from 'react';
import cn from 'classnames';
import styles from '../AddBulkExEmp/AddBulkExEmp.module.scss';
import BulkUploader from '../BulkUploader/BulkUploader';

const AddBulkCandidate = (props) => {
  return (
    <>
      {/* <div className={styles.cardSection}>{getFormBasedOnStep(currentStep)}</div> */}
      <div className={cn(`${styles.alumniWrapperWrapper}`, props?.isSA ? styles.saContainer : '')}>
        <div className={styles.subHeading}>
          <h4 className={styles.header}>{'Candidates'}</h4>
        </div>
        <BulkUploader {...props} flow={'candidate'} />
      </div>
    </>
  );
};

AddBulkCandidate.defaultProps = {
  isCA: false, // to check whether component is imported in CA side or not
  isSA: false // to check whether component is imported in SA side or not
};

export default AddBulkCandidate;
