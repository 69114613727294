import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isEmpty } from 'lodash';
import validationSchema from './ReferAndEarn.validation';
import { baseContactForm, hrContactsResponseMapper } from '../ReferAndEarnUtils';
import { getReferrals, referHRContacts as referHRContactsAPI } from '../../../../actions/company';
import { toast } from 'react-toastify';

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    referHRContacts: (data) => dispatch(referHRContactsAPI(data)),
    getReferrals: (data) => dispatch(getReferrals(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let propsValue = {};
      let contactCount = 1;

      const storedValues = {
        contactCount: contactCount,
        additionalContact: new Array(parseInt(contactCount)).fill(baseContactForm)
      };
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema,
    handleSubmit: (
      values,
      { setFieldError, setStatus, setSubmitting, resetForm, setFieldValue, props }
    ) => {
      if (isEmpty(props.errors)) {
        const apiPayload = {
          contacts: hrContactsResponseMapper(values?.additionalContact)
        };
        referHRContactsAPI(apiPayload)
          .then(() => {
            toast.success(
              `${apiPayload.contacts.length === 1 ? 'Referral' : 'Referrals'} added successfully`
            );
            for (let i = 0; i < apiPayload.contacts.length; i++) {
              setFieldValue(`additionalContact.${i}.anonymous`, false);
              setFieldValue(`additionalContact.${i}.hrMobile`, '');
            }
            props.showReferredInfo(apiPayload.contacts.length);
            props?.getReferrals({
              limit: 10,
              offset: 0
            });
            resetForm();
          })
          .catch((err) => {
            setSubmitting(false);
          });
      }
    },
    displayName: 'Refer And Earn'
  })
);
