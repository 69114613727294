// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: Poppins, sans-serif;
}

.AGDU3yVjeXGuLPvtHatM {
  color: #000000;
  font-weight: 500;
  font-size: 24px;
}

.pmejiL_nfPo3Eh3c7WBL {
  color: #8c8c8c;
  font-size: 16px;
}

._JhwipuLkhMJYvyi8BQj {
  font-weight: 600;
  font-size: 16px;
}

.CyT6cMQOvMVnc1GOv2SE {
  font-size: 14px;
  color: #999999;
}

.FcTQrGnmltMGDZUSySvf {
  color: red;
}

.Bfsy5fgN1LiWcNVgRVz2 {
  color: #dc3545;
  font-size: 14px;
  margin-top: -10px;
}

.ltGJfPC1WlhXT6YNUV1I {
  margin-top: 20px;
}

.W5vcfa_bsGIXpXtRndC9 {
  width: 240px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border-radius: 2px;
  text-align: center;
  color: #2755fe;
}
@media screen and (max-width: 501px) {
  .W5vcfa_bsGIXpXtRndC9 {
    width: 100%;
  }
}

.E6jzd4bv6YQ8timF0BYc {
  width: 211px;
  height: 117px;
  background: #f5f7ff;
  border: 1px dashed #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  margin-top: 10px;
}
@media screen and (max-width: 601px) {
  .E6jzd4bv6YQ8timF0BYc {
    margin-top: 15px;
  }
}
.E6jzd4bv6YQ8timF0BYc .xFAFKsqYxudatcLo6mSw .AHXfdM1wLCEsjpoD86pN {
  color: white;
}
.E6jzd4bv6YQ8timF0BYc .xFAFKsqYxudatcLo6mSw ._4zMjVcLeLkbqWULc4ru {
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin-top: -15px;
  margin-left: 170px;
  text-align: center;
  border-radius: 50%;
  background: white;
}
.E6jzd4bv6YQ8timF0BYc .MfJI0ODjYDpNyd32_WTV {
  text-align: center;
  margin-top: 30px;
}
.E6jzd4bv6YQ8timF0BYc .MfJI0ODjYDpNyd32_WTV .AHXfdM1wLCEsjpoD86pN {
  color: white;
}
.E6jzd4bv6YQ8timF0BYc .MfJI0ODjYDpNyd32_WTV ._4zMjVcLeLkbqWULc4ru {
  cursor: pointer;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-left: 90px;
  background: white;
}

.HwAxXtIVrcQOpYTP9bEd {
  display: flex;
  position: relative;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.HwAxXtIVrcQOpYTP9bEd .pvpp2R8p0dEv7qfc8cFT {
  position: absolute;
  top: 0;
  right: 5px;
  cursor: pointer;
}
.HwAxXtIVrcQOpYTP9bEd span {
  margin: 5px;
}
.HwAxXtIVrcQOpYTP9bEd img {
  height: 100%;
  width: 100%;
}
.HwAxXtIVrcQOpYTP9bEd ._mJavxGlITbiTasuwFuc {
  font-size: 20px;
  font-weight: 500;
  color: #faad14;
}
.HwAxXtIVrcQOpYTP9bEd .wV3RCPzOKacK6s8HfqDX {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
}
.HwAxXtIVrcQOpYTP9bEd .wV3RCPzOKacK6s8HfqDX .dEEfxZgxVFrbpeF8W1oy {
  background: transparent;
  color: #2755fe;
  font-style: normal;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  margin: 0px 10px 5px 10px;
}
.HwAxXtIVrcQOpYTP9bEd .wV3RCPzOKacK6s8HfqDX .WdiB9Gkz9_AcNwSSS8ER {
  background: #2755fe;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin: 0px 10px 5px 10px;
}

.jaLeX5odCAFdp4Cl2wgb {
  position: absolute;
  display: flex;
}
.jaLeX5odCAFdp4Cl2wgb .IB29XQsJLc8BlNcP8JDH {
  margin-left: 3px;
  background: #ffffff;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.LqFq27p6CuOnpkj0SjPH {
  position: absolute;
  bottom: 8px;
  right: 40px;
}
.LqFq27p6CuOnpkj0SjPH .IB29XQsJLc8BlNcP8JDH {
  margin-right: 3px;
  background: #ffffff;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.rBBzI4D_X9Jmd4srna_4 {
  margin-top: 20px;
  padding: 0 !important;
  justify-content: flex-end !important;
}
@media screen and (max-width: 601px) {
  .rBBzI4D_X9Jmd4srna_4 {
    position: fixed;
    left: 0;
    margin: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
    padding: 12px 16px;
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 12px 16px !important;
    display: flex;
    align-items: center;
  }
}

.TPKREl4VsWpHQHct6Adh {
  min-height: 500px;
  padding-bottom: 65px !important;
}
@media screen and (max-width: 601px) {
  .TPKREl4VsWpHQHct6Adh {
    padding-bottom: 65px !important;
  }
}

.xupRjBGlbpNEZlULQNcI {
  margin-bottom: 20px;
}

.BTaLs8vVBeaqXIwYYWOD {
  background-color: #f8f9ff;
  border: 1px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
}

.qe7kJqMO5bEQPWHy1mWv {
  color: #000;
  font-size: 14px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.qe7kJqMO5bEQPWHy1mWv span {
  font-weight: 500;
  font-size: 12px;
}

.ok0LAq7A0tAvgacy_s7i {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.at5f07gu84b41ZLcH6ul {
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.rt3fDneNBualEJBAsGiA {
  width: 6px;
  height: 6px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 4px;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"step3Title": `AGDU3yVjeXGuLPvtHatM`,
	"step3SubTitle": `pmejiL_nfPo3Eh3c7WBL`,
	"warningHeading": `_JhwipuLkhMJYvyi8BQj`,
	"warningSubHeading": `CyT6cMQOvMVnc1GOv2SE`,
	"error": `FcTQrGnmltMGDZUSySvf`,
	"otherError": `Bfsy5fgN1LiWcNVgRVz2`,
	"Row": `ltGJfPC1WlhXT6YNUV1I`,
	"addBtn": `W5vcfa_bsGIXpXtRndC9`,
	"idPlaceHolder": `E6jzd4bv6YQ8timF0BYc`,
	"idMaxError": `xFAFKsqYxudatcLo6mSw`,
	"errorTag": `AHXfdM1wLCEsjpoD86pN`,
	"errorRefresh": `_4zMjVcLeLkbqWULc4ru`,
	"idUploadText": `MfJI0ODjYDpNyd32_WTV`,
	"bgvAddressDocumentWarning": `HwAxXtIVrcQOpYTP9bEd`,
	"warningModalCloseButton": `pvpp2R8p0dEv7qfc8cFT`,
	"modalWarningHeader": `_mJavxGlITbiTasuwFuc`,
	"modalWarningButtonContain": `wV3RCPzOKacK6s8HfqDX`,
	"ignoreSaveButton": `dEEfxZgxVFrbpeF8W1oy`,
	"modalWarningButton": `WdiB9Gkz9_AcNwSSS8ER`,
	"uploadFunctionality": `jaLeX5odCAFdp4Cl2wgb`,
	"functionBtn": `IB29XQsJLc8BlNcP8JDH`,
	"passwordProtectIcon": `LqFq27p6CuOnpkj0SjPH`,
	"modalFooter": `rBBzI4D_X9Jmd4srna_4`,
	"modalBody": `TPKREl4VsWpHQHct6Adh`,
	"documentContainer": `xupRjBGlbpNEZlULQNcI`,
	"documentBox": `BTaLs8vVBeaqXIwYYWOD`,
	"documentHeader": `qe7kJqMO5bEQPWHy1mWv`,
	"listContainer": `ok0LAq7A0tAvgacy_s7i`,
	"documentItem": `at5f07gu84b41ZLcH6ul`,
	"documentDot": `rt3fDneNBualEJBAsGiA`
};
export default ___CSS_LOADER_EXPORT___;
