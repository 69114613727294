import React, { useState } from 'react';
import {
  Card,
  Breadcrumb,
  Container,
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { Field } from 'formik';
import styles from '../AddCredits.module.scss';
import { Notes } from '../index';
import RazorpayButton from '../../../../../../../core-components/Razorpay/index';
import ValidatedRadioFields from '../../../../../../../core-components/ValidatedRadioFields';
import {
  purchaseCredits,
  purchaseCreditsSuccess,
  purchaseCreditsFailure
} from '../../../../../../../api/company';
import { loggly_payment_type, TDS_TOOLTIP } from '../../../../../../../utils/commonConstant';
import {
  calculateTDS,
  getTdsOptions,
  calculateTax,
  convertToIndianNumeration,
  hasDot
} from '../../../../../../../utils/utilities';
import ConfirmationModal from '../../../../../../../core-components/ConfirmationModalTds';
import Image from '../../../../../../../core-components/Image';
import { parseInt } from 'lodash';
import Loader from '../../../../../../../core-components/Loader';
export default function ReviewOrder(props) {
  const { values } = props;

  const [showModal, setShowModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const onPaymentSuccess = () => {
    props.setAddedBalance(props.amount);
  };
  const onPaymentFailure = (data) => {
    purchaseCreditsFailure(data);
  };
  const getTax = () => {
    return props?.paymentDetails?.tax;
  };

  /** calculating TDS for company based on TDS % selected
   *
   *   if NONE is slected then TDS amount = 0
   *   else calculate TDS amount based on selection (2%,10%)
   */

  const calculateTds = () => {
    let tdsCost = calculateTDS(
      props.amount,
      values?.companyTds === 'NONE' ? 0 : parseInt(values?.companyTds)
    );
    return (
      <div className={styles.tax}>
        <span className={styles.rupee}>-&#8377;</span>

        {convertToIndianNumeration(tdsCost)}
        {!hasDot(tdsCost) ? '.00' : ''}
      </div>
    );
  };

  const onClickHandler = (e) => {
    setShowModal(!showModal);
  };
  return (
    <Container className={styles.container}>
      <Card className={styles.card}>
        <Breadcrumb className={styles.breadcrumb}>
          <Breadcrumb.Item
            className={styles.breadcrumb_item}
            onClick={() => props.setCurrentStep(1)}
          >
            Credits
          </Breadcrumb.Item>
          <Breadcrumb.Item
            className={styles.breadcrumb_item}
            onClick={() => props.setCurrentStep(2)}
          >
            Add credits
          </Breadcrumb.Item>
          <Breadcrumb.Item className={styles.breadcrumb_item} active>
            Review order
          </Breadcrumb.Item>
        </Breadcrumb>
        {showLoader ? (
          <Loader />
        ) : (
          <Card.Body className={styles.body}>
            <Card.Title className={styles.title}>Review order</Card.Title>
            <Card.Text className={styles.summary}>Summary</Card.Text>
            <Row className={styles.row}>
              <Col>Credits</Col>
              <Col className='text-right'>
                <span className={styles.rupee}>&#8377;</span>
                {convertToIndianNumeration(parseInt(props?.amount)) + '.00'}
              </Col>
            </Row>
            <Row className={styles.tax}>
              <Col>Tax ({props.paymentDetails.tax}%)</Col>
              <Col className='text-right'>
                <span className={styles.rupee}>&#8377;</span>
                {convertToIndianNumeration(calculateTax(props.amount, getTax()))}
                {!hasDot(calculateTax(props.amount, getTax())) ? '.00' : ''}
              </Col>
            </Row>
            {/* //render TDS options only if 2% or 10% is selected from SA side for that company not for
          NONE selection */}
            {values?.tdsFromSAConfig != null &&
              values?.tdsFromSAConfig != '' &&
              values?.tdsFromSAConfig !== 'NONE' && (
                <Row className={styles.row}>
                  <Col>
                    <div>
                      <span>
                        Apply TDS?
                        <OverlayTrigger
                          placement='bottom'
                          overlay={
                            <Tooltip id={'tdsTooltip'}>
                              <p>{TDS_TOOLTIP}</p>
                            </Tooltip>
                          }
                        >
                          <Image name='InfoIcon.svg' style={{ marginLeft: '5px' }} />
                        </OverlayTrigger>
                      </span>
                      <div className={styles.tdsOptions}>
                        <Form>
                          <Field
                            name='companyTds'
                            component={ValidatedRadioFields}
                            options={getTdsOptions(values?.tdsFromSAConfig)}
                          />
                        </Form>
                      </div>
                    </div>
                  </Col>
                  <Col className='text-right'>{calculateTds(props?.amount)}</Col>
                </Row>
              )}
            <Row className={styles.total}>
              <Col>TOTAL</Col>
              <Col className='text-right'>
                <span className={styles.rupee}>&#8377;</span>
                {convertToIndianNumeration(
                  parseFloat(props.amount) +
                  parseFloat(calculateTax(props.amount, getTax())) -
                  parseFloat(
                    calculateTDS(
                      props.amount,
                      values?.companyTds === 'NONE' ? 0 : values?.companyTds
                    ).toFixed(2)
                  )
                )}
                {!hasDot(
                  parseFloat(props.amount) +
                  parseFloat(calculateTax(props.amount, getTax())) -
                  parseFloat(
                    calculateTDS(
                      props.amount,
                      values?.companyTds === 'NONE' ? 0 : values?.companyTds
                    ).toFixed(2)
                  )
                )
                  ? '.00'
                  : ''}
              </Col>
            </Row>
            {/* No tds for NONE option selected by user*/}
            {values?.companyTds === 'NONE' ? (
              <RazorpayButton
                setShowLoader={setShowLoader}
                createOrder={purchaseCredits}
                createOrderPayload={{
                  amount: props.amount,
                  tds_percentage: 0 // for NONE case
                }}
                payableAmount={props.amount}
                buttonText={'Proceed to checkout'}
                setTransactionId={props?.setTransactionId}
                setPaymentStatus={props.setPaymentStatus}
                setOrderId={props.setOrderId}
                onPaymentSuccess={onPaymentSuccess}
                type={loggly_payment_type.CREDITS}
              />
            ) : (
              <div className={styles.addBtnContainer}>
                <Button variant={'primary'} className={styles.payBtn} onClick={onClickHandler}>
                  &nbsp;
                  <span>Proceed to checkout</span>
                </Button>
              </div>
            )}
          </Card.Body>
        )}
      </Card>
      <Notes />

      {showModal && (
        <ConfirmationModal
          setShowLoader={setShowLoader}
          setTransactionId={props?.setTransactionId}
          show={showModal}
          buttonLabel={'Proceed to checkout'}
          onClose={onClickHandler}
          paymentsObj={{
            createOrder: purchaseCredits,
            createOrderPayload: {
              amount: props.amount,
              tds_percentage: parseInt(values?.companyTds)
            },
            payableAmount: props.amount,
            buttonText: 'Proceed to checkout',
            onSuccess: purchaseCreditsSuccess,
            onFailure: onPaymentFailure,
            setPaymentStatus: props.setPaymentStatus,
            setOrderId: props.setOrderId,
            onPaymentSuccess: onPaymentSuccess,
            type: loggly_payment_type.CREDITS
          }}
        />
      )}
    </Container>
  );
}
