import { connect } from 'react-redux';

import {
  getCandidates,
  invalidateCandidates,
  getCategories,
  getCompanyPaymentDetails,
  INVALIDATE_CATEGORIES,
  getTags,
  INVALIDATE_TAGS
} from '../../../actions/company';

const mapStateToProps = (state) => {
  return {
    candidates: state.candidates,
    categories: state.categories,
    tags: state.tags,
    paymentDetails: state.paymentDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invalidateCategories: () => dispatch({ type: INVALIDATE_CATEGORIES }),
    getCategories: () => dispatch(getCategories()),
    invalidateTags: () => dispatch({ type: INVALIDATE_TAGS }),
    getTags: () => dispatch(getTags()),
    getPaymentDetails: () => dispatch(getCompanyPaymentDetails()),
    invalidateCandidates: () => dispatch(invalidateCandidates()),
    getCandidates: (
      limit,
      offset,
      status,
      search,
      tag,
      userCategory,
      days,
      dateRange,
      showAllCandidate
    ) =>
      dispatch(
        getCandidates(
          limit,
          offset,
          false,
          status,
          search,
          tag,
          userCategory,
          days,
          dateRange,
          !showAllCandidate // Negating this because initial we have to show bounced email only
        )
      )
  };
};

export { mapDispatchToProps, mapStateToProps };
export default connect(mapStateToProps, mapDispatchToProps);
