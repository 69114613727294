import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isEmpty, has } from 'lodash';

import validationSchema from './AddressStep3.validation';
import logger from '../../../../../../../core-components/Logger';
import { toCamelCase, toSnakeCase } from '../../../../../../../utils/utilities';
import {
  ADDRESS_DOC_TYPE,
  MAP_OLD_ADDRESS_DOC_TYPE
} from '../../../../../../../utils/commonConstant';

import {
  idUpload as bgvIdUploadAPI,
  validateDocument,
  saveBgvCache as saveBgvCacheAPI,
  setAddressDocType as addressDocTypeAPI,
  setAddressFrontDoc as setAddressFrontDocAPI,
  setAddressBackDoc as setAddressBackDocAPI,
  setAddressSameDoc as setAddressSameDocAPI,
  deleteGradesheetUpload as deleteGradesheetUploadAPI,
  educationGradesheetUpload as educationGradesheetUploadAPI
} from '../../../../../../../actions/bgv';

const mapStateToProps = (state) => {
  return {
    cachedData: !state.bgvCachedData.data ? null : toCamelCase({ ...state.bgvCachedData.data }),
    uploadedID: state.idDetails.data || state?.idDetails?.url,
    validatedDocumentDetails: state.validatedDocumentDetails,
    addressDocType: state.addressDocType,
    addressDocFront: state.addressDocFront,
    addressDocBack: state.addressDocBack,
    addressDocSame: state.addressDocSame
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBgvCache: (data) => dispatch(saveBgvCacheAPI(data)),
    bgvIdUpload: (data) => dispatch(bgvIdUploadAPI(data)),
    validateDocument: (data) => dispatch(validateDocument(data)),
    setAddressDocType: (data) => dispatch(addressDocTypeAPI(data)),
    setAddressFrontDoc: (data) => dispatch(setAddressFrontDocAPI(data)),
    setAddressBackDoc: (data) => dispatch(setAddressBackDocAPI(data)),
    setAddressSameDoc: (data) => dispatch(setAddressSameDocAPI(data)),
    deleteGradesheetUpload: (data) => dispatch(deleteGradesheetUploadAPI(data)),
    educationGradesheetUpload: (data) => dispatch(educationGradesheetUploadAPI(data))
  };
};
let propsValue = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      if (
        has(props.cachedData.cacheData, 'addressCheck') &&
        !isEmpty(props.cachedData.cacheData.addressCheck) &&
        props.cachedData.cacheData.addressCheck.length > props.index &&
        !isEmpty(props.cachedData.cacheData.addressCheck[props.index]) &&
        has(props.cachedData.cacheData.addressCheck[props.index], 'step3')
      ) {
        propsValue = props.cachedData.cacheData.addressCheck[props.index]['step3'];
      } else {
        propsValue = {};
      }

      const getNewDocType = (type) => {
        const oldDocTypes = Object.keys(MAP_OLD_ADDRESS_DOC_TYPE);
        if (oldDocTypes.includes(type)) {
          return ADDRESS_DOC_TYPE.find((obj) => obj.label === MAP_OLD_ADDRESS_DOC_TYPE[type])
            ?.value;
        }
        return type;
      };
      let storedValues = {
        docType: !isEmpty(propsValue) ? propsValue.docType : '',
        proofDoc: !isEmpty(propsValue) ? propsValue.proofDoc : [],
        otherDetails: !isEmpty(propsValue) ? propsValue.otherDetails : '',
        frontDocUrl: !isEmpty(propsValue) ? propsValue.frontDocUrl : '',
        backDocUrl: !isEmpty(propsValue) ? propsValue.backDocUrl : '',
        frontPdf: !isEmpty(propsValue) ? propsValue.frontPdf : false,
        frontDocPassword: '',
        backDocPassword: '',
        backPdf: !isEmpty(propsValue) ? propsValue.backpdf : false,
        singleDoc: !isEmpty(propsValue) ? propsValue.singleDoc : false,
        addressMatchPercentageFront: !isEmpty(propsValue)
          ? propsValue.addressMatchPercentageFront
          : false,
        addressMatchPercentageBack: !isEmpty(propsValue)
          ? propsValue.addressMatchPercentageBack
          : false,
        isAddressChanged:
          props?.cachedData?.cacheData?.addressCheck?.[props.index]?.['step1']?.isAddressChanged ??
          false
      };
      if (propsValue?.docType && !isEmpty(propsValue?.docType)) {
        propsValue.docType = getNewDocType(propsValue.docType);
      }
      if (!propsValue?.otherDetails && isEmpty(propsValue?.otherDetails)) {
        propsValue.otherDetails = propsValue.otherDoc || '';
      }
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setStatus, setFieldError, setSubmitting, resetForm, props }) => {
      let valuesCopy = Object.assign({}, values);

      const addressCheck = [...props.cachedData.cacheData.addressCheck];

      addressCheck[props.index]['step3'] = {
        ...valuesCopy
      };
      addressCheck[props.index]['currentStep'] = 1;

      const saveObject = toSnakeCase({
        ...props.cachedData,
        cacheData: {
          ...props.cachedData.cacheData,
          addressCheck: [...addressCheck]
        },
        current_saving_index: '3',
        currentState: '3#1'
      });
      const finalCacheObject = {
        saveObject
      };

      props.setBgvCache(finalCacheObject).then(() => {
        logger.push({
          message: `${props.addressType} Address step3  ${
            props.title === 'Edit' ? 'Edited' : 'Saved'
          }`,
          data: JSON.stringify(finalCacheObject),
          category: `candidate-id-${props.candidateId}`,
          subCategory: `${props.addressType} Address #3`,
          type: 'info'
        });
        props.onHide();
        props.refresh();

        setSubmitting(false);
      });
    },
    displayName: 'addressStep3'
  })
);
