// React and other libraries imports
import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import axios from 'axios';
import moment from 'moment';
import { has } from 'lodash';
import Dropdown from 'react-bootstrap/Dropdown';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';

// Common components, constants and utils import
import NoRecords from '../NoRecords';
import Image from '../../../../core-components/Image';
import AppConstants from '../../../../core-components/AppConstants';
import { CHECK_STATUSES } from '../../../../utils';
import localStorage from '../../../../api/localStorage';
import {
  ADDRESS_TYPE_MAPPING,
  ADDRESS_VERIFICATION_TYPE_MAPPING,
  CUSTOMIZATION_TYPES_STATUS_CODES_MAPPING
} from '../../../../utils/commonConstant';

import {
  getCityValue as removeAsterisks,
  getStatus,
  removeAsterisksFromString,
  tatDataFormatted,
  mapCustomizationTypes,
  getCustomMapping
} from '../../../../utils/utilities';
import Icon from '../../frontend-common/core-components/Icon';
import HoldCheckModal from '../HoldCheckModal';
import CloseCheckModal from '../CloseCheckModal';

// Styles import
import styles from './VerificationDetails.module.scss';
import {
  CLOSE_CHECK_REASONS,
  HOLD_CHECK_REASONS_MAPPING,
  HOLD_REASONS_REF_CHECK
} from '../../../../utils/commonConstant';

import { capitalizeCase } from '../../frontend-common/utils/Utilities';
import { capitalizeName } from '../../frontend-common/utils/Utilities';
import HistoryIcon from '@material-ui/icons/History';
import AddressActivityLog from '../AddressActivityLog';

class VerificationDetails extends React.Component {
  constructor(props) {
    super(props);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.state = {
      IDENTITY_TYPE: {
        1: 'Any',
        2: 'PAN',
        3: 'Driving License',
        4: 'Passport',
        5: 'Voter ID',
        6: 'Aadhaar'
      },
      type: [],
      loading: true,
      title: null,
      document: [],
      i: 0,
      j: 0,
      docUrl: '',
      docxUrl: '',
      companyId: this?.props?.profile?.company_user_mapping?.company_id,
      docsTotalPage: new Map(),
      isModalOpen: false,
      selectedDavCheck: null,
      isHoldCheckModalOpen: false,
      isCloseCheckModalOpen: false,
      holdCheckData: null,
      closeCheckData: null
    };
  }

  token = localStorage.getToken();
  baseUrl = AppConstants.developmentAPIURL;

  validateNumber = (number) => {
    return number !== null && number !== undefined && number !== '' && number !== 0;
  };

  validateString = (string) => {
    return string !== null && string !== undefined && string !== '';
  };

  downloadDocFile = () => {
    return (
      <a href={this.state.docUrl} download={'document.doc'}>
        View document
      </a>
    );
  };

  downloadDocxFile = () => {
    return (
      <a href={this.state.docxUrl} download={'document.docx'}>
        View document
      </a>
    );
  };

  fetchContentType = (title, doc) => {
    if (this.state.title !== title) {
      this.setState({ type: [], document: [], loading: true });
      this.state.i++;
      axios.get(doc, { responseType: 'blob' }).then((response) => {
        if (response.headers['content-type'] === 'application/msword') {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          this.setState({
            docUrl: url
          });
        } else if (
          response.headers['content-type'] ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          this.setState({
            docxUrl: url
          });
        }
        this.state.document.push(doc);
        this.state.type.push(response.headers['content-type']);
        this.state.j++;

        if (this.state.i == this.state.j) {
          this.setState({
            loading: false
          });
        }
      });

      this.setState({
        title: title
      });
    }
  };

  // creating Map for dcouments with key pair as [url,count of pages in doc]
  setPages = (url, count) => {
    this.setState({
      ...this.state,
      docsTotalPage: this.state.docsTotalPage.set(url, count)
    });
  };

  // this funtion return sthe count for the uploaded PDF's from map created in state
  getCount = (url) => {
    return this.state.docsTotalPage.get(url);
  };
  renderPdfToImg = (url, doc, candidateId) => {
    const checkType = url.substring(url.indexOf('checkType=') + 10, url.lastIndexOf('&'));
    return (
      <a
        href={`${AppConstants.baseURL}candidate/document?uuid=${doc.uuid}&candidate_id=${candidateId}&checkType=${checkType}`}
        target={'_blank'}
        rel='noreferrer'
      >
        <div className={`${styles.viewPdfWrapper} ${this.getCount(url) > 1 ? styles.docs : ''}`}>
          <Document
            file={this.baseUrl.substring(0, this.baseUrl.length - 1) + url + this.token}
            onLoadSuccess={({ numPages }) => {
              this.setPages(url, numPages);
            }}
          >
            {Array.apply(null, Array(this.getCount(url)))
              .map((x, i) => i + 1)
              .map((page) => {
                return <Page pageNumber={page} scale={0.5} />;
              })}
          </Document>
        </div>
      </a>
    );
  };

  renderImg = (doc, candidateId, addressFile) => {
    const checkType = doc.documentUrl.substring(
      doc.documentUrl.indexOf('checkType=') + 10,
      doc.documentUrl.lastIndexOf('&')
    );
    const cibilCheckType = doc?.checkType ? 'candidates_ids' : 'candidates_creditchecks';

    return (
      <a
        href={
          this?.props?.title.includes('CIBIL')
            ? `${AppConstants.baseURL}candidate/document?uuid=${doc.uuid}&candidate_id=${candidateId}&checkType=${cibilCheckType}`
            : addressFile === true && this?.props?.title === 'Court'
            ? `${AppConstants.baseURL}candidate/document?uuid=${doc.uuid}&candidate_id=${candidateId}&checkType=candidates_addresses`
            : `${AppConstants.baseURL}candidate/document?uuid=${doc.uuid}&candidate_id=${candidateId}&checkType=${checkType}`
        }
        target={'_blank'}
        rel='noreferrer'
      >
        <img
          src={
            addressFile === true || (this?.props?.title.includes('CIBIL') && doc?.checkType)
              ? doc.documentUrl
              : this.baseUrl.substring(0, this.baseUrl.length - 1) + doc.documentUrl + this.token
          }
          alt=''
        />
      </a>
    );
  };

  renderDocuments = (docType, doc, url, candidateId, addressFile = false) => {
    switch (docType) {
      case 'application/pdf':
        return this.renderPdfToImg(url, doc, candidateId);
      case 'application/msword':
        return this.downloadDocFile();
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return this.downloadDocxFile();
      default:
        return this.renderImg(doc, candidateId, addressFile);
    }
  };

  renderTitle = (title, value, voterAddress) => {
    switch (title) {
      case 'Identity':
        return this.state.IDENTITY_TYPE[value.type];
      case 'Employment':
        return (
          <div>
            {value.companyName}
            <div className={styles.duration}>
              {value.startDate ? `${moment(value.startDate).format('MMM YYYY')}` : <div></div>}
              {value.endDate ? (
                value.startDate ? (
                  ` - ${moment(value.endDate).format('MMM YYYY')}`
                ) : (
                  `Till ${moment(value.endDate).format('MMM YYYY')}`
                )
              ) : value.currentlyHere ? (
                ' - Currently working here'
              ) : (
                <div></div>
              )}
            </div>
          </div>
        );
      case 'World':
        return capitalizeName(value.candidateName);
      case 'Drug':
        return value.panelType;
      case 'Reference':
        return value.verifierName;
      case 'CIBIL Credit Score':
        return value.name;
      case 'Court':
        return voterAddress.type;
      default:
        return '';
    }
  };
  // Helper function to render Dropdown.Item with Tooltip conditionally
  renderDropdownItemWithTooltip = (itemText, value, onClickHandler, tooltipMessage) => {
    const hasAdditionalCharge = value?.insuffFunds?.some(
      (fund) => fund.insuffType === 'ADDITIONAL_CHARGE'
    );
    const isOverallStatusConsentMissingOrInsuffFunds = [11, 12].includes(this.props?.overallStatus);

    let isDisabled = isOverallStatusConsentMissingOrInsuffFunds || value?.insuffFunds?.length > 0;

    if (hasAdditionalCharge && !isOverallStatusConsentMissingOrInsuffFunds) {
      isDisabled = false;
    }

    if (isDisabled) {
      return (
        <OverlayTrigger
          placement='top'
          trigger={['hover', 'focus']}
          overlay={
            <Tooltip id={`tooltip-${itemText.replace(/\s+/g, '-').toLowerCase()}`}>
              {tooltipMessage}
            </Tooltip>
          }
        >
          <div>
            <Dropdown.Item className={styles.dropdownItemDisabled} disabled={isDisabled}>
              {itemText}
            </Dropdown.Item>
          </div>
        </OverlayTrigger>
      );
    }

    return (
      <Dropdown.Item className={styles.dropdownItem} onClick={onClickHandler}>
        {itemText}
      </Dropdown.Item>
    );
  };

  checkIfDavPresent() {
    const foundAddress = this.props?.verificationInfo
      .map((address) => {
        if (address.inHouseDav === 1) {
          return address.id;
        }
        return null;
      })
      .find((id) => id !== null);

    return foundAddress ?? null;
  }

  render() {
    const candidateId = new URLSearchParams(document.location.search).get('candidateId');
    const { checkStatusesMapping } = this.props?.companyCustomizations?.length
      ? mapCustomizationTypes(this.props?.companyCustomizations)
      : {};

    const getColor = (status) => {
      return getCustomMapping(
        checkStatusesMapping?.colors,
        status,
        CUSTOMIZATION_TYPES_STATUS_CODES_MAPPING.CHECK_STATUSES.COLORS
      );
    };

    const getLabel = (status) => {
      return getCustomMapping(
        checkStatusesMapping?.labels,
        status,
        CUSTOMIZATION_TYPES_STATUS_CODES_MAPPING.CHECK_STATUSES.LABELS
      );
    };

    return (
      <div className={styles.verificationDetailsContainer}>
        <div className={styles.verificationDetailsHeading}>
          <Image name={this.props?.icon} />
          <div className={styles.titleContainer}>{this.props?.title}</div>
        </div>
        {this.props?.verificationInfo?.length === 0 ? (
          <NoRecords />
        ) : (
          <div className={styles.verificationDetailsContent}>
            {this.props?.verificationInfo?.map((value, index) => {
              let voterAddress = {};
              let { status, onHold, onClosed, proxyUserId } = value;
              let courtDocuments = [];
              let cibilDocuments = [];
              if (this.props.title === 'Court') {
                courtDocuments.push(
                  ...(value?.candidatesAddress?.files || []),
                  ...(value?.files ?? [])
                );
              }
              if (this.props.title.includes('CIBIL')) {
                value?.candidatesId
                  ? cibilDocuments.push(
                      ...value?.candidatesId?.files?.map((file) => ({
                        ...file,
                        checkType: 'candidates_ids'
                      })),
                      ...value?.files
                    )
                  : cibilDocuments.push(...value?.files);
              }

              status = getStatus(status);
              this.props?.additionalInfo?.map((val, i) => {
                if (value.addressId === val.id) {
                  voterAddress = {
                    type: val?.documentType,
                    address: val?.address1,
                    residenceType: val?.type,
                    document: val?.files && val?.files?.length !== 0 ? val?.files : ''
                  };
                }
              });
              if (status !== 11) {
                return (
                  <div>
                    {index !== 0 ? <hr /> : <div></div>}
                    <div className={styles.verificationCard}>
                      <div className={styles.cardHeaderContainer}>
                        <div className={styles.cardHeader}>
                          {this.renderTitle(this.props.title, value, voterAddress)}
                          {this.props.title === 'Address' ? (
                            <>
                              <span>
                                {ADDRESS_TYPE_MAPPING[value.type]}
                                {ADDRESS_VERIFICATION_TYPE_MAPPING[
                                  value.preferredVerificationMethod
                                ] &&
                                  ` (${
                                    ADDRESS_VERIFICATION_TYPE_MAPPING[
                                      value.preferredVerificationMethod
                                    ]
                                  })`}
                                {has(value, 'isCurrentPermanentSame') &&
                                value.isCurrentPermanentSame
                                  ? ' - Marked same as current address'
                                  : null}
                              </span>
                              {this.props?.title === 'Address' &&
                              value.inHouseDav &&
                              value.preferredVerificationMethod == 'DIGITAL' ? (
                                <OverlayTrigger
                                  key={`address_dav_logs`}
                                  placement='top'
                                  overlay={
                                    <Tooltip id={`address_dav_logs`}>
                                      <p>Activity Log</p>
                                    </Tooltip>
                                  }
                                >
                                  <HistoryIcon
                                    className={styles.activityIcon}
                                    onClick={() =>
                                      this.setState({
                                        isModalOpen: true,
                                        selectedDavCheck: value.id
                                      })
                                    }
                                  />
                                </OverlayTrigger>
                              ) : null}
                            </>
                          ) : null}
                        </div>
                        {/*  Don't remove this comment need it for reference
                              0 - Ready to Initiate
                              9 - Form Submitted
                              13 - Insufficiency
                              5 - On Hold
                              4 - In Progress
                        */}
                        {[0, 9, 13, 5, 4, 12].includes(status) && (
                          <div className={styles.dropdownMenuWrapper}>
                            <Dropdown className={styles.mainDropdown}>
                              <Dropdown.Toggle
                                className={styles.toggleButton}
                                id='dropdown-custom-components'
                              >
                                <Icon
                                  icon={'MenuDots'}
                                  color={'#666666'}
                                  width={'24'}
                                  height={'24'}
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className={styles.dropdownMenu}>
                                {[0, 9, 13].includes(status) &&
                                  this.renderDropdownItemWithTooltip(
                                    'Hold check',
                                    value,
                                    () => {
                                      this.setState({ holdCheckData: { ...value, candidateId } });
                                      this.setState({ isHoldCheckModalOpen: true });
                                    },
                                    [11, 12].includes(this.props?.overallStatus)
                                      ? `Cannot edit check as candidate's status is in ${
                                          this.props?.overallStatus === 12
                                            ? 'Insuff-funds'
                                            : 'Consent missing'
                                        }`
                                      : `Cannot edit check as status is in Insuff funds`
                                  )}

                                {status === 5 &&
                                  this.renderDropdownItemWithTooltip(
                                    'Edit hold',
                                    value,
                                    () => {
                                      this.setState({ holdCheckData: { ...value, candidateId } });
                                      this.setState({ isHoldCheckModalOpen: true });
                                    },
                                    [11, 12].includes(this.props?.overallStatus)
                                      ? `Cannot edit hold as candidate's status is in ${
                                          this.props?.overallStatus === 12
                                            ? 'Insuff-funds'
                                            : 'Consent missing'
                                        }`
                                      : `Cannot edit hold as status is in Insuff funds`
                                  )}

                                {[0, 9, 13, 5, 4, 12].includes(status) &&
                                  this.renderDropdownItemWithTooltip(
                                    'Close check',
                                    value,
                                    () => {
                                      this.setState({ closeCheckData: { ...value, candidateId } });
                                      this.setState({ isCloseCheckModalOpen: true });
                                    },
                                    [11, 12].includes(this.props?.overallStatus)
                                      ? `Cannot close check as candidate's status is in ${
                                          this.props?.overallStatus === 12
                                            ? 'Insuff-funds'
                                            : 'Consent missing'
                                        }`
                                      : `Cannot close check as status is in Insuff funds`
                                  )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        )}
                      </div>

                      <div
                        className={styles.cardStatus}
                        style={{
                          color: getColor(value?.status) || CHECK_STATUSES.values[status].color
                        }}
                      >
                        <svg
                          width='8'
                          height='8'
                          viewBox='0 0 8 8'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle
                            cx='4'
                            cy='4'
                            r='4'
                            fill={getColor(value?.status) || CHECK_STATUSES.values[status].color}
                          />
                        </svg>
                        {getLabel(value?.status) || CHECK_STATUSES.values[status].value}
                        {status === 5 && (
                          <span className={styles.reasonSpan}>
                            (till {moment(onHold?.holdEndDate).format('DD-MM-YYYY')}) by{' '}
                            {onHold?.checkStatusUpdatedByUserType === 1
                              ? 'SpringVerify'
                              : `${onHold?.name}${proxyUserId && ' (via SV)'}`}{' '}
                            due to{' '}
                            {onHold?.holdReason === 'OTHER'
                              ? onHold?.holdComment
                              : HOLD_CHECK_REASONS_MAPPING[onHold?.holdReason] ||
                                HOLD_REASONS_REF_CHECK[onHold?.holdReason]}
                            .
                          </span>
                        )}
                        {status === 6 && onClosed?.checkStatusUpdatedByUserType === 2 && (
                          <span className={styles.reasonSpan}>
                            {' '}
                            by {onClosed?.name}
                            {proxyUserId && ' (via SV)'} due to{' '}
                            {onClosed?.closedReason === 'OTHER'
                              ? onClosed?.closedComment
                              : CLOSE_CHECK_REASONS?.find((r) => r.value === onClosed?.closedReason)
                                  ?.label || ''}
                            .
                          </span>
                        )}
                      </div>
                      <div className={styles.cardContentWrapper}>
                        {this.props.title === 'Identity' ? (
                          <div className={styles.cardContent}>
                            <div className={styles.label}>{'ID Number'}</div>
                            <div className={styles.value}>
                              {this.validateString(value.identityNumber)
                                ? value.identityNumber
                                : '-'}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {this.props.title === 'World' ? (
                          <div className={styles.cardContent}>
                            <div className={styles.label}>{'Father’s Name'}</div>
                            <div className={styles.value}>
                              {this.validateString(value.fatherName)
                                ? capitalizeName(value.fatherName)
                                : '-'}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {this.props.title === 'CIBIL Credit Score' ? (
                          <div className={styles.cardContent}>
                            <div className={styles.label}>{'PAN'}</div>
                            <div className={styles.value}>
                              {this.validateString(value?.candidatesId?.identityNumber)
                                ? value.candidatesId.identityNumber
                                : '-'}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {this.props.title === 'Identity' ||
                        this.props.title === 'World' ||
                        this.props.title === 'CIBIL Credit Score' ? (
                          <div className={styles.cardContent}>
                            <div className={styles.label}>{'Date Of Birth'}</div>
                            <div className={styles.value}>
                              {this.validateString(
                                this.props.title === 'CIBIL Credit Score'
                                  ? value?.candidatesId?.dob
                                  : value.dob
                              )
                                ? moment(
                                    this.props.title === 'CIBIL Credit Score'
                                      ? value?.candidatesId?.dob
                                      : value.dob
                                  ).format('DD MMM YYYY')
                                : '-'}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {this.props.title === 'Address' ||
                        this.props.title === 'Court Record' ||
                        this.props.title === 'World' ? (
                          <div className={styles.cardContent}>
                            <div className={styles.label}>{'Address'}</div>
                            <div className={styles.value}>
                              {this.props.title === 'Address'
                                ? this.validateString(value.address1)
                                  ? value.address1 +
                                    ', ' +
                                    value.address2 +
                                    ', ' +
                                    capitalizeName(removeAsterisks(value?.city)) +
                                    ', ' +
                                    removeAsterisks(value?.state) +
                                    ', ' +
                                    removeAsterisks(value?.country) +
                                    ',' +
                                    value.zipcode
                                  : '-'
                                : this.props.title === 'Court Record'
                                ? this.validateString(voterAddress.address)
                                  ? voterAddress.address
                                  : '-'
                                : this.validateString(value.address)
                                ? capitalizeName(removeAsterisksFromString(value.address))
                                : '-'}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {this.props.title === 'Drug' ? (
                          <div>
                            <div className={styles.cardContent}>
                              <div className={styles.label}>{'Sample from'}</div>
                              <div className={styles.value}>
                                {this.validateString(value.candidateName)
                                  ? capitalizeName(value.candidateName)
                                  : '-'}
                              </div>
                            </div>
                            <div className={styles.cardContent}>
                              <div className={styles.label}>{'Verification type'}</div>
                              <div className={styles.value}>
                                {this.validateString(value.verificationType)
                                  ? capitalizeName(value.verificationType)
                                  : '-'}
                              </div>
                            </div>
                            <div className={styles.cardContent}>
                              <div className={styles.label}>{'Sample type'}</div>
                              <div className={styles.value}>
                                {this.validateString(value.sampleType)
                                  ? capitalizeName(value.sampleType)
                                  : '-'}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {this.props.title === 'Reference' ? (
                          <div className={styles.cardContent}>
                            <div className={styles.label}>{'Company'}</div>
                            <div className={styles.value}>
                              {this.validateString(value.employerName) ? value.employerName : '-'}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <div className={styles.cardContent}>
                          <div className={styles.label}>{'Insufficiency Time'}</div>
                          <div className={styles.value}>
                            {this.validateNumber(
                              tatDataFormatted(
                                this.props.tatData,
                                this.props.verificationInfo,
                                'checkInsuffNet',
                                index
                              )
                            )
                              ? `${tatDataFormatted(
                                  this.props.tatData,
                                  this.props.verificationInfo,
                                  'checkInsuffNet',
                                  index
                                )} days`
                              : '-'}
                          </div>
                        </div>
                        <div className={styles.cardContent}>
                          <div className={styles.label}>{'On Hold'}</div>
                          <div className={styles.value}>
                            {value?.holdEndDate
                              ? `${moment(value?.holdEndDate).format('YYYY-MM-DD')}`
                              : '-'}
                          </div>
                        </div>
                        <div className={styles.cardContent}>
                          <div className={styles.label}>{'Effective TAT'}</div>
                          <div className={styles.value}>
                            {this.validateNumber(
                              tatDataFormatted(
                                this.props.tatData,
                                this.props.verificationInfo,
                                'actualNetTatNet',
                                index
                              )
                            )
                              ? `${tatDataFormatted(
                                  this.props.tatData,
                                  this.props.verificationInfo,
                                  'actualNetTatNet',
                                  index
                                )} days`
                              : '-'}
                          </div>
                        </div>
                        {!this.props.isCustomCheck ? (
                          <div className={styles.cardContent}>
                            <div className={styles.label}>{'Documents'}</div>
                            {(value?.files && value?.files?.length !== 0) ||
                            courtDocuments?.length !== 0 ||
                            cibilDocuments?.length !== 0 ? (
                              <div className={styles.documents}>
                                {this.state.loading ? 'Loading...' : null}
                                {this?.props?.title === 'Court'
                                  ? courtDocuments.map((doc, i) => {
                                      let url = doc?.isVerified
                                        ? doc.documentUrl
                                        : `/auth/image?uuid=${doc.uuid}&companyId=${this.state.companyId}&checkType=candidates_addresses&token=`;
                                      let addessFile = doc?.isVerified ? false : true;
                                      return (
                                        <div className={styles.docValue} key={i}>
                                          {url &&
                                            this.fetchContentType(
                                              this.props.title,
                                              this.baseUrl.substring(0, this.baseUrl.length - 1) +
                                                url +
                                                this.token
                                            )}
                                          {this.state.loading ? null : (
                                            <div>
                                              {this.renderDocuments(
                                                this.state.type[
                                                  this.state.document.findIndex((document) =>
                                                    document.includes(url)
                                                  )
                                                ],
                                                doc,
                                                url,
                                                candidateId,
                                                addessFile
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      );
                                    })
                                  : this?.props?.title.includes('CIBIL')
                                  ? cibilDocuments.map((doc, i) => {
                                      let url = doc?.checkType
                                        ? `/auth/image?uuid=${doc.uuid}&companyId=${this.state.companyId}&checkType=candidates_ids&token=`
                                        : `/auth/image?uuid=${doc.uuid}&companyId=${this.state.companyId}&checkType=candidates_creditchecks&token=`;
                                      return (
                                        <div className={styles.docValue} key={i}>
                                          {url &&
                                            this.fetchContentType(
                                              this.props.title,
                                              this.baseUrl.substring(0, this.baseUrl.length - 1) +
                                                url +
                                                this.token
                                            )}
                                          {this.state.loading ? null : (
                                            <div>
                                              {this.renderDocuments(
                                                this.state.type[
                                                  this.state.document.findIndex((document) =>
                                                    document.includes(url)
                                                  )
                                                ],
                                                doc,
                                                url,
                                                candidateId
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      );
                                    })
                                  : value.files.map((doc, i) => {
                                      let url = doc.documentUrl;
                                      return (
                                        <div className={styles.docValue} key={i}>
                                          {url &&
                                            this.fetchContentType(
                                              this.props.title,
                                              this.baseUrl.substring(0, this.baseUrl.length - 1) +
                                                url +
                                                this.token
                                            )}
                                          {this.state.loading ? null : (
                                            <div>
                                              {this.renderDocuments(
                                                this.state.type[
                                                  this.state.document.findIndex((document) =>
                                                    document.includes(url)
                                                  )
                                                ],
                                                doc,
                                                url,
                                                candidateId
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      );
                                    })}
                              </div>
                            ) : (
                              <div className={styles.value}>{'-'}</div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        )}
        {this.state.isHoldCheckModalOpen && (
          <HoldCheckModal
            open={this.state.isHoldCheckModalOpen}
            data={this.state.holdCheckData}
            activeItem={this.props.activeItem}
            fetchCandidate={this.props?.fetchCandidate}
            onClose={() => {
              this.setState({ isHoldCheckModalOpen: false });
            }}
          />
        )}
        {this.state.isCloseCheckModalOpen && (
          <CloseCheckModal
            open={this.state.isCloseCheckModalOpen}
            data={this.state.closeCheckData}
            activeItem={this.props.activeItem}
            fetchCandidate={this.props?.fetchCandidate}
            onClose={() => {
              this.setState({ isCloseCheckModalOpen: false });
            }}
          />
        )}
        <AddressActivityLog
          addressId={this.state.selectedDavCheck}
          show={this.state.isModalOpen}
          onClose={() => {
            this.setState({ isModalOpen: false });
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { profile } = state.profile;
  return { profile };
}

export default connect(mapStateToProps)(VerificationDetails);
