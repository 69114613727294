import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, } from 'lodash';

import validationSchema from './HrDetailsStep.validation';
import logger from '../../../../../../../core-components/Logger';
import {
  compareObjects,
  errToastMessage
} from '../../../../../../../utils/utilities';

import { saveBgvCache as saveBgvCacheAPI } from '../../../../../../../actions/bgv';
import { capitalizeName } from '../../../../../../CompanyAdmin/frontend-common/utils/Utilities';

const mapDispatchToProps = (dispatch) => {
  return {
    setBgvCache: (data) => dispatch(saveBgvCacheAPI(data))
  };
};

const mapStateToProps = (state) => {
  return {
    cached_data: !state.bgvCachedData.data ? null : { ...state.bgvCachedData.data }
  };
};

const formattedSaveObject = (props, values) => {
  let finalCacheObj = {};
  let saveObject = {};
  if (props?.cached_data?.cache_data?.employment_check?.data[props.currentSavingIndex]) {
    saveObject = {
      ...props.cached_data,
      cache_data: {
        ...props?.cached_data?.cache_data,
        employment_check: {
          data: [...props?.cached_data?.cache_data?.employment_check?.data]
        }
      }
    };
    delete values.companyName;
    saveObject.cache_data.employment_check.data[props.currentSavingIndex].step_3 = {
      ...values
    };
  }
  saveObject.current_state = '4';
  finalCacheObj['saveObject'] = {
    ...saveObject
  };
  return finalCacheObj;
};

let propsValue = {};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let refCount =
        props.bgvPackageConfig && props.bgvPackageConfig.config.reference
          ? props.bgvPackageConfig.config.reference[props.currentSavingIndex] || 0
          : 0;
      let baseReferenceObj = {
        full_name: '',
        email: '',
        country_code: '+91',
        isValidPhone: true,
        phone: '',
        designation: '',
        linkedin_url: '',
        phone_number: ''
      };
      let storedValues = {
        hr_info: {
          full_name: '',
          email: '',
          country_code: '+91',
          isValidPhone: true,
          phone: '',
          phone_number: ''
        },
        manager_info: {
          full_name: '',
          email: '',
          country_code: '+91',
          isValidPhone: true,
          refCount: parseInt(refCount),
          phone: '',
          phone_number: ''
        },
        refCount: parseInt(refCount),
        references_additional: new Array(parseInt(refCount)).fill(baseReferenceObj)
      };
      if (props?.cached_data?.cache_data?.employment_check?.data?.length > 0) {
        if (
          props?.cached_data?.cache_data?.employment_check?.data[props.currentSavingIndex] &&
          props?.cached_data?.cache_data?.employment_check?.data[props.currentSavingIndex]?.step_3
        ) {
          let empData =
            props.cached_data.cache_data?.employment_check?.data[props.currentSavingIndex];
          propsValue = { ...empData.step_3 };
        } else {
          propsValue = {};
        }
      } else {
        propsValue = {};
      }
      if (propsValue?.hr_info?.full_name) {
        propsValue.hr_info.full_name = capitalizeName(propsValue.hr_info.full_name);
      }
      if (propsValue?.manager_info?.full_name) {
        propsValue.manager_info.full_name = capitalizeName(propsValue.manager_info.full_name);
      }
      if (!propsValue?.manager_info?.refCount) {
        propsValue.manager_info = {
          ...propsValue.manager_info,
          refCount: parseInt(refCount)
        }
      }
      propsValue.manager_info.refCount = parseInt(refCount);
      if (
        refCount &&
        Array.isArray(propsValue.references_additional) &&
        propsValue.references_additional.length
      ) {
        propsValue.references_additional = propsValue.references_additional.map((o) => ({
          ...o,
          full_name: capitalizeName(o.full_name)
        }));
      }
      if (
        props?.cached_data?.cache_data['employment_check']?.data[props.currentSavingIndex].step_1
      ) {
        const empData =
          props?.cached_data?.cache_data['employment_check']?.data[props.currentSavingIndex];
        propsValue = { ...propsValue, companyName: empData.step_1?.company_name };
      }
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    handleSubmit: (values, { setStatus, setSubmitting, resetForm, props }) => {
      // calling cache API if previous value and new value is diff otherwise we will move to the next step
      if (!compareObjects(values, propsValue)) {
        let valuesCopy = { ...values };
        valuesCopy.hr_info.phone_number =
          (valuesCopy.hr_info.phone &&
            valuesCopy?.hr_info?.country_code + valuesCopy.hr_info.phone) ||
          '';
        valuesCopy.manager_info.phone_number =
          (valuesCopy.manager_info.phone &&
            valuesCopy?.manager_info?.country_code + valuesCopy.manager_info.phone) ||
          '';
        valuesCopy.hr_info.email =
          (valuesCopy.hr_info.email && valuesCopy.hr_info.email?.toLowerCase()) || null;
        valuesCopy.manager_info.email =
          (valuesCopy.manager_info.email && valuesCopy.manager_info.email?.toLowerCase()) || null;
        valuesCopy.references_additional.forEach((object) => {
          if (object.email !== '') object.email = object.email.toLowerCase();
          if (object.phone !== '') object.phone_number = object?.country_code + object?.phone;
        });
        const finalCacheObj = formattedSaveObject(props, valuesCopy);
        props
          .setBgvCache(finalCacheObj)
          .then(() => {
            logger.push({
              message: `Employment step3 is ${props.isEdit ? 'Edited' : 'Saved'}`,
              data: JSON.stringify(finalCacheObj),
              category: `candidate-id-${props.candidateId}`,
              subCategory: 'Employment details#3',
              type: 'info'
            });
            setSubmitting(false);
            props.onHide();
          })
          .catch((error) => {
            console.error(error);
            setSubmitting(false);
            errToastMessage(error);
          });
      } else {
        setSubmitting(false);
        props.onHide();
      }
    },
    displayName: 'Employment'
  })
);
