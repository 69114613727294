// React and other libraries imports
import React, { useEffect, useState } from 'react';
import { Field, useFormik } from 'formik';
import { Form, Modal } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import Select from 'react-select';
import moment from 'moment';
import classNames from 'classnames';

// Common components, constants and utils import
import {
  ADDR_CHECK_HOLD_REASON,
  CHECK_NAME_BY_ID,
  EDU_CHECK_HOLD_REASON,
  EMP_CHECK_HOLD_REASON,
  HOLD_CHECK_REASON,
  REF_CHECK_HOLD_REASON
} from '../../../../utils/commonConstant';
import ValidatedFormTextArea from '../../../../core-components/ValidatedFormTextArea';
import Image from '../../frontend-common/core-components/Image';
import validationSchema from './HoldCheckModal.validation';

// Styles import
import styles from './HoldCheckModal.module.scss';
import { isEmpty } from 'lodash';
import { getTrimmedValue } from '../../../../utils/utilities';

const HoldCheckModal = (props) => {
  const { open, onClose, activeItem, data = null, putCheckOnHoldAPI, fetchCandidate } = props;
  const [onHoldReasons, setOnHoldReasons] = useState([]);
  const idToUse = activeItem?.id > 10 ? 11 : activeItem?.id; // Custom check ids will be 10 and above

  const initialValues = {
    check_name: CHECK_NAME_BY_ID[idToUse] || '',
    check_id: data?.id || null,
    candidate_id: data?.candidateId || null,
    current_status: data?.status,
    status: 5,
    reason: data?.status === 5 && data?.onHold?.holdReason ? data?.onHold?.holdReason : '',
    comment: data?.status === 5 && data?.onHold?.holdComment ? data?.onHold?.holdComment : '',
    end_date:
      data?.status === 5 && data?.onHold?.holdEndDate ? new Date(data?.onHold?.holdEndDate) : null
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const endDate = moment(values?.end_date).format('YYYY-MM-DD');
      const reqBody = { ...values };
      reqBody['end_date'] = endDate;
      await putCheckOnHoldAPI(reqBody);
      fetchCandidate();
    }
  });

  const {
    setFieldValue,
    values,
    errors,
    touched,
    setFieldTouched,
    isValid,
    dirty,
    handleSubmit,
    isSubmitting
  } = formik;

  const isValidForm =
    !isEmpty(onHoldReasons?.filter((c) => c.value === values?.reason)) &&
    values?.end_date &&
    (values.reason === 'OTHER' ? values?.comment : true);

  useEffect(() => {
    /* 
      1. Identity
      2. Court
      3. Address
      4. Employment
      5. Education
      6. World
      7. Drug
      8. Credit Check
      9. Reference
      10. Custom
    */
    if (activeItem?.id) {
      const reasonMap = {
        1: HOLD_CHECK_REASON,
        2: HOLD_CHECK_REASON,
        3: ADDR_CHECK_HOLD_REASON,
        4: EMP_CHECK_HOLD_REASON,
        5: EDU_CHECK_HOLD_REASON,
        6: HOLD_CHECK_REASON,
        7: HOLD_CHECK_REASON,
        8: HOLD_CHECK_REASON,
        9: REF_CHECK_HOLD_REASON,
        10: HOLD_CHECK_REASON,
        11: HOLD_CHECK_REASON
      };
      setOnHoldReasons(reasonMap[idToUse] || []);
    }
  }, [activeItem]);

  const holdEndDaysCalculation = () => {
    const today = moment().startOf('day');
    const selectedDate = moment(values.end_date, 'DD-MM-YYYY').startOf('day');
    return selectedDate.diff(today, 'days');
  };
  const isHoldEndDateValidForCa =
    values.end_date && moment(values.end_date) <= moment().add(2, 'days') ? false : true;

  useEffect(() => {
    const selectedEndDate = values?.end_date ? moment(values?.end_date).format('DD-MMM-YY') : '';
    const reasonMessages = {
      CURRENTLY_STUDYING:
        'Candidate has communicated that s/he is currently studying in the university',
      CURRENTLY_WORKING: `Candidate is currently employed at this organization. The verification can be initiated only after the LWD ${selectedEndDate}`,
      INFO_UNAVAILABLE: `Candidate has requested additional time to furnish the required information / documents and is expected to submit it by ${selectedEndDate}`,
      CLIENT_REQUEST: `As per client request, the check is On Hold until ${selectedEndDate}`,
      ISSUE_WITH_SOURCE: `There is an issue with the source which is causing delays in verification. The issue is expected to be resolved by ${selectedEndDate}`,
      INFO_OR_DOC_UNAVAILABLE: `Candidate has requested additional time to furnish the required information / documents and is expected to submit it by ${selectedEndDate}`,
      AWAITING_RESULTS: `Candidate has communicated that s/he is expecting final results by ${selectedEndDate}`,
      COMPLETED_BUT_DOCS_NOT_ISSUED: `Candidate has communicated that s/he has completed the course and expecting the documents to be available by ${selectedEndDate}`,
      FNF_PENDING: `Candidate has communicated that FNF has not been completed. Tentative date (as indicated by the candidate) to complete FNF and receive Experience Letter is ${selectedEndDate}`,
      FUTURE_AVAILABILITY: `Candidate has communicated that no one will be available at the premises to complete the verification until ${selectedEndDate}`,
      DONOT_CONTACT_REF_UNTIL: `Candidate has requested not to contact the given individual until ${selectedEndDate}`
    };
    if (values?.reason !== 'OTHER') setFieldValue('comment', reasonMessages[values?.reason]);
  }, [values]);

  return (
    <div>
      <Modal
        size='lg'
        centered
        show={open}
        onHide={onClose}
        backdropClassName={'customBGVModalBackdrop'}
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className={styles.title}>Hold check</Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.modalBody}>
            <p>
              This action will change the check status to ON HOLD and no further action will be
              taken on this check until the hold-till-date. Any charges associated with this check
              will not be eligible for refund.
            </p>
            <p>
              <b> This action cannot be undone. Are you sure?</b>
            </p>

            <div className='form-group'>
              <label>
                Hold end date <span>*</span>
              </label>
              <div className={styles.dateFieldContainer}>
                <div
                  className={classNames(styles.datePickerContainer, {
                    [styles.err]: !isHoldEndDateValidForCa || (touched.end_date && errors.end_date)
                  })}
                >
                  <ReactDatePicker
                    dateFormat='dd-MM-yyyy'
                    isClearable
                    minDate={moment().add(3, 'days').toDate()}
                    maxDate={moment().add(180, 'days').toDate()}
                    placeholderText={'DD-MM-YYYY'}
                    className={styles.datePicker}
                    selected={values.end_date ? moment(values.end_date).toDate() : null}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    onChange={(date) => {
                      setFieldValue('end_date', date);
                    }}
                    onBlur={() => {
                      setFieldTouched('end_date', true);
                    }}
                  />
                  <Image name='calenderIcon.svg' />
                </div>
                {values?.end_date && (
                  <div className={styles.daysCount}>
                    Days from today: {holdEndDaysCalculation()}{' '}
                  </div>
                )}
              </div>

              {touched?.end_date && errors?.end_date && (
                <div className={styles.errDiv}>{errors?.end_date}</div>
              )}
            </div>
            <div className='form-group'>
              <label>
                Select reason <span>*</span>
              </label>
              <Field
                name='reason'
                component={Select}
                menuPlacement={'top'}
                options={onHoldReasons}
                className={styles.selectReason}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    borderColor: touched?.reason && errors?.reason ? 'red' : '#ccc'
                  })
                }}
                value={onHoldReasons?.filter((c) => c.value === values?.reason)}
                isClearable
                onChange={(option) => {
                  setFieldValue('reason', option?.value || '');
                  if (option?.value === 'OTHER') setFieldValue('comment', '');
                }}
                onBlur={() => {
                  setFieldTouched('reason', true);
                }}
              />
              {touched?.reason && errors?.reason && (
                <div className={styles.errDiv}>{errors?.reason}</div>
              )}
            </div>
            {values?.reason === 'OTHER' && (
              <div className='form-group'>
                <label>
                  Specify reason for marking this check as HOLD <span>*</span>
                </label>
                <Field
                  name='comment'
                  containerClass={styles.OtherReasonFieldContainer}
                  charCountClass={styles.charCount}
                  errCountClass={styles.errPosition}
                  showCharCount={true}
                  rows='2'
                  value={values?.comment}
                  maxLength={160}
                  onChange={(e) => {
                    setFieldValue('comment', e?.target?.value || '');
                  }}
                  onBlur={(e) => {
                    setFieldValue('comment', getTrimmedValue(e?.target?.value, true));
                    setFieldTouched('comment', true);
                  }}
                  charsCount={values?.comment?.length}
                  className={styles.otherReasonText}
                  component={ValidatedFormTextArea}
                  maxChars={160}
                  charsCountError={touched.comment && errors?.comment}
                />
                {touched?.comment && errors?.comment && (
                  <div className={styles.errDiv}>{errors?.comment}</div>
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button type='button' className={styles.cancelButton} onClick={onClose}>
              Cancel
            </button>
            <button
              disabled={
                !isValid ||
                !dirty ||
                isSubmitting ||
                !isHoldEndDateValidForCa ||
                !isValidForm ||
                (values?.reason === 'OTHER' && values?.comment?.length < 11)
              }
              type='submit'
              className={styles.redButton}
            >
              {data?.status === 5 ? 'Update hold' : 'Hold Check'}
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default HoldCheckModal;
