import { mergeWith, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import axios from 'axios';

import validationSchema from './ConsentDetails.validation';
import { formValuesToAPI } from '../../../CommonComponent/Bgv.mapper';
import logger from '../../../../../core-components/Logger';
import AppConstants from '../../../../../core-components/AppConstants';
import { errToastMessage, getErrorMessage } from '../../../../../utils/utilities';
import { toCamelCase, toSnakeCase } from '../../../../../utils/utilities';

import {
  generateBgvConsent as generateBgvConsentAPI,
  saveConsent as saveConsentAPI,
  getBgvCandidateDetails,
  saveBgvCache as saveBgvCacheAPI,
  invalidateConsentData,
  setConsentData,
  setBgvSubmission as setBgvSubmissionAPI
} from '../../../../../actions/bgv';

let updatedCacheData = null;
let updatedBasicDetail = null;

const mapStateToProps = (state) => {
  const bgvCachedData = !state.bgvCachedData.data ? null : { ...state.bgvCachedData.data };
  const initialCandidateDetails = !isEmpty(state.initialCandidateDetails.data)
    ? toCamelCase({ ...state.initialCandidateDetails.data })
    : null;
  const bgvConfig = !state.bgvConfig.data ? null : toCamelCase({ ...state.bgvConfig.data });
  updatedCacheData = bgvCachedData;
  updatedBasicDetail = initialCandidateDetails;

  return {
    bgvConfig,
    bgvCachedData,
    initialCandidateDetails,
    consentData: state.bgvConsentData,
    isLoadingBgvCache: state?.bgvCachedData?.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    generateBgvConsent: (data) => dispatch(generateBgvConsentAPI(data)),
    saveConsent: (data) => dispatch(saveConsentAPI(data)),
    getBgvCandidateDetails: () => dispatch(getBgvCandidateDetails()),
    invalidateConsentData: () => dispatch(invalidateConsentData()),
    saveBgvCache: (data) => dispatch(saveBgvCacheAPI(data)),
    setBgvSubmission: (data) => dispatch(setBgvSubmissionAPI(data)),
    setConsentData: (data) => dispatch(setConsentData(data))
  };
};

let propsValue = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        name: '',
        place: '',
        isConsentForm:
          props?.initialCandidateDetails?.basicDetails?.consentLetterUrl || props?.isCompany
            ? false
            : true //need check for company
      };

      if (
        props.bgvCachedData &&
        !props.isConsentOnly &&
        props.bgvCachedData?.cache_data?.basic_details?.full_name
      ) {
        propsValue['name'] = props.bgvCachedData.cache_data.basic_details['full_name'];
      } else {
        propsValue['name'] =
          props.initialCandidateDetails &&
          props.initialCandidateDetails['basicDetails']['fullName'];
      }

      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: (values, { setSubmitting, props }) => {
      // logger code for saving browser details
      logger.push({
        message: 'Browser Details',
        data: JSON.stringify({
          userAgent: window.navigator.userAgent,
          version: window.navigator.appVersion
        }),
        category: `candidate-id-${props.candidateId}`,
        subCategory: 'Browser Details',
        type: 'info'
      });

      // 1) getting IP address
      // 2) Calling API for bgv generation
      // 3) Calling API for updating cache
      // 4) Calling API for final submission
      values.name = values.name.trim();
      if (values.isConsentForm) {
        axios
          .get('https://api.ipify.org/?format=json')
          .then((response) => {
            if (response.data && response?.data?.ip) {
              handleConsentSubmit(response?.data?.ip);
            } else {
              handleConsentSubmit('117.99.88.202');
            }
          })
          .catch((error) => {
            console.error(error);
            logger.push({
              message: 'At Consent submission',
              category: `candidate-id-${props.candidateId}`,
              subCategory: 'At Consent submission',
              type: 'error'
            });
            handleConsentSubmit(AppConstants.consent_default_ip); //added default IP
          });
      } else {
        try {
          props.validateFinalSubmit(
            formValuesToAPI(toSnakeCase(props.bgvCachedData.cache_data)),
            setSubmitting
          );
        } catch (error) {
          console.error(error);
          logger.push({
            message: 'At final submit',
            data: JSON.stringify(props.bgvCachedData.cache_data),
            category: `candidate-id-${props.candidateId}`,
            subCategory: 'Final submit',
            type: 'error'
          });
          setSubmitting(false);
          errToastMessage(error);
        }
      }

      // This function will call in both the case if IP is generated or not from API
      const handleConsentSubmit = (ipAddress) => {
        props
          .generateBgvConsent({ ...values, ip_address: ipAddress })
          .then((response) => {
            props.setConsentData(response.data); //dispatching action for setting consent data in redux
            logger.push({
              message: 'Consent Generated Successfully',
              data: JSON.stringify(response.data),
              category: `candidate-id-${props.candidateId}`,
              subCategory: 'Consent Generation',
              type: 'info'
            });
            if (
              //if status is consent missing or insufficient funds
              props?.initialCandidateDetails &&
              (props?.initialCandidateDetails['basicDetails']?.status === 11 ||
                props?.initialCandidateDetails['basicDetails']?.status === 12) &&
              props?.isConsentOnly
            ) {
              props
                .saveConsent({
                  doc_url: response?.data?.data?.springsign?.body?.docUrl,
                  id: response.data?.data.candidateConsents?.id
                })
                .then(() => {
                  props.getBgvCandidateDetails();
                  setSubmitting(false);
                  props.nextStep();
                })
                .catch((error) => {
                  console.error(error);
                  setSubmitting(false);
                  errToastMessage(error);
                });
            } else {
              const finalCacheData = {
                ...props.bgvCachedData,
                cacheData: {
                  ...props.bgvCachedData.cache_data,
                  consent: {
                    ...values,
                    docUrl: response.data.data.springsign.body.docUrl,
                    id: response.data.data.candidateConsents?.id
                  }
                }
              };
              props
                .saveBgvCache({ saveObject: toSnakeCase(finalCacheData) })
                .then(() => {
                  try {
                    props.validateFinalSubmit(
                      formValuesToAPI(toSnakeCase(updatedCacheData.cache_data)),
                      setSubmitting
                    );
                  } catch (error) {
                    console.error(error);
                    logger.push({
                      message: 'At final submit',
                      data: JSON.stringify(finalCacheData),
                      category: `candidate-id-${props.candidateId}`,
                      subCategory: 'Final submit',
                      type: 'error'
                    });
                    setSubmitting(false);
                    errToastMessage(error);
                  }
                })
                .catch((error) => {
                  console.error(error);
                  setSubmitting(false);
                  errToastMessage(error);
                });
            }
          })
          .catch((error) => {
            setSubmitting(false);
            const msg = 'This form has already been submitted. Please refresh this page';
            const errorMsg = getErrorMessage(error);
            if (errorMsg && msg === errorMsg) {
              errToastMessage(errorMsg);
            } else {
              errToastMessage(`Unable to generate consent. ${errorMsg}`);
            }
          });
      };
    },
    displayName: 'Consent Details'
  })
);
