import React, { useState } from 'react';

export const DRUG_PANEL_TYPES = {
    DRUG_5_PANEL: 'drug5Panel',
    DRUG_6_PANEL: 'drug6Panel',
    DRUG_7_PANEL: 'drug7Panel',
    DRUG_8_PANEL: 'drug8Panel',
    DRUG_9_PANEL: 'drug9Panel',
    DRUG_10_PANEL: 'drug10Panel',
    DRUG_11_PANEL: 'drug11Panel',
    DRUG_12_PANEL: 'drug12Panel',
    DRUG_HAIR_5_PANEL: 'drugHair5Panel'
};

export const DRUG_PANEL_DISPLAY_NAMES = {
    drug5Panel: '5 Panel',
    drug6Panel: '6 Panel',
    drug7Panel: '7 Panel',
    drug8Panel: '8 Panel',
    drug9Panel: '9 Panel',
    drug10Panel: '10 Panel',
    drug11Panel: '11 Panel',
    drug12Panel: '12 Panel',
    drugHair5Panel: 'Hair 5 Panel'
};

/**
 * Get display name for drug panel type
 * @param {string} panelType - The drug panel type
 * @returns {string} - Formatted display name
 */
export const getDrugPanelDisplayName = (panelType) => {
    return `Drug test (${DRUG_PANEL_DISPLAY_NAMES[panelType]})`;
};

/**
 * Disables all other drug panel types when one is selected
 * @param {string} selectedType - The selected drug panel type
 * @param {boolean} value - The selected value (true/false)
 * @param {Object} setterFunctions - Object containing all disable setter functions
 */
export const handleDrugPanelDisable = (selectedType, value, setterFunctions) => {
    // Get all panel types except the selected one
    const panelsToDisable = Object.values(DRUG_PANEL_TYPES).filter(
        (type) => type !== selectedType
    );

    // Disable/enable all other panels
    panelsToDisable.forEach((panelType) => {
        const setterKey = `setDisable${panelType.charAt(0).toUpperCase() + panelType.slice(1)}`;
        if (setterFunctions[setterKey]) {
            setterFunctions[setterKey](value);
        }
    });
};

/**
 * Checks if a drug panel should be displayed based on package configuration
 * @param {Object} pDetails - Package details
 * @param {Object} createCandidateConfig - Candidate configuration
 * @param {string} panelType - Drug panel type
 * @returns {boolean} - Whether the panel should be displayed
 */
export const shouldDisplayDrugPanel = (pDetails, createCandidateConfig, panelType) => {
    const configDrugType = `${panelType.toLowerCase()}`;
    return (
        pDetails &&
        (!pDetails.config.drug || pDetails.config.drug !== configDrugType.toUpperCase()) &&
        createCandidateConfig?.addons?.[panelType] &&
        pDetails.config.drug === null
    );
};

/**
 * Creates initial state hooks for all drug panel types
 * @returns {Object} Object containing state variables and setters
 */
export const createDrugPanelStates = () => {
    const [disableD5, setDisableD5] = useState(false);
    const [disableD6, setDisableD6] = useState(false);
    const [disableD7, setDisableD7] = useState(false);
    const [disableD8, setDisableD8] = useState(false);
    const [disableD9, setDisableD9] = useState(false);
    const [disableD10, setDisableD10] = useState(false);
    const [disableD11, setDisableD11] = useState(false);
    const [disableD12, setDisableD12] = useState(false);
    const [disableHair5, setDisableHair5] = useState(false);

    const drugPanelSetters = {
        setDisableDrug5Panel: setDisableD5,
        setDisableDrug6Panel: setDisableD6,
        setDisableDrug7Panel: setDisableD7,
        setDisableDrug8Panel: setDisableD8,
        setDisableDrug9Panel: setDisableD9,
        setDisableDrug10Panel: setDisableD10,
        setDisableDrug11Panel: setDisableD11,
        setDisableDrug12Panel: setDisableD12,
        setDisableHair5Panel: setDisableHair5
    };

    const drugPanelDisableStates = {
        drug5Panel: disableD5,
        drug6Panel: disableD6,
        drug7Panel: disableD7,
        drug8Panel: disableD8,
        drug9Panel: disableD9,
        drug10Panel: disableD10,
        drug11Panel: disableD11,
        drug12Panel: disableD12,
        hair5Panel: disableHair5
    };

    return {
        drugPanelSetters,
        drugPanelDisableStates
    };
};

/**
 * Checks drug panel values and returns active panel type and value
 * @param {Object} values - The values object containing drug panel states
 * @returns {Object} Object containing type and value
 */
export const getActiveDrugPanel = (values) => {
    let type = '', value = false;

    for (const [key, panelType] of Object.entries(DRUG_PANEL_TYPES)) {
        if (values?.[panelType]) {
            type = panelType;
            value = true;
            break;
        }
    }
    return { type, value };
};

/**
 * Helper function to calculate drug panel prices
 * @param {Object} values - The values object containing drug panel states
 * @param {Object} config - Configuration object containing addon prices
 * @returns {number} - Total price for drug panels
 */
export const calculateDrugPanelPrices = (values, config) => {
    return Object.values(DRUG_PANEL_TYPES).reduce((total, panelType) => {
        if (values[panelType]) {
            return total + (config.addons[panelType]?.price || 0);
        }
        return total;
    }, 0);
};

/**
 * Extracts drug panel values from userSelectedAddons
 * @param {Object} userSelectedAddons - The complete addons object
 * @returns {Object} - Object containing only drug panel values
 */
export const extractDrugPanelValues = (userSelectedAddons) => {
    const drugPanelValues = {};
    Object.values(DRUG_PANEL_TYPES).forEach(key => {
        drugPanelValues[key] = userSelectedAddons[key];
    });
    return drugPanelValues;
};

/**
 * Maps drug panel addons to the payload format
 * @param {Object} originalAddons - Original addon configuration
 * @param {Object} userSelectedAddons - Selected addon values
 * @returns {Object} - Mapped drug panel addons
 */
export const mapDrugPanelAddons = (originalAddons, userSelectedAddons) => {
    const drugPanelValues = extractDrugPanelValues(userSelectedAddons);
    const mappedAddons = {};

    Object.values(DRUG_PANEL_TYPES).forEach(panelType => {
        if (originalAddons[panelType] && drugPanelValues[panelType]) {
            mappedAddons[panelType] = {
                price: originalAddons[panelType].price,
                config: originalAddons[panelType].config
            };
        }
    });

    return mappedAddons;
};